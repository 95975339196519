import React from 'react';
import {
  RecommendationCard,
  TitleIcon,
  TopWhiteSection,
} from '@components/molecules';
import { GradientSection, Grid1frColumns } from '@components/templates/layouts';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useGridColumn } from '@utils/dimensions';
import { PageCatalog } from '@components/templates';
import { useRecommendations } from '@modules/services/services-recommendations';
import { ArrowRightAlt } from '@mui/icons-material';
import RecommendationsLoader from './Recommendations.loading';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';

const Screen: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();

  const { data: recommendations, isError: isRecommendationsError } =
    useRecommendations();

  const columns = useGridColumn({ mobile: 1, tablet: 2, desktop: 2 });

  if (!(recommendations || isRecommendationsError)) {
    return <RecommendationsLoader />;
  }

  return (
    <>
      <GradientSection
        color={'linear-gradient(102.86deg, #015A66 0.05%, #41949F 101.01%);'}
      >
        <TopWhiteSection backText={t('navigation.dashboard')}>
          {t('recommendations.headline')}
        </TopWhiteSection>
      </GradientSection>
      <PageCatalog>
        <Box>
          <TitleIcon
            id="recommendations-title"
            src="/calendar.svg"
            alt="calendar"
          >
            {t('recommendations.title')}
          </TitleIcon>
          <Typography id="recommendations-subtitle" variant="paragraph">
            {t('recommendations.description')}
          </Typography>
        </Box>

        {recommendations !== undefined && recommendations.items.length > 0 && (
          <Grid1frColumns columns={columns}>
            {recommendations.items?.map((item) => (
              <RecommendationCard
                key={item.id}
                {...item}
                href={item.id}
                actionIcon={<ArrowRightAlt />}
                action={t('action.start')}
              />
            ))}
          </Grid1frColumns>
        )}
      </PageCatalog>
    </>
  );
};

export default withErrorBoundary(Screen);
