import React from 'react';
import axios, { AxiosError } from 'axios';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import camelCaseKeys from 'camelcase-keys';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {},
  timeout: 40000,
});

export const apiPortalClient = axios.create({
  baseURL: process.env.REACT_APP_PORTAL_API_BASE_URL,
  headers: {},
  timeout: 40000,
});

export const apiGet =
  <T,>(
    path: string,
    staticOptions: {
      useErrorBoundary?: boolean;
      retry?: number;
      refetchOnWindowFocus?: boolean;
      staleTime?: number;
    } = {}
  ) =>
  (
    dynamicOptions: { enabled: boolean } = { enabled: true }
  ): UseQueryResult<T, AxiosError> => {
    const apiCall = async () => {
      const response = await apiClient.get(path);
      return response?.data;
    };
    return useQuery([path], apiCall, { ...staticOptions, ...dynamicOptions });
  };

export const apiGetId =
  <T,>(path: string, suffix?: string) =>
  (id?: string): UseQueryResult<T, AxiosError> => {
    const apiCall = async () => {
      const response = await apiClient.get<T>(
        path.concat(`/${id}`).concat(suffix !== undefined ? `/${suffix}` : '')
      );
      return response?.data;
    };
    return useQuery([path, id, suffix], apiCall);
  };

apiClient.interceptors.response.use(
  function ({ data, ...response }) {
    return {
      ...response,
      data: camelCaseKeys(data, { deep: true }),
    };
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      cacheTime: 30 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
      useErrorBoundary: true,
    },
  },
});

export const QueryProvider = ({ children }: { children: JSX.Element }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
