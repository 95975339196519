import { Typography } from '@mui/material';
import React from 'react';
import { TitleContainer } from './TitlePopup.styles';
import { TitlePopupProps } from './TitlePopup.types';

const TitlePopup: React.FC<TitlePopupProps> = ({ title }) => (
  <TitleContainer>
    <Typography variant="h5">{title.toUpperCase()}</Typography>
  </TitleContainer>
);

export default TitlePopup;
