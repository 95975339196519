import { Box, styled } from '@mui/material';

export const ExpandContainer = styled(Box)`
  padding: 16px 0px 8px 0px;
`;

export const ExpandActions = styled(Box)(({ $isEmpty = false }) => ({
  display: 'flex',
  padding: $isEmpty ? '0px' : '8px 0px',
}));

export const SideAction = styled(Box)`
  flex: 1;
`;

export const MiddleAction = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: center;
`;
