import React from 'react';
import { Typography, Chip } from '@mui/material';
import { ArticleCardProps } from './ArticleCard.types';
import { Launch } from '@mui/icons-material';
import {
  ArticleButton,
  ArticleCardContent,
  ArticleCardMedia,
  CardContainer,
  ChipBox,
  TitleBox,
} from './ArticleCard.styles';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default function ArticleCard({
  title,
  categories,
  image,
  url,
  articleButtonTitle,
}: ArticleCardProps) {
  return (
    <CardContainer id="article-card">
      <ArticleCardContent>
        <ArticleCardMedia
          image={`${process.env.REACT_APP_PORTAL_API_BASE_URL}${image.url}`}
          title={image.description}
        />
        <ChipBox>
          {categories.map((category) => (
            <Chip
              id="article-card-category"
              key={category.id}
              label={category.name}
              size="small"
            />
          ))}
        </ChipBox>
        <TitleBox>
          <Typography id="article-card-title" variant="h2">
            {title}
          </Typography>
        </TitleBox>

        <ArticleButton
          id="article-button-read"
          target="_blank"
          rel="noopener noreferrer"
          href={sanitizeUrl(url)}
          sx={{ alignSelf: 'flex-start' }}
          size="small"
          endIcon={<Launch />}
        >
          {articleButtonTitle}
        </ArticleButton>
      </ArticleCardContent>
    </CardContainer>
  );
}
