import { ThemeOptions } from '@mui/material';
export const SourceSansProFamily = [
  '"Source Sans Pro"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const PublicoFamily = [
  'Publico',
  '"Source Sans Pro"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const typography = {
  fontFamily: SourceSansProFamily,
  d1: {
    fontFamily: PublicoFamily,
    fontSize: '4.5rem',
    lineHeight: '5rem',
    fontWeight: '700',
  },
  d2: {
    fontFamily: PublicoFamily,
    fontSize: '4rem',
    lineHeight: '4.5rem',
    fontWeight: '700',
  },
  d3: {
    fontFamily: PublicoFamily,
    fontSize: '3rem',
    lineHeight: '3.5rem',
    fontWeight: '700',
  },
  d4: {
    fontFamily: PublicoFamily,
    fontSize: '2.5rem',
    lineHeight: '3rem',
    fontWeight: '700',
  },
  d5: {
    fontFamily: PublicoFamily,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    fontWeight: '700',
  },
  d6: {
    fontFamily: PublicoFamily,
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: '700',
  },
  chartValue: {
    fontSize: '2.5rem',
    lineHeight: '3rem',
    fontWeight: '700',
  },
  h1: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: '700',
  },
  h2: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: '700',
  },
  h3: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: '600',
    letterSpacing: 1,
  },
  h4: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
    fontWeight: '400',
    letterSpacing: 1,
  },
  h5: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: '600',
    letterSpacing: 1,
  },
  lead: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: '400',
    letterSpacing: -0.5,
  },
  leadSemi: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: '600',
  },
  paragraph: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: '400',
  },
  paragraphSemi: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: '600',
  },
  paragraphMedium: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: '400',
  },
  paragraphLarge: {
    fontSize: '0.61rem',
    lineHeight: '0.85rem',
    fontWeight: '400',
  },
  paragraphMediumSemi: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: '600',
  },
  paragraphSmall: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: '400',
  },
  paragraphSmallSemi: {
    fontSize: '0.75rem',
    lineHeight: '1,125rem',
    fontWeight: '600',
  },
  miniMobile: {
    fontSize: '0.625rem',
    lineHeight: '1rem',
    fontWeight: '400',
  },
} as ThemeOptions['typography'];

export default typography;
