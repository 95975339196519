import { ButtonGreyControl } from '@components/atoms/buttons';
import { Box, styled } from '@mui/material';

export const StyledButton = styled(ButtonGreyControl)(() => ({
  justifyContent: 'start',
  flex: 1,
  flexDirection: 'column',
}));

export const Head = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  margin: theme.spacing(0, 0, 1, 0),
}));

export const LeftIcon = styled('img')``;
