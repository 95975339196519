import { Box, styled } from '@mui/material';

export const CountBox = styled(Box)`
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const MapClusterContainerStorybook = styled(Box)`
  width: 100%;
  height: 700px;
`;
