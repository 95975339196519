import {
  InsuredRisk,
  InsuredValue,
  PortfolioItems,
  Site,
  SitesCount,
  SiteFootprint,
  CountryItems,
  SiteListItems,
} from '@modules/types';
import { apiClient, apiGet, apiGetId } from '.';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useSitesCount = apiGet<SitesCount>('/sites/count');

export const useSiteList = apiGet<SiteListItems>('/sites/');

export const useIsoRiskSitesRisk = apiGet<PortfolioItems>(
  '/sites/iso_risk_matrix'
);

export const useCountryList = (): UseQueryResult<CountryItems, AxiosError> => {
  const apiCall = async () => {
    const response = await apiClient.get<CountryItems>(
      '/sites/risk_by_country'
    );

    const itemsWithID = response.data.items.map((item) => {
      return {
        ...item,
        id: item.country.isoCode2,
      };
    });

    return {
      items: itemsWithID,
    };
  };
  return useQuery(['/sites/risk_by_country'], apiCall, {
    useErrorBoundary: false,
    retry: 1,
  });
};

export const usePortfolioRisk = apiGet<InsuredRisk>('/sites/risk');

export const usePortfolioValue = apiGet<InsuredValue>('/sites/tiv');

export const useSite = apiGetId<Site>('/sites');

export const useSiteFootprint = apiGetId<SiteFootprint>('/sites', '/footprint');
