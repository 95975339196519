import React from 'react';
import { Box, Typography } from '@mui/material';
import { BackButton, WhiteLine } from './TopWhiteSection.styles';
import { TopWhiteSectionProps } from './TopWhiteSection.types';
import ScopedDark from '@components/themes/ScopedDark';
import { ArrowBack } from '@mui/icons-material';
import { TopSectionStack } from '../TopSection/TopSection.styles';

const TopWhiteSection: React.FC<TopWhiteSectionProps> = ({
  description,
  backText,
  children,
  ...props
}) => {
  return (
    <TopSectionStack $marginTop={!backText} id="banner-section">
      <Box sx={{ display: 'flex', flexDirection: 'column' }} {...props}>
        <ScopedDark>
          {backText && (
            <BackButton
              id="navigation-button-back"
              aria-label="back"
              size="small"
              href="/"
              sx={{ padding: 0 }}
              startIcon={<ArrowBack fontSize="large" />}
            >
              {backText}
            </BackButton>
          )}
          <WhiteLine />
          <Typography
            id="navigation-header-title"
            sx={{
              display: 'flex',
              whiteSpace: 'pre-line',
              overflowWrap: 'anywhere',
              maxWidth: 420,
            }}
            variant="d5"
          >
            {children}
          </Typography>
          <Typography
            id="navigation-header-title"
            sx={{
              display: 'flex',
              whiteSpace: 'pre-line',
              overflowWrap: 'anywhere',
            }}
            variant="paragraph"
          >
            {description}
          </Typography>
        </ScopedDark>
      </Box>
    </TopSectionStack>
  );
};

export default TopWhiteSection;
