import { Box, Button, styled } from '@mui/material';

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2.5, 2.5, 0),
}));

export const SmallButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-start',
  padding: theme.spacing(2.5),
}));

export const LogoutButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 2.5),
}));
