import React from 'react';
import { BarGradingProps } from './BarGrading.types';
import { BarProgress } from './BarGrading.styles';

const BarGrading: React.FC<BarGradingProps> = ({ width, color }) => {
  return (
    <>
      <BarProgress width={width} height={2} color={color.border} />
      <BarProgress
        width={width}
        height={24}
        color={color.center}
        opacity={false}
      />
      <BarProgress width={width} height={2} color={color.border} />
    </>
  );
};

export default BarGrading;
