import { FlexColumnBox } from '@components/templates';
import { Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const RiskGradingContainer = styled(FlexColumnBox)(({ theme }) => ({
  flex: '1 1 auto',

  gap: 16,
  padding: theme.spacing(3),
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(2, 1),
  },
  [theme.breakpoints.not('mobile')]: {
    minWidth: 500,
  },
  backgroundColor: grey[100],
  boxShadow: theme.shadows[1],
}));

export const TitleSection = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(5, 0),
}));
