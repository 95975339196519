import { SvgIcon } from '@mui/material';
import React, { useMemo } from 'react';
import { RiskIconProps } from './RiskIcon.types';

const RiskIcon: React.FC<RiskIconProps> = ({ name, ...props }) => {
  const path = useMemo(() => {
    switch (name) {
      case 'surfaceWaterFloodRiskGrade':
        return (
          <>
            <path d="M12.4493 12.6663C11.1493 12.6663 11.0559 13.333 10.2293 13.333C9.43594 13.333 9.2826 12.6663 8.00927 12.6663C6.70927 12.6663 6.60927 13.333 5.7826 13.333C4.95594 13.333 4.8626 12.6663 3.5626 12.6663C2.2626 12.6663 2.1626 13.333 1.33594 13.333V14.6663C2.63594 14.6663 2.7426 13.9997 3.5626 13.9997C4.38927 13.9997 4.4826 14.6663 5.7826 14.6663C7.0826 14.6663 7.1826 13.9997 8.00927 13.9997C8.8226 13.9997 8.9426 14.6663 10.2293 14.6663C11.5159 14.6663 11.6293 13.9997 12.4493 13.9997C13.2626 13.9997 13.3826 14.6663 14.6693 14.6663V13.333C13.8426 13.333 13.7493 12.6663 12.4493 12.6663Z" />
            <path d="M5.78927 11.6663C7.08927 11.6663 7.1826 10.9997 8.00927 10.9997C8.8026 10.9997 8.95594 11.6663 10.2293 11.6663C11.5293 11.6663 11.6226 10.9997 12.4493 10.9997C13.2426 10.9997 13.3826 11.653 14.6559 11.6663V10.333C14.2359 10.333 13.9893 10.1463 13.6693 9.96634L12.3226 4.94634L13.7159 5.51301L14.2093 4.27301L6.5226 1.33301L1.33594 7.73967L2.3826 8.55967L3.30927 7.37301L3.92927 9.69301C3.80927 9.67968 3.69594 9.65967 3.55594 9.65967C2.25594 9.65967 2.1626 10.3263 1.33594 10.3263V11.6597C2.6026 11.6597 2.7826 10.993 3.56927 10.993C4.3626 10.9997 4.51594 11.6663 5.78927 11.6663ZM6.94927 2.93967L10.7759 4.33301L12.2093 9.67968C11.1826 9.75301 10.9959 10.273 10.3093 10.3197L9.3626 6.77967L6.78927 7.47301L7.39594 9.73301C6.64927 9.89968 6.45594 10.333 5.7826 10.333C5.6626 10.333 5.5626 10.3197 5.4826 10.2997L4.33594 6.05967L6.94927 2.93967Z" />
          </>
        );
      case 'coastalFloodRiskGrade':
        return (
          <path d="M7.9974 1.33301C4.44406 4.36634 2.66406 6.98634 2.66406 9.19967C2.66406 12.5197 5.1974 14.6663 7.9974 14.6663C10.7974 14.6663 13.3307 12.5197 13.3307 9.19967C13.3307 6.98634 11.5507 4.36634 7.9974 1.33301ZM7.9974 13.333C5.76406 13.333 3.9974 11.6197 3.9974 9.19967C3.9974 7.63967 5.2974 5.57301 7.9974 3.10634C10.6974 5.57301 11.9974 7.63301 11.9974 9.19967C11.9974 11.6197 10.2307 13.333 7.9974 13.333ZM5.2174 9.33301C5.46406 9.33301 5.66406 9.50634 5.71073 9.74634C5.98406 11.2263 7.23073 11.733 8.1374 11.6597C8.42406 11.6463 8.66406 11.873 8.66406 12.1597C8.66406 12.4263 8.45073 12.6463 8.18406 12.6597C6.76406 12.7463 5.10406 11.933 4.72406 9.91301C4.67073 9.61301 4.91073 9.33301 5.2174 9.33301Z" />
        );
      case 'cycloneRiskGrade':
        return (
          <>
            <path d="M8.0026 5.33301C6.52927 5.33301 5.33594 6.52634 5.33594 7.99967C5.33594 9.47301 6.52927 10.6663 8.0026 10.6663C9.47594 10.6663 10.6693 9.47301 10.6693 7.99967C10.6693 6.52634 9.47594 5.33301 8.0026 5.33301ZM8.0026 9.33301C7.26927 9.33301 6.66927 8.73301 6.66927 7.99967C6.66927 7.26634 7.26927 6.66634 8.0026 6.66634C8.73594 6.66634 9.33594 7.26634 9.33594 7.99967C9.33594 8.73301 8.73594 9.33301 8.0026 9.33301Z" />
            <path d="M14.6693 4.97967V3.56634C13.3693 3.17967 11.0426 2.66634 8.0026 2.66634C6.56927 2.66634 5.2626 3.23967 4.30927 4.15967C4.39594 3.59301 4.57594 2.55967 4.9826 1.33301H3.56927C3.1826 2.63301 2.66927 4.95967 2.66927 7.99967C2.66927 9.43301 3.2426 10.7397 4.1626 11.693C3.59594 11.5997 2.5626 11.4263 1.33594 11.0197V12.433C2.63594 12.8197 4.9626 13.333 8.0026 13.333C9.43594 13.333 10.7426 12.7597 11.6959 11.8397C11.6026 12.4063 11.4293 13.4397 11.0226 14.6663H12.4359C12.8226 13.3663 13.3359 11.0397 13.3359 7.99967C13.3359 6.56634 12.7626 5.25967 11.8426 4.30634C12.4093 4.39301 13.4426 4.57301 14.6693 4.97967ZM8.0026 11.9997C5.79594 11.9997 4.0026 10.2063 4.0026 7.99967C4.0026 5.79301 5.79594 3.99967 8.0026 3.99967C10.2093 3.99967 12.0026 5.79301 12.0026 7.99967C12.0026 10.2063 10.2093 11.9997 8.0026 11.9997Z" />
          </>
        );
      case 'earthquakeRiskGrade':
        return (
          <path d="M8.0026 1.33301C4.3226 1.33301 1.33594 4.31967 1.33594 7.99967C1.33594 11.6797 4.3226 14.6663 8.0026 14.6663C11.6826 14.6663 14.6693 11.6797 14.6693 7.99967C14.6693 4.31967 11.6826 1.33301 8.0026 1.33301ZM2.66927 7.99967C2.66927 7.59301 2.7226 7.19301 2.80927 6.81301L5.99594 9.99967V10.6663C5.99594 11.3997 6.59594 11.9997 7.32927 11.9997V13.2863C4.70927 12.953 2.66927 10.713 2.66927 7.99967ZM11.9293 11.5997C11.7559 11.0597 11.2626 10.6663 10.6626 10.6663H9.99594V8.66634C9.99594 8.29967 9.69594 7.99967 9.32927 7.99967H5.32927V6.66634H6.6626C7.02927 6.66634 7.32927 6.36634 7.32927 5.99967V4.66634H8.6626C9.39594 4.66634 9.99594 4.06634 9.99594 3.33301V3.05967C11.9493 3.84634 13.3359 5.76634 13.3359 7.99967C13.3359 9.38634 12.7959 10.653 11.9293 11.5997Z" />
        );
      case 'extraTropicalCycloneRiskGrade':
        return (
          <>
            <g clipPath="url(#clip0_2950_32494)">
              <path d="M8.93666 3.7827L10.3468 0.548828L7.42719 2.7053L7.41494 2.69648L7.413 2.71573L4.11613 5.15089L1.49581 2.75173L2.60619 6.26608L2.60263 6.26864L2.60769 6.27086L3.83222 10.1468L0.742188 12.0283L8.53463 11.8449L9.33425 15.4514L11.6987 7.94564L15.2513 8.2893L8.93666 3.7827ZM7.485 9.34252C6.48197 9.34252 5.66891 8.52942 5.66891 7.52645C5.66891 6.52339 6.48197 5.71036 7.485 5.71036C8.48803 5.71036 9.30106 6.52339 9.30106 7.52645C9.30106 8.52945 8.48803 9.34252 7.485 9.34252Z" />
            </g>
            <defs>
              <clipPath id="clip0_2950_32494">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </>
        );
      case 'geocodingQuality':
        return (
          <path d="M8.0026 1.33301C5.4226 1.33301 3.33594 3.41967 3.33594 5.99967C3.33594 9.49967 8.0026 14.6663 8.0026 14.6663C8.0026 14.6663 12.6693 9.49967 12.6693 5.99967C12.6693 3.41967 10.5826 1.33301 8.0026 1.33301ZM8.0026 7.66634C7.0826 7.66634 6.33594 6.91967 6.33594 5.99967C6.33594 5.07967 7.0826 4.33301 8.0026 4.33301C8.9226 4.33301 9.66927 5.07967 9.66927 5.99967C9.66927 6.91967 8.9226 7.66634 8.0026 7.66634Z" />
        );
      case 'hailstormRiskGrade':
        return (
          <path d="M14.6693 7.33301H11.8893L14.0493 5.17301L13.1093 4.22634L10.0026 7.33301H8.66927V5.99967L11.7759 2.89301L10.8293 1.95301L8.66927 4.11301V1.33301H7.33594V4.11301L5.17594 1.95301L4.22927 2.89301L7.33594 5.99967V7.33301H6.0026L2.89594 4.22634L1.95594 5.17301L4.11594 7.33301H1.33594V8.66634H4.11594L1.95594 10.8263L2.89594 11.773L6.0026 8.66634H7.33594V9.99967L4.22927 13.1063L5.17594 14.0463L7.33594 11.8863V14.6663H8.66927V11.8863L10.8293 14.0463L11.7759 13.1063L8.66927 9.99967V8.66634H10.0026L13.1093 11.773L14.0493 10.8263L11.8893 8.66634H14.6693V7.33301Z" />
        );
      case 'insuredValue':
        return (
          <>
            <path d="M14 3.33366L8 0.666992L2 3.33366V7.33366C2 11.0337 4.56 14.4937 8 15.3337C9.53333 14.9603 10.8867 14.067 11.92 12.8603L9.84 10.7803C8.54667 11.6403 6.78667 11.4937 5.64667 10.3537C4.34667 9.05366 4.34667 6.94033 5.64667 5.64033C6.94667 4.34033 9.06 4.34033 10.36 5.64033C11.5 6.78033 11.64 8.54033 10.7867 9.83366L12.72 11.767C13.5267 10.4603 14 8.92033 14 7.33366V3.33366Z" />
            <path d="M8 10.0003C9.10457 10.0003 10 9.10489 10 8.00033C10 6.89576 9.10457 6.00033 8 6.00033C6.89543 6.00033 6 6.89576 6 8.00033C6 9.10489 6.89543 10.0003 8 10.0003Z" />
          </>
        );
      case 'lightningRiskGrade':
        return (
          <>
            <path d="M11.9493 4.01366C11.6359 2.12033 9.9826 0.666992 8.0026 0.666992C6.54927 0.666992 5.2226 1.45366 4.5226 2.70699C2.72927 2.94033 1.33594 4.49366 1.33594 6.33366C1.33594 8.35366 2.9826 10.0003 5.0026 10.0003H11.6693C13.3226 10.0003 14.6693 8.65366 14.6693 7.00033C14.6693 5.44033 13.4759 4.15366 11.9493 4.01366ZM11.6693 8.66699H5.0026C3.71594 8.66699 2.66927 7.62033 2.66927 6.33366C2.66927 5.17366 3.5426 4.18033 4.69594 4.02699L5.35594 3.94033L5.6826 3.36033C6.15594 2.52033 7.0426 2.00033 8.0026 2.00033C9.29594 2.00033 10.4226 2.96033 10.6359 4.23366L10.8026 5.24699L11.8293 5.34033C12.6759 5.42033 13.3359 6.14699 13.3359 7.00033C13.3359 7.92033 12.5893 8.66699 11.6693 8.66699Z" />
            <path d="M9.86927 10.667L7.93594 12.8803L9.26927 13.547L7.7026 15.3337H9.46927L11.4026 13.1203L10.0693 12.4537L11.6359 10.667H9.86927Z" />
            <path d="M5.86927 10.667L3.93594 12.8803L5.26927 13.547L3.7026 15.3337H5.46927L7.4026 13.1203L6.06927 12.4537L7.63594 10.667H5.86927Z" />
          </>
        );
      case 'perils':
        return (
          <path d="M0.664062 14.3337H15.3307L7.9974 1.66699L0.664062 14.3337ZM8.66406 12.3337H7.33073V11.0003H8.66406V12.3337ZM8.66406 9.66699H7.33073V7.00033H8.66406V9.66699Z" />
        );
      case 'riskGrade':
        return (
          <>
            <path d="M10.6667 7.99967L6 1.33301L1.33333 7.99967H2.57333L0 11.9997H4.66667V14.6663H7.33333V11.9997H12L9.42667 7.99967H10.6667Z" />
            <path d="M13.4267 7.99967H14.6667L10 1.33301L8.40667 3.60634L11.9467 8.66634H10.6467L12.7933 11.9997H16L13.4267 7.99967Z" />
            <path d="M11.3333 12.6663H8.66667V14.6663H11.3333V12.6663Z" />
          </>
        );
      case 'riverineFloodRiskGrade':
        return (
          <path d="M3.56927 8.33301C4.3626 8.33301 4.51594 8.99967 5.78927 8.99967C7.08927 8.99967 7.1826 8.33301 8.00927 8.33301C8.8026 8.33301 8.95594 8.99967 10.2293 8.99967C11.5293 8.99967 11.6226 8.33301 12.4493 8.33301C13.2426 8.33301 13.3826 8.98634 14.6559 8.99967V7.66634C13.8626 7.66634 13.7093 6.99967 12.4359 6.99967C11.1359 6.99967 11.0426 7.66634 10.2159 7.66634C9.4226 7.66634 9.26927 6.99967 7.99594 6.99967C6.69594 6.99967 6.6026 7.66634 5.77594 7.66634C4.9826 7.66634 4.82927 6.99967 3.55594 6.99967C2.25594 6.99967 2.1626 7.66634 1.33594 7.66634V8.99967C2.6026 8.99967 2.7826 8.33301 3.56927 8.33301ZM12.4493 9.66634C11.1493 9.66634 11.0559 10.333 10.2293 10.333C9.43594 10.333 9.2826 9.66634 8.00927 9.66634C6.70927 9.66634 6.60927 10.333 5.7826 10.333C4.95594 10.333 4.8626 9.66634 3.5626 9.66634C2.2626 9.66634 2.1626 10.333 1.33594 10.333V11.6663C2.63594 11.6663 2.7426 10.9997 3.5626 10.9997C4.38927 10.9997 4.4826 11.6663 5.7826 11.6663C7.0826 11.6663 7.1826 10.9997 8.00927 10.9997C8.8026 10.9997 8.95594 11.6663 10.2293 11.6663C11.5226 11.6663 11.6226 10.9997 12.4493 10.9997C13.2426 10.9997 13.3959 11.6663 14.6693 11.6663V10.333C13.8426 10.333 13.7493 9.66634 12.4493 9.66634ZM3.56927 5.66634C4.3626 5.66634 4.51594 6.33301 5.78927 6.33301C7.08927 6.33301 7.1826 5.66634 8.00927 5.66634C8.8026 5.66634 8.95594 6.33301 10.2293 6.33301C11.5293 6.33301 11.6226 5.66634 12.4493 5.66634C13.2426 5.66634 13.3826 6.31967 14.6559 6.33301V4.99967C13.8626 4.99967 13.7093 4.33301 12.4359 4.33301C11.1359 4.33301 11.0426 4.99967 10.2159 4.99967C9.4226 4.99967 9.26927 4.33301 7.99594 4.33301C6.69594 4.33301 6.6026 4.99967 5.77594 4.99967C4.9826 4.99967 4.82927 4.33301 3.55594 4.33301C2.25594 4.33301 2.1626 4.99967 1.33594 4.99967V6.33301C2.6026 6.33301 2.7826 5.66634 3.56927 5.66634Z" />
        );
      case 'surfaceRiskGrade':
        return (
          <path d="M7.99594 1.33301C4.31594 1.33301 1.33594 4.31967 1.33594 7.99967C1.33594 11.6797 4.31594 14.6663 7.99594 14.6663C11.6826 14.6663 14.6693 11.6797 14.6693 7.99967C14.6693 4.31967 11.6826 1.33301 7.99594 1.33301ZM12.6159 5.33301H10.6493C10.4359 4.49967 10.1293 3.69967 9.72927 2.95967C10.9559 3.37967 11.9759 4.23301 12.6159 5.33301ZM8.0026 2.69301C8.55594 3.49301 8.98927 4.37967 9.27594 5.33301H6.72927C7.01594 4.37967 7.44927 3.49301 8.0026 2.69301ZM2.8426 9.33301C2.73594 8.90634 2.66927 8.45967 2.66927 7.99967C2.66927 7.53967 2.73594 7.09301 2.8426 6.66634H5.09594C5.0426 7.10634 5.0026 7.54634 5.0026 7.99967C5.0026 8.45301 5.0426 8.89301 5.09594 9.33301H2.8426ZM3.38927 10.6663H5.35594C5.56927 11.4997 5.87594 12.2997 6.27594 13.0397C5.04927 12.6197 4.02927 11.773 3.38927 10.6663ZM5.35594 5.33301H3.38927C4.02927 4.22634 5.04927 3.37967 6.27594 2.95967C5.87594 3.69967 5.56927 4.49967 5.35594 5.33301ZM8.0026 13.3063C7.44927 12.5063 7.01594 11.6197 6.72927 10.6663H9.27594C8.98927 11.6197 8.55594 12.5063 8.0026 13.3063ZM9.5626 9.33301H6.4426C6.3826 8.89301 6.33594 8.45301 6.33594 7.99967C6.33594 7.54634 6.3826 7.09967 6.4426 6.66634H9.5626C9.6226 7.09967 9.66927 7.54634 9.66927 7.99967C9.66927 8.45301 9.6226 8.89301 9.5626 9.33301ZM9.72927 13.0397C10.1293 12.2997 10.4359 11.4997 10.6493 10.6663H12.6159C11.9759 11.7663 10.9559 12.6197 9.72927 13.0397ZM10.9093 9.33301C10.9626 8.89301 11.0026 8.45301 11.0026 7.99967C11.0026 7.54634 10.9626 7.10634 10.9093 6.66634H13.1626C13.2693 7.09301 13.3359 7.53967 13.3359 7.99967C13.3359 8.45967 13.2693 8.90634 13.1626 9.33301H10.9093Z" />
        );
      case 'tornadoRiskGrade':
        return (
          <path d="M15.3307 1.66699H0.664062L7.9974 14.3337L15.3307 1.66699ZM13.0174 3.00033L11.8574 5.00033H4.1374L2.9774 3.00033H13.0174ZM6.8374 9.66699H9.1574L7.9974 11.6737L6.8374 9.66699ZM9.93073 8.33366H6.06406L4.90406 6.33366H11.0841L9.93073 8.33366Z" />
        );
      case 'tropicalCycloneRiskGrade':
        return (
          <path d="M10 4.52634C11.24 5.23967 12 6.56634 12 7.99967C12 14.6663 4 14.6663 4 14.6663C4.83333 14.0397 5.58667 13.2997 6.22667 12.473C6.28743 12.3958 6.33018 12.306 6.3518 12.2101C6.37342 12.1143 6.37337 12.0148 6.35165 11.919C6.32993 11.8232 6.28709 11.7334 6.22625 11.6562C6.16541 11.5791 6.08812 11.5165 6 11.473C4.76 10.7597 4 9.43301 4 7.99967C4 1.33301 12 1.33301 12 1.33301C11.1667 1.95967 10.4133 2.69967 9.77333 3.52634C9.71257 3.60356 9.66983 3.69337 9.6482 3.78922C9.62658 3.88507 9.62663 3.98453 9.64835 4.08036C9.67007 4.17619 9.71291 4.26595 9.77375 4.34311C9.83459 4.42027 9.91188 4.48287 10 4.52634ZM8 9.33301C8.35362 9.33301 8.69276 9.19253 8.94281 8.94248C9.19286 8.69243 9.33333 8.3533 9.33333 7.99967C9.33333 7.64605 9.19286 7.30691 8.94281 7.05687C8.69276 6.80682 8.35362 6.66634 8 6.66634C7.64638 6.66634 7.30724 6.80682 7.05719 7.05687C6.80714 7.30691 6.66667 7.64605 6.66667 7.99967C6.66667 8.3533 6.80714 8.69243 7.05719 8.94248C7.30724 9.19253 7.64638 9.33301 8 9.33301Z" />
        );
      case 'tsunamiRiskGrade':
        return (
          <>
            <path d="M12.4493 11.7533C9.91594 13.62 8.36927 12.02 8.0026 11.7533C7.5626 12.08 6.05594 13.5933 3.55594 11.7533C2.28927 12.6867 1.76927 12.6667 1.33594 12.6667V14C2.10927 14 2.86927 13.7867 3.55594 13.38C4.92927 14.1933 6.62927 14.1933 8.0026 13.38C9.37594 14.1933 11.0759 14.1933 12.4493 13.38C13.1359 13.7867 13.8959 14 14.6693 14V12.6667C14.2293 12.6667 13.6693 12.6533 12.4493 11.7533Z" />
            <path d="M12.8893 8H14.6693V6.66667H12.8893C11.6693 6.66667 10.6693 5.66667 10.6693 4.44667C10.6693 3.76667 10.9226 3.28667 11.3959 2.22C10.4826 2.08 10.0626 2 9.33594 2C4.90927 2 1.43594 5.35333 1.3426 9.66667L1.33594 11C2.10927 11 2.86927 10.7867 3.55594 10.38C4.92927 11.1933 6.62927 11.1933 8.0026 10.38C9.37594 11.1933 11.0759 11.1933 12.4493 10.38C13.1359 10.7867 13.8959 11 14.6693 11V9.66667C14.2293 9.66667 13.6693 9.65333 12.4493 8.75333C9.91594 10.62 8.36927 9.02 8.0026 8.75333C7.4026 9.2 7.6426 9.02667 7.39594 9.17333C6.92927 8.54667 6.66927 7.8 6.66927 7C6.66927 5.28 7.84927 3.84 9.47594 3.44667C9.38927 3.78667 9.33594 4.12667 9.33594 4.44667C9.33594 6.40667 10.9293 8 12.8893 8ZM3.55594 8.75333C3.1426 9.06 3.00927 9.17333 2.68927 9.33333C2.86927 6.98 4.27594 5.01333 6.30927 4.02667C5.69594 4.86 5.33594 5.88667 5.33594 7C5.33594 7.94667 5.6026 8.84667 6.08927 9.63333C5.60927 9.68 4.89594 9.73333 3.55594 8.75333Z" />
          </>
        );
      case 'volcanoRiskGrade':
        return (
          <>
            <path d="M12.0026 5.66667H7.33594L6.0026 9H4.0026L1.33594 15H14.6693L12.0026 5.66667ZM4.86927 10.3333H6.9026L7.23594 9.49333L8.23594 7H10.9959L12.9026 13.6667H3.38927L4.86927 10.3333Z" />
            <path d="M10.0026 1H8.66927V3.66667H10.0026V1Z" />
            <path d="M12.6357 2.09326L10.7501 3.97886L11.6929 4.92166L13.5785 3.03606L12.6357 2.09326Z" />
            <path d="M6.03853 2.09073L5.09573 3.03353L6.98133 4.91913L7.92413 3.97633L6.03853 2.09073Z" />
          </>
        );
      case 'wildfireRiskGrade':
        return (
          <path d="M12.9841 8.40107C11.9374 5.68107 8.21073 5.5344 9.11073 1.58107C9.1774 1.28774 8.86406 1.06107 8.61073 1.2144C6.19073 2.64107 4.45073 5.50107 5.91073 9.24774C6.03073 9.5544 5.67073 9.84107 5.41073 9.64107C4.20406 8.72774 4.0774 7.4144 4.18406 6.4744C4.22406 6.12774 3.77073 5.96107 3.5774 6.24774C3.12406 6.94107 2.66406 8.06107 2.66406 9.74774C2.9174 13.4811 6.07073 14.6277 7.20406 14.7744C8.82406 14.9811 10.5774 14.6811 11.8374 13.5277C13.2241 12.2411 13.7307 10.1877 12.9841 8.40107ZM6.7974 11.7544C7.7574 11.5211 8.25073 10.8277 8.38406 10.2144C8.60406 9.26107 7.74406 8.32774 8.32406 6.82107C8.54406 8.06774 10.5041 8.84774 10.5041 10.2077C10.5574 11.8944 8.73073 13.3411 6.7974 11.7544Z" />
        );
      default:
        return (
          <path d="M7.9974 1.33301C4.44406 4.36634 2.66406 6.98634 2.66406 9.19967C2.66406 12.5197 5.1974 14.6663 7.9974 14.6663C10.7974 14.6663 13.3307 12.5197 13.3307 9.19967C13.3307 6.98634 11.5507 4.36634 7.9974 1.33301ZM7.9974 13.333C5.76406 13.333 3.9974 11.6197 3.9974 9.19967C3.9974 7.63967 5.2974 5.57301 7.9974 3.10634C10.6974 5.57301 11.9974 7.63301 11.9974 9.19967C11.9974 11.6197 10.2307 13.333 7.9974 13.333ZM5.2174 9.33301C5.46406 9.33301 5.66406 9.50634 5.71073 9.74634C5.98406 11.2263 7.23073 11.733 8.1374 11.6597C8.42406 11.6463 8.66406 11.873 8.66406 12.1597C8.66406 12.4263 8.45073 12.6463 8.18406 12.6597C6.76406 12.7463 5.10406 11.933 4.72406 9.91301C4.67073 9.61301 4.91073 9.33301 5.2174 9.33301Z" />
        );
    }
  }, [name]);
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="inherit" color="inherit" {...props}>
      {path}
    </SvgIcon>
  );
};

export default RiskIcon;
