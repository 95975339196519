import { ButtonBottom } from '@components/atoms/buttons';
import { Box, styled } from '@mui/material';

export const StyledButton = styled(ButtonBottom)(() => ({}));

export const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'row',
}));

export const LeftIcon = styled('img')``;
