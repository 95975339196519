import React, { useState } from 'react';
import { PortfolioSiteListProps } from './PortfolioSiteList.types';
import { Typography, useMediaQuery, Theme, Link } from '@mui/material';
import { SiteGrid } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import {
  PortfolioFilter,
  getColumns,
  portfolioFilters,
} from '@components/molecules/portfolio/SiteGrid/datadef';
import {
  CenteredContent,
  QuickFilterCentered,
  SitesContainer,
} from './PortfolioSiteList.styles';
import { UnmarginBox } from '@components/templates';
import { ModalGeocodingExplanation } from '../ModalGeocodingExplanation';
import { getColumnsPerCountry } from '@components/molecules/portfolio/SiteGrid/datadefCountry';
import { AllSitesTopSection } from '../AllSitesTopSection';

const PortfolioSiteList: React.FC<PortfolioSiteListProps> = ({
  items,
  initialSort,
  openModal,
  itemsCountry,
  companyCurrency,
  ...props
}) => {
  const { t } = useTranslation();
  const matches = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('tablet')
  );
  const [isOpenModalGeocoding, setIsOpenModalGeocoding] =
    useState<boolean>(false);
  const [filter, setFilter] = useState<PortfolioFilter | undefined>(
    initialSort !== null
      ? portfolioFilters.find((portfolioFilter) =>
          initialSort?.find(
            (sort) =>
              portfolioFilter.sort !== undefined &&
              sort.field === portfolioFilter?.sort[0].field &&
              sort.sort === portfolioFilter?.sort[0].sort
          )
        ) || portfolioFilters[matches ? 0 : 1]
      : portfolioFilters[matches ? 0 : 1]
  );
  return (
    <SitesContainer {...props}>
      <ModalGeocodingExplanation
        isOpen={isOpenModalGeocoding}
        closeText={'Close'}
        onClose={() => setIsOpenModalGeocoding(false)}
      />
      <AllSitesTopSection
        titleSection={t('portfolio.title')}
        descriptionScetion={t('portfolio.tab.all_sites_section.description')}
        titleMapView={t('portfolio.tab.all_sites_section.title')}
        descriptionMapView={t(
          'portfolio.tab.all_sites_section.description_map'
        )}
        titleButtonMapView={t('portfolio.map.action')}
        textLink={t('site.risk.tooltip.link')}
        sourceText={t('footer.risk-attributes')}
        openModal={openModal}
      />

      <UnmarginBox>
        <QuickFilterCentered
          items={portfolioFilters}
          nameExtractor={(item: PortfolioFilter) =>
            t(`portfolio.filters.${item?.name}.name`)
          }
          selected={filter}
          onItemChange={setFilter}
        />
      </UnmarginBox>
      <CenteredContent sx={{ marginTop: -2 }}>
        <Typography variant="paragraphMedium">
          {t(`portfolio.filters.${filter?.name}.description`)}{' '}
          {filter?.name === 'dataQuality' && (
            <Link onClick={() => setIsOpenModalGeocoding(true)}>
              {t('portfolio.filters.dataQuality.learn_more')}
            </Link>
          )}
        </Typography>
      </CenteredContent>

      <SiteGrid
        columnVisibilityModel={filter?.columns}
        sortModel={filter?.sort}
        width={filter?.width}
        columns={
          filter?.name === 'riskCountry'
            ? getColumnsPerCountry(t, companyCurrency || '')
            : getColumns(t, companyCurrency || '', openModal)
        }
        onSortModelChange={(sort) =>
          setFilter((prevFilter) => ({ ...prevFilter, sort }))
        }
        items={filter?.name === 'riskCountry' ? itemsCountry : items}
        openModal={openModal}
      />
    </SitesContainer>
  );
};

export default PortfolioSiteList;
