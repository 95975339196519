import { Box, Card, CardContent, styled } from '@mui/material';

export const CardIcon = styled('img')`
  width: 56px;
  height: 56px;
  margin: 4px 12px;
`;

export const TextContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  whitespace: normal;
`;

export const ContentContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
`;

export const CardContentFlex = styled(CardContent)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1;
`;

export const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex: 1;
  height: 100%;
`;

CardContainer.defaultProps = {
  elevation: 2,
};
