import React from 'react';
import { Button, Skeleton } from '@mui/material';
import { ResponsiveButtonLoadingProps } from './button.types';

const ResponsiveButtonSkeleton: React.FC<ResponsiveButtonLoadingProps> = ({
  id,
  size,
}) => {
  return (
    <Skeleton width={'100%'}>
      <Button id={id} disabled={true} size={size} />
    </Skeleton>
  );
};

export default ResponsiveButtonSkeleton;
