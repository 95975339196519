export enum TotalRiskGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}
export enum ColorRiskGrade {
  A = '#138636',
  B = '#8ab957',
  C = '#f2ce0d',
  D = '#ff9900',
  E = '#c91432',
}

export enum PerilRiskGrade {
  Negligible = 'negligible',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Extreme = 'extreme',
}

export const PerilRiskGradeOrdinate = {
  [PerilRiskGrade.Negligible]: 0,
  [PerilRiskGrade.Low]: 1,
  [PerilRiskGrade.Medium]: 2,
  [PerilRiskGrade.High]: 3,
  [PerilRiskGrade.Extreme]: 4,
};

export interface GeophysicalInsuredRisk {
  earthquake: number;
  volcano: number;
  tsunami: number;
}

export interface HydrologicalInsuredRisk {
  riverineFlood: number;
  surfaceWaterFlood: number;
  coastalFlood: number;
}

export interface MeteorologicalInsuredRisk {
  tropicalCyclone: number;
  extraTropicalCyclone: number;
  hailstorm: number;
  tornado: number;
  lightning: number;
}

export interface WildfireRisk {
  wildfire: number;
}

export interface InsuredRisk
  extends GeophysicalInsuredRisk,
    HydrologicalInsuredRisk,
    MeteorologicalInsuredRisk,
    WildfireRisk {
  totalHazard: number;
}

export interface PerilScoredSummary {
  perilScoreSummary: InsuredRisk;
}

export interface IsoRiskMatrix {
  isoriskCoordinates: {
    x: number;
    y: number;
  };
}

export interface GeophysicalInsuredRiskGrade {
  earthquake: PerilRiskGrade;
  volcano: PerilRiskGrade;
  tsunami: PerilRiskGrade;
}

export interface HydrologicalInsuredRiskGrade {
  riverineFlood: PerilRiskGrade;
  surfaceWaterFlood: PerilRiskGrade;
  coastalFlood: PerilRiskGrade;
}

export interface MeteorologicalInsuredRiskGrade {
  tropicalCyclone: PerilRiskGrade;
  extraTropicalCyclone: PerilRiskGrade;
  hailstorm: PerilRiskGrade;
  tornado: PerilRiskGrade;
  lightning: PerilRiskGrade;
}

export interface wildfireRiskGrade {
  wildfire: PerilRiskGrade;
}

export interface CategoryPerilsRiskGrade {
  categoryRiskGradeSummary: {
    geophysical: PerilRiskGrade;
    hydrological: PerilRiskGrade;
    meteorological: PerilRiskGrade;
    wildfire: PerilRiskGrade;
  };
}
export interface AllPerilsRisk
  extends GeophysicalInsuredRiskGrade,
    HydrologicalInsuredRiskGrade,
    MeteorologicalInsuredRiskGrade,
    wildfireRiskGrade {}

export interface LegacyPerilsRisk {
  perilGradeSummary: AllPerilsRisk;
}

export interface SitesPerRiskGrade {
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
}
