import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { extractCountry } from '../../../../utils';
import { TitleIconResponsive } from '../../navigation/TitleIconResponsive';
import {
  AdressSectionContainer,
  InLineText,
  InfoContainer,
  InfoContent,
  InfoItemContainer,
  PreLineText,
  WrongAddressButton,
} from './InfoDetails.styles';
import { InfoDetailsProps } from './InfoDetails.types';

const InfoItem: React.FC<{
  src: string;
  separator?: boolean;
  title: string;
  alt: string;
  children?: React.ReactElement | React.ReactElement[];
}> = ({ src, title, separator, children, alt, ...props }) => {
  return (
    <InfoItemContainer {...props}>
      <TitleIconResponsive marginIcon src={src} alt={alt}>
        {title}
      </TitleIconResponsive>
      <InfoContent borderBottom={separator}>{children}</InfoContent>
    </InfoItemContainer>
  );
};

const InfoDetails: React.FC<InfoDetailsProps> = ({
  address,
  identifier,
  occupancy,
  location,
  dataQuality,
  fieldAddress,
  fieldId,
  fieldName,
  fieldOccupancy,
  fieldLocation,
  fieldConfidence,
  fieldResolution,
  fieldSource,
  confidence,
  resolution,
  source,
  wrongAdressTitle,
  onSendMessage,
  isLoading,
  errorMessage,
  coachID,
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );

  const postMessage = () =>
    onSendMessage({
      contactId: coachID,
      message: errorMessage,
      subject: 'location data is wrong',
    });

  return (
    <InfoContainer id="site-component-details">
      <InfoItem src="/pin.svg" alt="risk" title={fieldAddress} separator>
        <AdressSectionContainer>
          <PreLineText variant={isSmall ? 'paragraphMedium' : 'lead'}>
            {address?.street || address?.city
              ? address?.street && address?.city && address?.postalcode
                ? (
                  <>
                    {address?.street}
                    {'\n'}
                    {address?.city}
                    {'\n'}
                    {address?.postalcode}
                  </>
                )
                : address?.street || address?.city
              : 'No address'
            }
            {'\n'}
            {extractCountry(address.countryIso2)}
          </PreLineText>
          <WrongAddressButton
            id="individual-site-button-wrong-address"
            variant="contained"
            size={isSmall ? 'medium' : 'small'}
            onClick={postMessage}
            disabled={isLoading}
          >
            {wrongAdressTitle}
          </WrongAddressButton>
        </AdressSectionContainer>
      </InfoItem>

      <InfoItem src="/details.svg" alt="risk" title={fieldLocation} separator>
        <PreLineText variant={isSmall ? 'paragraphMedium' : 'lead'}>
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldId}
          </InLineText>{' '}
          {identifier}
          {'\n'}
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldName}
          </InLineText>{' '}
          {location} {'\n'}
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldOccupancy}
          </InLineText>{' '}
          {occupancy}
          {'\n'}
        </PreLineText>
      </InfoItem>
      <InfoItem src="/data-quality.svg" alt="risk" title={dataQuality}>
        <PreLineText variant={isSmall ? 'paragraphMedium' : 'lead'}>
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldResolution}
          </InLineText>{' '}
          {resolution}
          {'\n'}
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldConfidence}
          </InLineText>{' '}
          {confidence} {'\n'}
          <InLineText variant={isSmall ? 'h3' : 'leadSemi'}>
            {fieldSource}
          </InLineText>{' '}
          {source}
          {'\n'}
        </PreLineText>
      </InfoItem>
    </InfoContainer>
  );
};

export default InfoDetails;
