import React from 'react';
import { Skeleton, Box, useMediaQuery, Theme } from '@mui/material';
import { ArrowButtonsStack } from './SiteLinks.styles';

const SiteLinksSkeleton: React.FC = () => {
  const height = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'))
    ? 186
    : 218;

  return (
    <>
      <Box style={{ marginBottom: 8 }}>
        <Skeleton
          variant="rectangular"
          height={height}
          style={{ marginBottom: 2 }}
        />
        <Skeleton variant="rectangular" height={50} />
      </Box>
      <ArrowButtonsStack>
        <Skeleton variant="rectangular" height={100} width={'50%'} />
        <Skeleton variant="rectangular" height={100} width={'50%'} />
      </ArrowButtonsStack>
    </>
  );
};

export default SiteLinksSkeleton;
