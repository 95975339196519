import React from 'react';
import { Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import {
  BarItem,
  GradingContainer,
  ItemContainer,
  TitleItem,
} from './ItemPortfolioRiskGrading.styles';

const ItemPortfolioRiskGradingSkeleton = () => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return (
    <ItemContainer>
      <GradingContainer>
        <TitleItem>
          <Skeleton variant="circular" sx={{ width: 20 }} />
          <Skeleton sx={{ marginLeft: 1 }}>
            {!isSmall ? (
              <Typography>Extreme (A)</Typography>
            ) : (
              <Typography>(A)</Typography>
            )}
          </Skeleton>
        </TitleItem>
        <BarItem>
          <Skeleton width={'35%'} height={40} />
        </BarItem>
      </GradingContainer>
      <Skeleton>
        <Typography>23 LOCATIONS</Typography>
      </Skeleton>
    </ItemContainer>
  );
};

export default ItemPortfolioRiskGradingSkeleton;
