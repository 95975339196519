import React from 'react';
import { SectionTitleProps } from './SectionTitle.types';
import { TitleIcon } from '@components/molecules/navigation/TitleIcon';
import { Typography } from '@mui/material';

const SectionTitle: React.FC<SectionTitleProps> = ({
  icon,
  empty,
  isEmpty,
  title,
  description,
  titleTooltip,
  descriptionTooltip,
}) => (
  <>
    <TitleIcon
      id="resources-title"
      {...icon}
      titleTooltip={titleTooltip}
      descriptionTooltip={descriptionTooltip}
    >
      {!!empty && isEmpty ? empty.title : title}
    </TitleIcon>
    <Typography title="resources-subtitle" variant="paragraph">
      {!!empty && isEmpty ? empty.description : description}
    </Typography>
  </>
);

export default SectionTitle;
