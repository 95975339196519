import React, { useEffect, useState } from 'react';
import { Popup, useMap } from 'react-map-gl';
import { SiteSummaryCard } from '@components/molecules';
import { MapSitePopupProps } from './MapSitePopup.types';

// todo: find a better map style override
import './MapSitePopup.css';
import { SiteMapFeature } from '@modules/types';
const MapSitePopup: React.FC<MapSitePopupProps> = ({
  sites,
  onClose,
  anchor = 'left',
  companyCurrency,
}) => {
  const map = useMap();

  const [currentSiteSummary, setCurrentSiteSummary] = useState<
    SiteMapFeature | undefined
  >(sites.length === 1 ? sites[0] : undefined);

  useEffect(() => {
    if (sites && sites.length > 0) {
      let latitude = sites[0]?.lat;
      if (anchor === 'bottom') {
        const zoom = map?.current?.getZoom() || 3;

        latitude = Math.min(
          90,
          latitude + (200 * 180) / (256 * Math.pow(2, zoom))
        );
      }

      map?.current?.easeTo({
        center: { lon: sites[0]?.lon, lat: latitude },
        duration: 500,
      });
    }
  }, [map, sites]);

  return (
    <Popup
      // avoid popup overflow on the east
      anchor={
        anchor === 'left'
          ? Number(sites[0].lon) < 104
            ? 'left'
            : 'right'
          : 'bottom'
      }
      longitude={Number(currentSiteSummary?.lon || sites[0].lon)}
      latitude={Number(currentSiteSummary?.lat || sites[0].lat)}
      onClose={onClose}
      maxWidth="300px"
      offset={20}
    >
      <SiteSummaryCard
        selectedSite={currentSiteSummary}
        sites={sites}
        onSelectSite={setCurrentSiteSummary}
        companyCurrency={companyCurrency}
      />
    </Popup>
  );
};

export default React.memo(MapSitePopup);
