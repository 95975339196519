import { FlexBox } from '@components/templates';
import { Typography, styled } from '@mui/material';

export const RiskTitleContainer = styled(FlexBox)(() => ({
  alignItems: 'center',
  gap: 16,
}));

export const LabelContainer = styled(Typography)(() => ({
  textTransform: 'capitalize',
}));
