import { FlexBox, FlexColumnBox } from '@components/templates';
import { Box, Typography, styled } from '@mui/material';
//import { DataGrid } from '@mui/x-data-grid';

export const GridContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.not('mobile')]: {
    flex: '1 1 410px',
  },
  maxWidth: 816,
}));

export const TitleContainer = styled(FlexColumnBox)(({ theme, color }) => ({
  backgroundColor: color,
  padding: theme.spacing(1.25, 0),
  textAlign: 'center',
  //Todo: Save the color in the palette (check with JD)
  border: '1px solid rgba(204, 204, 204)',
  borderRadius: '5px 5px 0% 0%',
}));

export const TitleGrid = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.default,
  textAlign: 'center',
}));

export const StaticGrid = styled(FlexBox)(() => ({
  display: 'grid',
}));

/*export const StaticDataGrid = styled(DataGrid)(({ theme }) => ({
  ['& .MuiDataGrid-main']: {
    boxShadow: 'none !important',
    borderRight: '1px solid rgba(204, 204, 204)',
    borderBottom: '1px solid rgba(204, 204, 204)',
    borderRadius: '0px 0px 5px 5px',
  },
  ['& .MuiDataGrid-columnHeaders']: {
    backgroundColor: 'rgba(204, 204, 204, 0.4)',
    borderBottom: '1px solid rgba(204, 204, 204) !important',
    borderRadius: '0px !important',
  },
  ['& .MuiDataGrid-columnHeader']: {
    padding: theme.spacing(0, 0, 0, 1.5),
    borderLeft: '1px solid rgba(204, 204, 204)',
  },
  ['& .MuiDataGrid-cell']: {
    borderLeft: '1px solid rgba(204, 204, 204)',
    padding: theme.spacing(1.25, 1.5),
  },
}));*/
