import React from 'react';
import { RiskComponentProps } from './RiskComponent.types';
import {
  IconContainer,
  RiskComponentContainer,
  TitleContainer,
} from './RiskComponent.styles';
import { Typography } from '@mui/material';

const RiskComponent: React.FC<RiskComponentProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <RiskComponentContainer>
      <IconContainer>{icon}</IconContainer>
      <TitleContainer variant="h2">{title}</TitleContainer>
      <Typography variant="paragraph" align="center">
        {description}
      </Typography>
    </RiskComponentContainer>
  );
};

export default RiskComponent;
