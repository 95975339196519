import { NAV_BAR_MARGIN } from '@components/organisms/navigation/NavBar/NavBar.styles';
import { Box, Button, buttonClasses, styled } from '@mui/material';

export const ZoomContainer = styled(Box)(({ theme, $smallMargin }) => ({
  position: 'absolute',

  bottom: `${theme.spacing(9)} !important`,
  right: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('desktop')]: {
    display: 'none',
  },
  [theme.breakpoints.only('desktop')]: {
    bottom: theme.spacing($smallMargin ? 5 : 7),
    right: $smallMargin ? theme.spacing(5) : NAV_BAR_MARGIN,
  },
}));

export const IconButtonControl = styled(Button)((/*{ theme }*/) => ({
  padding: 0,
  minWidth: 40,
  minHeight: 40,
  [`& .${buttonClasses.startIcon}`]: {
    margin: 0,
  },
}));
