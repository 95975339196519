import React from 'react';
import { Typography, Card, Skeleton } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { ArticleButton, ChipBox, TitleBox } from './ArticleCard.styles';
import { FlexColumnBox } from '@components/templates';
import { styled } from '@mui/material';

const ArticleCardBoxSkeleton = styled(FlexColumnBox)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(5),
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(2.5),
  },
}));

export default function ArticleCardSkeleton() {
  return (
    <Card id="article-card" sx={{ width: '100%' }}>
      <ArticleCardBoxSkeleton>
        <Skeleton sx={{ transform: 'scale(1,1)', mb: 1 }} height={240} />
        <ChipBox>
          <Skeleton sx={{ borderRadius: 10 }} width={75} />
        </ChipBox>
        <TitleBox>
          <Typography variant="h2">
            <Skeleton width={150} />
          </Typography>
        </TitleBox>
        <Skeleton>
          <ArticleButton size="small" endIcon={<Launch />}>
            read now
          </ArticleButton>
        </Skeleton>
      </ArticleCardBoxSkeleton>
    </Card>
  );
}
