import React from 'react';
import { MapSiteDrawerProps } from './MapSiteDrawer.types';
import { SettingsDrawer } from './MapSiteDrawer.styles';
import { IndividualSiteSummary } from '@components/organisms/portfolio/IndividualSiteSummary';
import { TitleSiteDrawer } from '@components/atoms/TitleSiteDrawer';

const MapSiteDrawer: React.FC<MapSiteDrawerProps> = ({
  onClose,
  site,
  open,
  companyCurrency,
}) => {
  return (
    <SettingsDrawer
      anchor="bottom"
      onClose={onClose}
      open={open}
      variant="persistent"
    >
      <TitleSiteDrawer title={site.name} onClose={onClose} />
      <IndividualSiteSummary site={site} companyCurrency={companyCurrency} />
    </SettingsDrawer>
  );
};

export default MapSiteDrawer;
