import { Typography } from '@mui/material';
import React from 'react';
import { RiskGradeContainer } from './RiskGrade.styles';
import { RiskGradeProps } from './RiskGrade.types';
import { InfoTooltip } from '@components/atoms/InfoTooltip';

const RiskGrade: React.FC<RiskGradeProps> = ({
  label,
  color,
  titleRisk,
  titleTooltip,
  descriptionTooltip,
  ...props
}) => {
  return (
    <RiskGradeContainer {...props}>
      <Typography sx={{ display: 'inline-flex', gap: 0.5 }} variant="lead">
        {titleRisk}
        <Typography color={color} variant="h2">
          {label}
        </Typography>
      </Typography>
      <InfoTooltip title={titleTooltip} description={descriptionTooltip} />
    </RiskGradeContainer>
  );
};

export default RiskGrade;
