import { Box, Skeleton, Typography, styled } from '@mui/material';

export const ChartContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  gap: 20,
  flexWrap: 'wrap',
  justifyContent: 'center',
  [theme.breakpoints.only('tablet')]: {
    padding: theme.spacing(0, 0, 0, 3),
    ['& > *:not(:last-child)']: {
      margin: theme.spacing(0, 3, 0, 0),
    },
  },
  [theme.breakpoints.only('tablet')]: {
    margin: theme.spacing(0, 0, 3, 3),
  },
  [theme.breakpoints.only('mobile')]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('tablet')]: {
    paddingLeft: theme.spacing(3),
  },
}));

export const WrongValueText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.only('tablet')]: {
    paddingLeft: theme.spacing(3),
  },
}));

export const WrongValueSkeleton = styled(Skeleton)(({ theme }) => ({
  [theme.breakpoints.only('tablet')]: {
    marginLeft: theme.spacing(3),
  },
}));

export const TableLine = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('tablet')]: {
    width: '100%',
    borderTop: `2px solid ${theme.palette.border.main}`,
    borderBottom: `2px solid ${theme.palette.border.main}`,
    paddingBottom: '10px',
    paddingTop: '10px',
  },
}));

export const RiskScanningPromotionSkeleton = styled(Skeleton)(() => ({
  width: '100%',
  height: 150,
}));
