import React from 'react';
import { IsoRiskMatrixProps } from './IsoRiskMatrix.types';
import { ScatterChart } from '@mui/x-charts/ScatterChart';
import { useTheme } from '@mui/material';

import { getMarkerCategory } from './isoRiskMatrixHelper';
import {
  BackgroundImageMatrix,
  GraphAndBottomTitleContainer,
  GraphContainer,
  SectionContainer,
  TitleBottomtAxis,
  TitleLeftAxis,
  TooltipContainer,
  scatterChartStyle,
} from './IsoRiskMatrix.styles';
import { currencySymbole, riskGradeColors } from '@utils/numbers';
import { useNavigate } from 'react-router-dom';

const IsoRiskMatrix: React.FC<IsoRiskMatrixProps> = ({
  titleLeftAxis,
  titleBottomAxis,
  siteList,
  percentile90,
  YAxis,
  YLabelAxis,
  currency,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  function handleSiteClick(id: string) {
    navigate(id);
    window.scrollTo(0, 0);
  }
  return (
    <SectionContainer>
      <TitleLeftAxis variant="h3" color={theme.palette.text.primary}>
        {titleLeftAxis}
        {' (' + currencySymbole(currency) + ')'}
      </TitleLeftAxis>
      <GraphAndBottomTitleContainer>
        <GraphContainer>
          <ScatterChart
            series={[
              {
                data: siteList.map((point) => {
                  return {
                    ...point.isoriskCoordinates,
                    id: point.id,
                    z: getMarkerCategory(
                      point.isoriskCoordinates,
                      percentile90
                    ),
                    title: point.name,
                  };
                }),
                markerSize: 6,
                highlightScope: {
                  faded: 'global',
                  highlighted: 'item',
                },
              },
            ]}
            onItemClick={(event: Event, scatter: { dataIndex: number }) => {
              handleSiteClick(siteList[scatter.dataIndex].id);
            }}
            tooltip={{
              trigger: 'item',
              slots: {
                itemContent: (params) => {
                  return (
                    <TooltipContainer variant="paragraphSemi">
                      {params?.itemData?.dataIndex !== undefined &&
                      params.series.data[params.itemData.dataIndex]
                        ? //@ts-ignore
                          params.series.data[params.itemData.dataIndex]?.title
                        : ''}
                    </TooltipContainer>
                  );
                },
              },
            }}
            sx={scatterChartStyle(theme)}
            bottomAxis={{
              disableLine: true,
              disableTicks: true,
            }}
            leftAxis={{
              disableLine: true,
              disableTicks: true,
            }}
            xAxis={[{ min: 0, tickMaxStep: 10, max: 100 }]}
            yAxis={[
              {
                min: 0,
                tickInterval: YAxis,
                max: percentile90,
                valueFormatter: (value: number) => {
                  let indexlabel: number =
                    YAxis.findIndex((step) => step === value) || 0;
                  return YLabelAxis[indexlabel] || '';
                },
              },
            ]}
            zAxis={[
              {
                colorMap: {
                  type: 'piecewise',
                  thresholds: [0.9, 1.9, 2.9, 3.9],
                  colors: [
                    riskGradeColors.A,
                    riskGradeColors.B,
                    riskGradeColors.C,
                    riskGradeColors.D,
                    riskGradeColors.E,
                  ],
                },
              },
            ]}
            grid={{ horizontal: true }}
            slots={{ axisLine: undefined }}
          />

          <BackgroundImageMatrix src="background-iso-risk-matrix.svg" />
        </GraphContainer>
        <TitleBottomtAxis variant="h3" color={theme.palette.text.primary}>
          {titleBottomAxis}
        </TitleBottomtAxis>
      </GraphAndBottomTitleContainer>
    </SectionContainer>
  );
};

export default IsoRiskMatrix;
