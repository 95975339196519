import React from 'react';
import { usePagination } from '@mui/lab';
import { PaginationArrowProps } from './PaginationArrow.types';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { ArrowIconButton } from './PaginationArrow.styles';

const PaginationArrow: React.FC<PaginationArrowProps> = ({
  count,
  page,
  onPageChange,
  children,
}) => {
  const onChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };
  const { items } = usePagination({
    page,
    count: count,
    onChange,
    boundaryCount: 0,
    siblingCount: 0,
  });

  return (
    <>
      {items.map(({ type, disabled, onClick }) => {
        if (type === 'previous' || type === 'next')
          return (
            <>
              <ArrowIconButton
                key={`pagination-${type}`}
                id={`pagination-${type}`}
                aria-label={type}
                disabled={disabled}
                color="primary"
                size="large"
                onClick={onClick}
                type={type}
              >
                {type === 'previous' ? (
                  <ArrowBackIosNew
                    id={`pagination-arrow-${type}`}
                    fontSize="inherit"
                  />
                ) : (
                  <ArrowForwardIos
                    id={`pagination-arrow-${type}`}
                    fontSize="inherit"
                  />
                )}
              </ArrowIconButton>
              {type === 'previous' && children}
            </>
          );
      })}
    </>
  );
};

export default PaginationArrow;
