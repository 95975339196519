import React from 'react';
import { EventCardDashboardProps } from './EventCardDashboard.types';
import {
  CardContainer,
  CardContent,
  CountryContainer,
  ItemCategory,
  TextContainer,
} from './EventCardDashboard.styles';
import { getColorsLiveEvent } from '@utils/colors';
import { Button, CardActions, Divider, useTheme } from '@mui/material';
import { LiveEventIcon } from '@components/atoms/LiveEventIcon';
import { getMainEventDate } from '@utils/date';
import { ArrowRightAlt } from '@mui/icons-material';
import { formatCountries } from '@utils/strings';
import { ItemCard } from '@components/atoms/ItemCard';
import { currencyFormat } from '@utils/numbers';
import { IDEmptyEvent } from '@components/storybook/mock';

const EventCardDashboard: React.FC<EventCardDashboardProps> = ({
  href,
  event,
  action,
  locationsTitle,
  totalTitle,
  currency,
}) => {
  const theme = useTheme();

  const isEventWithAffectedSites: boolean =
    event.affectedSites && event.affectedSites?.sites.length > 0 ? true : false;
  const eventColor =
    event.id !== IDEmptyEvent
      ? getColorsLiveEvent(theme, event, isEventWithAffectedSites)
      : theme.palette.noEvent.main;
  const color = isEventWithAffectedSites
    ? theme.palette.text.primary
    : theme.palette.background.paper;

  return (
    <CardContainer id="live-event-dashboard-card">
      <CardContent color={eventColor} elevation={2}>
        <LiveEventIcon
          name={event.id !== IDEmptyEvent ? event.category : 'no-event'}
          color={eventColor}
          iconColor={color}
        />
        <TextContainer>
          <ItemCategory variant="h5" $eventColor={eventColor} $color={color}>
            {isEventWithAffectedSites && <img src="/warning.svg" />}
            {event.perilName.toUpperCase()}
          </ItemCategory>
          <CountryContainer variant="paragraphMediumSemi">
            {event.countries.length > 0
              ? formatCountries(event.countries)
              : event.title}
          </CountryContainer>
          <ItemCard
            title={getMainEventDate(event)?.label || 'Date started'}
            value={getMainEventDate(event)?.value || ''}
          />
          {(isEventWithAffectedSites || event.id === IDEmptyEvent) && (
            <>
              <ItemCard
                title={locationsTitle}
                value={event.affectedSites?.sites.length || ''}
              />
              <ItemCard
                title={totalTitle}
                value={
                  event.affectedSites
                    ? currencyFormat(event.affectedSites?.sumOfTiv, currency)
                    : ''
                }
              />
            </>
          )}
        </TextContainer>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          id="live-event-dashboard-open-detail"
          size="small"
          href={href}
          endIcon={<ArrowRightAlt />}
          disabled={event.id === IDEmptyEvent}
        >
          {action}
        </Button>
      </CardActions>
    </CardContainer>
  );
};

export default EventCardDashboard;
