import { SummaryButton } from '@components/molecules';
import { GridAutoColumns } from '@components/templates';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiskSuggestionsProps } from './RiskSuggestions.types';

const RiskSuggestions: React.FC<RiskSuggestionsProps> = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={3} {...props}>
      <Typography color="text.secondary" variant="d6">
        {t('support.suggestion.title')}
      </Typography>
      <GridAutoColumns minWidth={280}>
        <SummaryButton
          id="risk-question-side-link-claims"
          src="/briefcase.svg"
          target="_blank"
          href={t('support.suggestion.resources.0.link')}
          title={t('support.suggestion.resources.0.title')}
          description={t('support.suggestion.resources.0.description')}
        />
        <SummaryButton
          id="risk-question-side-link-playbook"
          src="/guides.svg"
          target="_blank"
          href={t('support.suggestion.resources.1.link')}
          title={t('support.suggestion.resources.1.title')}
          description={t('support.suggestion.resources.1.description')}
        />
        <SummaryButton
          id="risk-question-side-link-services"
          src="/tools.svg"
          href={t('support.suggestion.resources.2.link')}
          title={t('support.suggestion.resources.2.title')}
          description={t('support.suggestion.resources.2.description')}
        />
        <SummaryButton
          id="risk-question-side-link-ecosystem"
          src="/ecosystem.svg"
          target="_blank"
          href={t('support.suggestion.resources.3.link')}
          title={t('support.suggestion.resources.3.title')}
          description={t('support.suggestion.resources.3.description')}
        />
      </GridAutoColumns>
    </Stack>
  );
};

export default RiskSuggestions;
