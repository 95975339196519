import React from 'react';

import {
  GeophysicalIcon,
  HydrologicalIcon,
  MeteorologicalIcon,
  WildfireIcon,
} from '@components/atoms/Icons/icons';
import { RiskPotentialProps } from './RiskPotential.types';

export const dataRiskPotentialList: RiskPotentialProps[] = [
  {
    icon: <GeophysicalIcon />,
    title: 'geophysical',
    list: [
      {
        riskIcon: 'earthquakeRiskGrade',
        label: 'Earthquake',
      },
      {
        riskIcon: 'tsunamiRiskGrade',
        label: 'Tsunami',
      },
      {
        riskIcon: 'volcanoRiskGrade',
        label: 'Volcano ',
      },
    ],
  },
  {
    icon: <HydrologicalIcon />,
    title: 'hydrological',
    list: [
      {
        riskIcon: 'riverineFloodRiskGrade',
        label: 'Riverine',
      },
      {
        riskIcon: 'coastalFloodRiskGrade',
        label: 'Coastal flood',
      },
      {
        riskIcon: 'surfaceWaterFloodRiskGrade',
        label: 'Surface water flood',
      },
    ],
  },
  {
    icon: <MeteorologicalIcon />,
    title: 'meteorological',
    list: [
      {
        riskIcon: 'lightningRiskGrade',
        label: 'Lightning',
      },
      {
        riskIcon: 'tropicalCycloneRiskGrade',
        label: 'Tropical cyclone',
      },
      {
        riskIcon: 'extraTropicalCycloneRiskGrade',
        label: 'Extratropical cyclone',
      },
      {
        riskIcon: 'tornadoRiskGrade',
        label: 'Tornado',
      },
      {
        riskIcon: 'hailstormRiskGrade',
        label: 'Hailstorm',
      },
    ],
  },
  {
    icon: <WildfireIcon />,
    title: 'wildfire',
    list: [
      {
        riskIcon: 'wildfiresRiskGrade',
        label: 'Wildfire',
      },
    ],
  },
];
