import RecommendationCardSkeleton from '@components/molecules/resources/RecommendationCard/RecommendationCard.loading';
import { TopWhiteSection, TitleIcon } from '@components/molecules';
import { useGridColumn } from '@utils/dimensions';
import {
  GradientSection,
  Grid1frColumns,
  PageCatalog,
} from '@components/templates';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';

const RecommendationsLoader: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const columns = useGridColumn({ mobile: 1, tablet: 2, desktop: 2 });
  return (
    <>
      <GradientSection
        color={'linear-gradient(102.86deg, #015A66 0.05%, #41949F 101.01%);'}
      >
        <TopWhiteSection backText={t('navigation.dashboard')}>
          {t('recommendations.headline')}
        </TopWhiteSection>
      </GradientSection>
      <PageCatalog>
        <Box>
          <TitleIcon
            id="recommendations-title"
            src="/calendar.svg"
            alt="calendar"
          >
            {t('recommendations.title')}
          </TitleIcon>
          <Typography id="recommendations-subtitle" variant="paragraph">
            {t('recommendations.description')}
          </Typography>
        </Box>

        <Grid1frColumns columns={columns}>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <RecommendationCardSkeleton key={item} />
          ))}
        </Grid1frColumns>
      </PageCatalog>
    </>
  );
};

export default RecommendationsLoader;
