import React from 'react';
import { Stack, Typography, Button, Skeleton } from '@mui/material';
import {
  ContactButtonContainer,
  ContactPaper,
  Description,
  SectionIcon,
} from './RiskCoach.styles';
import ContactSkeleton from '@components/molecules/contact/ContactInfos/Contact.loading';

const RiskCoachSkeleton: React.FC<Record<string, never>> = () => {
  return (
    <ContactPaper id="risk-coach-card">
      <Stack>
        <Stack alignItems="center" direction="row">
          <Skeleton>
            <SectionIcon src="/weechat.svg" alt="chat" />
          </Skeleton>
          <Typography variant="h1">
            <Skeleton width={200} />
          </Typography>
        </Stack>
        <Description variant="paragraphMedium">
          <Skeleton />
        </Description>
        <ContactButtonContainer>
          <ContactSkeleton />
          <Skeleton>
            <Button
              variant="contained"
              sx={{
                flex: 1,
              }}
            >
              Ask a question
            </Button>
          </Skeleton>
        </ContactButtonContainer>
      </Stack>
    </ContactPaper>
  );
};

export default RiskCoachSkeleton;
