import { FlexBox } from '@components/templates';
import { Box, Button, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const SectionContainer = styled(FlexBox)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: theme.shadows[1],
  flexWrap: 'wrap',
}));

export const RightSectionContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    flex: '1 1 150px',
  },
  paddingRight: theme.spacing(0.75),
}));

export const MapImage = styled('img')`
  width: 420px;
  height: 158px;
  margin-right: 16px;
`;

export const TitleContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1.75, 0, 1, 0),
}));

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
}));

export const BorderSection = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${grey[400]}`,
  margin: theme.spacing(0, 0, 2, 0),
}));

export const ButtonRedirectionMap = styled(Button)(({ theme }) => ({
  width: 182,
  [theme.breakpoints.only('mobile')]: {
    width: 216,
  },
}));
