import React from 'react';
import { Chip } from '@mui/material';
import { MapHintFilterContainer } from './MapFilterHintControl.styles';
import { MapFilterHintControlProps } from './MapFilterHintControl.types';
import { RiskIcon } from '@components/atoms/RiskIcon';

const MapFilterHintControl: React.FC<MapFilterHintControlProps> = ({
  filter,
  onOpen,
  nameExtractor,
}) => {
  return (
    <MapHintFilterContainer>
      {filter.parentField && (
        <Chip
          label={nameExtractor(filter.parentField)}
          icon={<RiskIcon name={filter.parentField} />}
          size="small"
          onClick={onOpen}
        />
      )}
      <Chip
        label={nameExtractor(filter.field, !!filter.parentField)}
        icon={<RiskIcon name={filter.field} />}
        size="small"
        onClick={onOpen}
      />
    </MapHintFilterContainer>
  );
};

export default MapFilterHintControl;
