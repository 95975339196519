import { Box, Stack, styled, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

/* Stack layouts */
const defaultPagePaddingStyle = ({
  theme,
  paddingBottom = 0,
}: {
  theme: Theme;
  paddingBottom?: number;
}) => ({
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(2.5, 2.5, paddingBottom),
  },
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(4, 4, paddingBottom),
  },
});

export const PageSection = styled(Stack)<{
  color: 'primary';
}>(({ color = 'default', theme }) => ({
  backgroundColor:
    theme.palette.background[color] || theme.palette[color]?.main,
  ...defaultPagePaddingStyle({ theme, paddingBottom: 1.8 }),
}));

PageSection.defaultProps = { spacing: 4 };

export const GradientSection = styled(PageSection)(({ color, theme }) => ({
  background: color
    ? theme.palette[color]?.gradient || color
    : theme.palette.primary.gradient,
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(0, 2.5, 2),
  },
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(0, 4, 4),
  },
}));

/** Grid layouts */

export const ErrorContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flex: '1 1 auto',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2.5),
  [theme.breakpoints.only('tablet')]: {
    padding: theme.spacing(5),
  },
  [theme.breakpoints.up('desktop')]: {
    padding: theme.spacing(10, 27),
    justifyContent: 'flex-start',
  },
}));

export const GridAutoColumns = styled(Box)(({ theme, minWidth = 200 }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  columnGap: theme.spacing(2),
  rowGap: theme.spacing(3),
  [theme.breakpoints.up('tablet')]: {
    gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}px, 1fr));`,
  },
}));

export const Grid1frColumns = styled(Box)(({ theme, columns }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gap: theme.spacing(3),
  overflow: 'visible',
}));
/* Box layouts */

export const FlexBox = styled(Box)`
  display: flex;
`;

export const FlexColumnBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

// Use negative margin for overflow horizontal scrollview
export const UnmarginBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    margin: `0 -${theme.spacing(2)}`,
  },
  [theme.breakpoints.up('tablet')]: {
    margin: `0 -${theme.spacing(4)}`,
  },
}));

export const ColoredBox = styled(Box)<{
  color: 'primary' | 'paper' | 'default';
}>(({ color = 'default', theme }) => ({
  backgroundColor: theme.palette.background[color] || theme.palette[color].main,
}));

export const BoxSection = styled(ColoredBox)(defaultPagePaddingStyle);

export const XSStretchBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    alignSelf: 'stretch',
  },
}));

export const ResponsiveDirectionBox = styled(Box)(({ theme, breakpoint }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up(breakpoint || 'tablet')]: {
    flexDirection: 'row',
  },
}));

export const ResponsiveSeparator = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    height: 40,
    marginTop: 40,
    borderTop: `2px solid ${grey[300]}`,
  },
  [theme.breakpoints.up('tablet')]: {
    width: 40,
    marginTop: 116,
    borderRight: `2px solid ${grey[300]}`,
  },
}));

export const MobileMargin = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    margin: theme.spacing(0, 2),
  },
  [theme.breakpoints.only('tablet')]: {
    margin: theme.spacing(0, 5),
  },
}));

export const LiveScreenContainer = styled(FlexColumnBox)(() => ({
  height: '100%',
}));

export const MapContainer = styled(FlexColumnBox)(() => ({
  flex: '1 1 auto',
}));
