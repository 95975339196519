import React from 'react';
import { Typography, Skeleton } from '@mui/material';
import { RadioLabel, RadioGridGroup } from './RadioGrid.styles';

const RadioGridLoading = () => {
  return (
    <RadioGridGroup mode="column">
      {[1, 2, 3].map((item) => {
        return (
          <RadioLabel
            id={`option-loader-radio-label-${item}`}
            key={item}
            control={<img src="/radioloading.svg" />}
            label={
              <Typography variant="h3" color="#015A66" sx={{ ml: 1 }}>
                <Skeleton width={237} />
                <Skeleton width={237} />
              </Typography>
            }
          />
        );
      })}
    </RadioGridGroup>
  );
};

export default RadioGridLoading;
