import React from 'react';
import { TitleSection, ValueViewContainer } from './ValueViewSection.styles';
import PortfolioRiskGradingSkeleton from '../PortfolioRiskGrading/PortfolioRiskGrading.loading';
import { Box, Skeleton } from '@mui/material';

const ValueViewSectionSkeleton: React.FC<{ isDesktop: boolean }> = ({
  isDesktop,
}) => {
  return (
    <Box>
      <Skeleton>
        <TitleSection>Title loading</TitleSection>
      </Skeleton>
      <ValueViewContainer>
        {isDesktop && <Skeleton width={760} height={510} />}
        <PortfolioRiskGradingSkeleton />
      </ValueViewContainer>
    </Box>
  );
};

export default ValueViewSectionSkeleton;
