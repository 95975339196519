import { ResponsiveDirectionBox } from '@components/templates';
import { LoadingButton } from '@mui/lab';
import { styled, TextField } from '@mui/material';

export const ActionContainer = styled(ResponsiveDirectionBox)(({ theme }) => ({
  gap: theme.spacing(1, 2),
}));

export const RiskSendButton = styled(LoadingButton)(({ theme }) => ({
  [theme.breakpoints.only('desktop')]: {
    width: 109,
  },
  [theme.breakpoints.not('desktop')]: {
    flex: 1,
  },
}));

export const TextArea = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(1),
}));

TextArea.defaultProps = {
  multiline: true,
  rows: 5,
};
