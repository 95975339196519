import React from 'react';
import { GradientSection } from '@components/templates/layouts/containers';
import { PageContent } from '@components/templates/layouts/pages';
import { useTranslation } from 'react-i18next';
import { TopSection } from '@components/molecules';
import PortfolioRiskValueSkeleton from '@components/organisms/portfolio/PortfolioRiskValue/PortfolioRiskValue.loading';
import { useCompany } from '@modules/services/services-company';
import { Box, Theme, useMediaQuery } from '@mui/material';
import ValueViewSectionSkeleton from '@components/organisms/portfolio/ValueViewSection/ValueViewSection.loading';
import PortfolioSiteListSkeleton from '@components/organisms/portfolio/PortfolioSiteList/PortfolioSiteList.loading';
import { SectionsContainer } from './Portfolio.styles';

const PortfolioSkeleton: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();
  const company = useCompany()?.data?.description;
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('desktop')
  );
  return (
    <>
      <GradientSection color="ocean">
        <TopSection
          back
          backText={t('navigation.dashboard')}
          headline={t('portfolio.title')}
          title={company || ''}
        />
      </GradientSection>
      <>
        <PageContent>
          <SectionsContainer>
            <Box>
              <PortfolioRiskValueSkeleton />
              <ValueViewSectionSkeleton isDesktop={isDesktop} />
            </Box>
            <PortfolioSiteListSkeleton />
          </SectionsContainer>
        </PageContent>
      </>
    </>
  );
};

export default PortfolioSkeleton;
