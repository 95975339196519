import { ColorRiskGrade } from '@modules/types';

export const infoRiskList = [
  {
    label: 'Negligible  (risk grade A)',
    labelColor: ColorRiskGrade.A,
    value: 'PGA = 0.1g or less',
  },
  {
    label: 'Low (risk grade B)',
    labelColor: ColorRiskGrade.B,
    value: 'PGA = 0.01g-0.13g',
  },
  {
    label: 'Moderate (risk grade C)',
    labelColor: ColorRiskGrade.C,
    value: 'PGA = 0.13g-0.35g',
  },
  {
    label: 'High (risk grade D)',
    labelColor: ColorRiskGrade.D,
    value: 'PGA = 0.35g-0.90g',
  },
  {
    label: 'Extreme (risk grade E)',
    labelColor: ColorRiskGrade.E,
    value: 'PGA = 0.90g-1.50g and above',
  },
];
