import { ResponsiveDirectionBox } from '@components/templates';
// import { Skeleton } from '@mui/material';
import React from 'react';
import PortfolioRiskListSkeleton from '../PortfolioRiskList/PortfolioRiskList.loading';
import PortfolioValueChartSkeleton from '../PortfolioValueChart/PortfolioValueChart.loading';
import { PortfolioResponsiveSeparator } from './PortfolioRiskValue.styles';

const PortfolioRiskValueSkeleton: React.FC<{
  displayFeedbackLinkSkeleton?: boolean;
}> = ({ displayFeedbackLinkSkeleton = false }) => {
  return (
    <ResponsiveDirectionBox>
      <PortfolioRiskListSkeleton />
      <>
        <PortfolioResponsiveSeparator />
        <PortfolioValueChartSkeleton
          displayFeedbackLinkSkeleton={displayFeedbackLinkSkeleton}
        />
      </>
    </ResponsiveDirectionBox>
  );
};

export default PortfolioRiskValueSkeleton;
