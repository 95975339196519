import { FlexColumnBox } from '@components/templates';
import { Card, Paper, Typography, styled } from '@mui/material';

export const CardContainer = styled(Card)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const CardContent = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(3, 2, 2, 3.5),
  flex: '1 1 auto',
}));

export const TextContainer = styled(FlexColumnBox)(({ theme }) => ({
  flex: 1,
  alignItems: 'flex-start',
  margin: theme.spacing(0, 0, 0, 2.5),
  minHeight: '115px',
  gap: 4,
}));

// Todo: use Chip component
export const ItemCategory = styled(Typography)<{
  $eventColor: string;
  $color: string;
}>(({ $eventColor, $color, theme }) => ({
  display: 'flex',
  padding: theme.spacing(0.5, 1, 0.5, 1),
  borderRadius: 15,
  backgroundColor: $eventColor,
  color: $color,
  gap: 8,
}));

export const CountryContainer = styled(Typography)(() => ({
  paddingBottom: 0.25,
}));
