import { FlexBox } from '@components/templates';
import { Box, Typography, styled, Theme } from '@mui/material';
import { axisClasses, chartsGridClasses } from '@mui/x-charts';

export const SectionContainer = styled(FlexBox)(() => ({}));

export const GraphAndBottomTitleContainer = styled(Box)(() => ({
  alignItems: 'center',
}));

export const GraphContainer = styled(Box)(({ theme }) => ({
  height: 480,
  width: 760,
  position: 'relative',
  margin: theme.spacing(-5, -3.75, -1.25, 0),
}));

export const TitleLeftAxis = styled(Typography)(({ theme }) => ({
  writingMode: 'vertical-rl',
  textOrientation: 'mixed',
  transform: 'rotate(-180deg)',
  textAlign: 'center',
  marginRight: theme.spacing(2.5),
  textTransform: 'uppercase',
}));

export const TitleBottomtAxis = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(1.25),
  textTransform: 'uppercase',
}));

export const BackgroundImageMatrix = styled('img')(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(-53.75, 0, 8.5, 5.9),
}));

export const scatterChartStyle = (theme: Theme) => ({
  zIndex: 2,
  [`& .${chartsGridClasses.line}`]: {
    strokeDasharray: '10 10',
    stroke: theme.palette.chartLine.main,
  },
  [`.MuiChartsAxis-root-erCVuI.MuiChartsAxis-directionX.MuiChartsAxis-bottom`]:
    {
      transform: 'translate(0px, 445px)',
    },
  [`.MuiChartsAxis-root-erCVuI.MuiChartsAxis-directionY.MuiChartsAxis-left`]: {
    transform: 'translate(35px, 0px)',
  },
  ['& .MuiChartsAxis-directionX']: { marginTop: '200px' },
  [`.${axisClasses.root}`]: {
    [`.${axisClasses.bottom}`]: {
      strokeWidth: 3,
    },
    [`.${axisClasses.tickLabel}`]: {
      ...theme.typography.paragraphMedium,
      fill: theme.palette.chartAxis.main,
    },
  },
});

export const TooltipContainer = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[1],
}));
