import React from 'react';
import {
  BarContainer,
  BarWrapper,
  Filler,
  FillerBackground,
} from './RiskProgress.styles';
import { RiskProgressProps } from './RiskProgress.types';

const RiskProgress: React.FC<RiskProgressProps> = ({
  value,
  color,
  ...props
}) => {
  return (
    <BarWrapper
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
      {...props}
    >
      <BarContainer>
        <Filler value={value}>
          <FillerBackground color={color} value={value} />
        </Filler>
      </BarContainer>
    </BarWrapper>
  );
};

export default RiskProgress;
