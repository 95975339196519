import React from 'react';
import { ButtonSitesProps } from './ButtonSites.types';
import { Button } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

const ButtonSites: React.FC<ButtonSitesProps> = ({ title, number }) => (
  <Button
    id="portfolio-overview-button-shortcut"
    variant="text"
    size="xs"
    href={'/portfolio'}
    endIcon={<ArrowForward />}
  >
    {title}
    {': '}
    {number}
  </Button>
);
export default ButtonSites;
