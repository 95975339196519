import React from 'react';
import {
  ExposureComponentIcon,
  RiskHazardComponentIcon,
  VulnerabilityComponentIcon,
} from '@components/atoms/Icons/icons';

export const dataRiskList = [
  {
    icon: <RiskHazardComponentIcon />,
    title: 'Hazard',
    description:
      'The likelihood of an event having negative consequences for people or property such as flood or wildfire, and its potential causes e.g. weather patterns, human activities etc.',
  },
  {
    icon: <ExposureComponentIcon />,
    title: 'Exposure',
    description:
      'The potential consequences to people, property and the environment e.g. loss of life, damage to property, business interruption etc.',
  },
  {
    icon: <VulnerabilityComponentIcon />,
    title: 'Vulnerability',
    description:
      'The propensity of a company to suffer adverse effects from the impact of a natural hazard event e.g. quality of building construction, level of preparedness etc.',
  },
];
