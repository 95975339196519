import React from 'react';
import { CardActions, Button, Typography, Divider } from '@mui/material';
import {
  CardContainer,
  CardContentFlex,
  CardIcon,
  ContentContainer,
  TextContainer,
} from './RecommendationCard.styles';
import { RecommendationCardProps } from './RecommendationCard.types';
import { getRecommendationImage } from '@pages/Recommendation/Recommendation';

export default function RecommendationCard({
  id,
  headline,
  type,
  title,
  enabled,
  description,
  href,
  action,
  actionIcon,
}: RecommendationCardProps) {
  return (
    <CardContainer id="recommendation-card" key={`${id}-${enabled}`}>
      <CardContentFlex>
        <Typography
          id="recommendation-card-category"
          mb={2}
          variant="h3"
          color="#015A66"
        >
          {headline.toUpperCase()}
        </Typography>
        <ContentContainer>
          <CardIcon src={getRecommendationImage(type)} alt={title} />
          <TextContainer>
            <Typography
              variant="h3"
              id="recommendation-card-title"
              sx={{ wordBreak: 'break-word' }}
            >
              {title}
            </Typography>
            <Typography
              id="recommendation-card-subtitle"
              variant="paragraphSmall"
            >
              {description}
            </Typography>
          </TextContainer>
        </ContentContainer>
      </CardContentFlex>
      <Divider />
      <CardActions>
        <Button
          id="recommendation-button-start"
          size="small"
          href={href}
          endIcon={actionIcon}
        >
          {action}
        </Button>
      </CardActions>
    </CardContainer>
  );
}
