import React from 'react';
import { ScopedCssBaseline, ThemeProvider } from '@mui/material';
import { axaDarkTheme } from './axa.theme';

const ScopedDark: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <ThemeProvider theme={axaDarkTheme}>
    <ScopedCssBaseline>{children}</ScopedCssBaseline>
  </ThemeProvider>
);

export default ScopedDark;
