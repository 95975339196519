import {
  EventDetail,
  EventList,
  EventStatus,
} from '@modules/types/events.types';
import { CombinedQueryResults, combineQueries } from '@utils/functions';
import { AxiosError } from 'axios';
import { useQuery, UseQueryResult, useQueries } from '@tanstack/react-query';
import { apiClient } from '.';
import { UUID } from '@modules/types';

const transformLegacyEventList = (events: EventList) => ({
  items: events.items.map((event) => ({
    ...event,
    // @ts-ignore
    status: event.status === 'open' ? 'active' : event.status,
  })),
});

const eventListCall =
  (status: EventStatus, sort_by: 'event_date' | 'publication_date') =>
  async () =>
    transformLegacyEventList(
      (
        await apiClient.get<EventList>('/events', {
          params: {
            status,
            sort_by,
            sort_desc: true,
          },
        })
      )?.data
    );

const eventById = (id: string) => async () => {
  const result = await apiClient.get<EventDetail>(`/events/${id}`);
  return result.data as EventDetail;
};

const OpenEventsQuery = {
  queryKey: ['events', 'active'],
  queryFn: eventListCall('active', 'event_date'),
  useErrorBoundary: false,
};

const ClosedEventsCall = {
  queryKey: ['events', 'closed'],
  queryFn: eventListCall('closed', 'publication_date'),
  useErrorBoundary: false,
};

export const useOpenEvents = (
  disabled?: boolean
): UseQueryResult<EventList, AxiosError> => {
  return useQuery({ ...OpenEventsQuery, enabled: !disabled });
};

export const useAllEvents = (): CombinedQueryResults<EventList> => {
  const results = useQueries({
    queries: [OpenEventsQuery, ClosedEventsCall],
  });
  return combineQueries(results);
};

export const useDisplayedFootprints = (
  ids: UUID[]
): UseQueryResult<EventDetail>[] => {
  return useQueries({
    queries:
      ids && ids.length > 0
        ? ids.map((id: string) => {
            return {
              queryKey: ['event', id],
              queryFn: eventById(id),
              enabled: true,
            };
          })
        : [],
  });
};
