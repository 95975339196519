import { FlexBox } from '@components/templates';
import { ArrowForward } from '@mui/icons-material';
import { Box, Typography, styled } from '@mui/material';

export const InfoContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.not('mobile')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
export const LabelContainer = styled(Typography)(() => ({
  minWidth: 208,
}));

export const ValueContainer = styled(Typography)(() => ({}));

export const ArrowContainer = styled(ArrowForward)(({ theme }) => ({
  [theme.breakpoints.not('mobile')]: {
    margin: theme.spacing(0, 2, 0, 5),
  },
  margin: theme.spacing(0, 2, 0, 0),
  fontSize: 24,
  color: theme.palette.primary.main,
}));

export const ArrowAndLabelContainer = styled(FlexBox)(() => ({
  alignItems: 'center',
}));
