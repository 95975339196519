import {
  Recommendation,
  RecommendationItems,
  RecommendationSummary,
  UUID,
} from '@modules/types';
import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';
import { apiClient, apiGetId, queryClient } from '.';
import { AxiosError } from 'axios';

export const useRecommendations = (): UseQueryResult<
  RecommendationItems,
  AxiosError
> => {
  const apiCall = async () => {
    const response = await apiClient.get<RecommendationItems>(
      '/recommendations'
    );
    return {
      items: [CustomReportItem].concat(response.data.items),
    };
  };

  return useQuery(['/recommendations'], apiCall, {
    useErrorBoundary: false,
    retry: 1,
  });
};

export const useRecommendation = apiGetId<Recommendation>('/recommendations');

export const useDisableRecommendation = () =>
  useMutation({
    mutationFn: (id: UUID) => apiClient.post(`/recommendations/${id}/disable`),
    onMutate: async (id: UUID) => {
      await queryClient.cancelQueries({ queryKey: ['/recommendations'] });

      const optimisticRecommendations = (old?: RecommendationItems) => {
        return {
          items:
            old?.items?.map((recommendation) =>
              recommendation.id === id
                ? { ...recommendation, enabled: false }
                : recommendation
            ) || [],
        } as RecommendationItems;
      };

      queryClient.setQueryData(['/recommendations'], optimisticRecommendations);
    },
  });

export const IDCustomReportItem = 'custom_report_recommendation';

export const CustomReportItem: RecommendationSummary = {
  id: IDCustomReportItem,
  description:
    'Learn more about your Natural Hazard exposure and Climate change impact on your portfolio.',
  enabled: true,
  headline: 'Your custom report',
  title: 'Your Natural Hazard exposure and Climate change impact',
  type: 'report',
};

export const CustomReportItemDetail = {
  id: self.crypto.randomUUID(),
  title: 'Your custom report',
  enabled: true,
  items: [
    {
      id: self.crypto.randomUUID(),
      title: 'Natural Hazard and Climate Change Report',
      description:
        'Learn more about your Natural Hazard exposure and Climate change impact on your portfolio.',
      type: 'report',
      responses: [
        {
          id: self.crypto.randomUUID(),
          description: 'Read the report',
          action: {
            type: 'PDF_LINK',
            data: {
              url: '',
            },
          },
        },
      ],
    },
  ],
};
