import React from 'react';
import { Typography, Stack } from '@mui/material';
import { SitesRiskIndicatorProps } from './SitesRiskIndicator.types';
import { XSStretchBox } from '@components/templates';
import { InfoTooltip } from '@components/atoms/InfoTooltip';
import {
  RiskLinearProgress,
  TitleItemContainer,
} from './SitesRiskIndicator.styles';

export default function SitesRiskIndicator({
  totalCount,
  count,
  titleRisk,
  titleTooltip,
  descriptionTooltip,
  ...props
}: SitesRiskIndicatorProps) {
  const percentRisk = (count * 100) / totalCount;
  return (
    <XSStretchBox {...props}>
      <TitleItemContainer>
        <Typography id="portfolio-overview-risk-title" variant="leadSemi">
          {titleRisk}
        </Typography>
        <InfoTooltip
          id="portfolio-overview-risk-tooltip"
          darkBackground
          title={titleTooltip}
          description={descriptionTooltip}
        />
      </TitleItemContainer>

      <Stack direction="row" spacing={0} alignItems="center">
        <RiskLinearProgress value={percentRisk} variant="determinate" />

        <Typography id="portfolio-sites-risk-count" variant="h2">
          {count}
        </Typography>
        <Typography id="portfolio-sites-count" variant="lead">
          /{totalCount}
        </Typography>
      </Stack>
    </XSStretchBox>
  );
}
