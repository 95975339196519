import { IconButton, List, styled } from '@mui/material';

export const ArrowIconButton = styled(IconButton)(({ theme, type }) => ({
  padding: theme.spacing(0, 3, 0, type === 'previous' ? 3 : 1.5),
  fontSize: 56,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
  span: {
    display: 'none',
  },
}));

export const ArrowList = styled(List)(({ theme }) => ({
  gap: theme.spacing(2),
  ['& > *']: {
    marginLeft: theme.spacing(2),
  },
}));
