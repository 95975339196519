import { IconButton, styled, Typography } from '@mui/material';

export const IconButtonContainer = styled(IconButton)((/*{ theme }*/) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 64,
  height: 64,
}));

export const ImageContainer = styled('img')(({ isSelected = false }) => ({
  width: 64,
  height: 64,
  border: isSelected ? '3px solid #00006D' : '2px solid #343c3d',
  '&:hover': {
    border: isSelected ? '3px solid #00006D' : '3px solid #3D3DAA',
  },
}));

export const TitleMapStyleContainer = styled(Typography)`
  position: absolute;
  top: 40px;
  max-width: 64px;
  text-align: center;
`;
