import { currencyFormat, getRiskGrade, getRiskLevel } from '@utils/numbers';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonExpandableContainer,
  InfoRiskContainer,
  SiteContainer,
  SiteSummarySeparator,
} from './IndividualSiteSummary.styles';
import { IndividualSiteSummaryProps } from './IndividualSiteSummary.types';
import { allCategoryRiskGrades } from '@utils/strings';
import { InfoSiteField } from '@components/molecules/portfolio/InfoSiteField';
import { InfoRiskSiteField } from '@components/molecules/portfolio/InfoRiskSiteField';
import { ButtonRedirection } from '@components/atoms/ButtonRedirection';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { ButtonExpandable } from '@components/atoms/ButtonExpandable';

const IndividualSiteSummary: React.FC<IndividualSiteSummaryProps> = ({
  site,
  companyCurrency,
}) => {
  const isLarge = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('desktop')
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(isLarge);
  const categoryRiskGradeSummary = useMemo(
    // @ts-ignore
    () => JSON.parse(site.categoryRiskGradeSummary as string),
    [site]
  );
  const { t } = useTranslation();
  return (
    <SiteContainer id="map-site-summary-popup">
      {site.address && isExpanded && (
        <InfoSiteField
          title={t(`site.fields.address`)}
          text={site.address as unknown as string}
        />
      )}
      {site.resolution && isExpanded && (
        <InfoSiteField
          title={t(`site.fields.geocoding-quality`)}
          text={t(`site.geocoding.quality.${site.geocodeQuality}`)}
        />
      )}
      {site.siteValue && (
        <InfoSiteField
          title={t(`site.fields.siteValue`)}
          text={currencyFormat(site.siteValue, companyCurrency)}
        />
      )}
      <SiteSummarySeparator />
      <Typography align="center" variant="paragraphSemi">
        {t(`portfolio.map.filters.riskGrade`)}
      </Typography>
      <InfoRiskContainer>
        {site.riskGrade && (
          <InfoRiskSiteField
            key={site.riskGrade}
            variant="filled"
            title={t(`portfolio.map.grade`)}
            risk={getRiskGrade(site.riskGrade)}
          />
        )}
        {isExpanded &&
          allCategoryRiskGrades.map(
            (categoryRisk) =>
              categoryRiskGradeSummary[categoryRisk.field] !== null && (
                <InfoRiskSiteField
                  key={categoryRisk.key}
                  title={t(`site.fields.${categoryRisk.key}`)}
                  risk={getRiskLevel(
                    categoryRiskGradeSummary[categoryRisk.field]
                  )}
                />
              )
          )}
      </InfoRiskContainer>
      {!isLarge && isExpanded && <SiteSummarySeparator />}
      {isExpanded && (
        <ButtonRedirection
          title={t('portfolio.map.detail')}
          href={`/portfolio/${site.id}?path=map`}
        />
      )}
      {!isLarge && (
        <ButtonExpandableContainer>
          <ButtonExpandable
            isExpand={isExpanded}
            onClickButton={() => setIsExpanded(!isExpanded)}
          />
        </ButtonExpandableContainer>
      )}
    </SiteContainer>
  );
};

export default IndividualSiteSummary;
