import { styled, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(
  ({ theme, $isExpanded, $maxHeight }) => ({
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    maxHeight: $isExpanded ? 'none' : $maxHeight,
    '&::after': {
      content: $isExpanded ? 'none' : '""',
      textAlign: 'right',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      width: '100%',
      height: '2rem',
      background: `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${theme.palette.background.paper} 100%)`,
    },
  })
);
