import { FlexBox, FlexColumnBox } from '@components/templates';
import { styled } from '@mui/material';

export const ModalGeocodingContainer = styled(FlexColumnBox)(({ theme }) => ({
  gap: theme.spacing(5),
  padding: theme.spacing(2),
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(2, 3),
  },
}));

export const SectionContainer = styled(FlexColumnBox)(({ theme }) => ({
  gap: theme.spacing(2),
}));

export const SquareContainer = styled(FlexColumnBox)(({ theme }) => ({
  boxShadow: theme.shadows[2],
  padding: theme.spacing(2.5),
  gap: theme.spacing(2),
}));

export const ImageSectionContainer = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(5),
  flexWrap: 'wrap',
  justifyContent: 'center',
}));

export const GroupSectionContainer = styled(FlexColumnBox)(({ theme }) => ({
  gap: theme.spacing(5),
  flex: '1 1 450px',
}));

export const GeocodingDesktopImage = styled('img')(() => ({
  width: 514,
  height: 836,
}));

export const GeocodingMobileImage = styled('img')(() => ({
  width: 200,
  height: 326,
}));
