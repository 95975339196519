import { Contact, TitleIconResponsive } from '@components/molecules';
import { Message } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import {
  CoachContainer,
  CoachDescription,
  CoachIntro,
  Container,
  TextContainer,
} from './PlaybooksContact.styles';
import { PlaybooksContactProps } from './PlaybooksContact.types';

const PlaybooksContact: React.FC<PlaybooksContactProps> = ({
  coach,
  title,
  introduction,
  description,
  action,
}) => {
  return (
    <Container id="playbooks-contact-card" breakpoint="desktop">
      <TextContainer>
        <TitleIconResponsive variant="display" src="/weechat.svg" alt="chat">
          {title}
        </TitleIconResponsive>
        <CoachIntro variant="h1">{introduction}</CoachIntro>
        <CoachDescription variant="lead">{description}</CoachDescription>
      </TextContainer>
      <CoachContainer>
        <Contact {...coach} size="large" />
        <Button
          id="playbooks-button-contact"
          aria-label="contact risk coach"
          variant="contained"
          endIcon={<Message />}
          size="large"
          href="/riskcoach"
        >
          {action}
        </Button>
      </CoachContainer>
    </Container>
  );
};

export default PlaybooksContact;
