import { Button, Divider, Stack, styled } from '@mui/material';

export const FooterContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.ocean.main,
  justifyContent: 'flex-end',
  padding: theme.spacing(2, 0),
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(0, 5, 0, 1),
  },
}));

export const FooterDivider = styled(Divider)`
  border-color: #7698cb;
`;

export const FooterButton = styled(Button)(({ theme }) => ({
  color: 'white',
  textTransform: 'none',
  ...theme.typography.paragraphSmall,
  padding: 0,
  textAlign: 'center',
  [theme.breakpoints.only('mobile')]: {
    minHeight: 20,
  },
  [theme.breakpoints.up('desktop')]: {
    ...theme.typography.paragraphMedium,
  },
}));
