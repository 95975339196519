import React from 'react';
import { WelcomeModalProps } from './WelcomeModal.types';
import { DescriptionContainer } from './WelcomeModal.styles';
import { ModalContainer } from '@components/organisms/ModalContainer';

const WelcomeModal: React.FC<WelcomeModalProps> = ({
  title,
  description,
  isOpen,
  closeText,
  onClose,
}) => {
  return (
    <ModalContainer
      title={title}
      isOpen={isOpen}
      closeText={closeText}
      onClose={onClose}
      totalModalWidth={664}
    >
      <DescriptionContainer variant="paragraphMedium">
        {description}
      </DescriptionContainer>
    </ModalContainer>
  );
};

export default WelcomeModal;
