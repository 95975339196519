import React from 'react';
import { MiniMapProps } from './MiniMap.types';

import { useTheme } from '@mui/material';

import MapGL, { Layer, Source } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { PinMap } from '@components/atoms/PinMap';
import { MapFooterAttributes } from '../MapFooterAttributes';

// https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling
// https://github.com/visgl/react-map-gl/issues/1266

/* eslint-disable @typescript-eslint/no-var-requires */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(mapboxgl as any).workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MiniMap: React.FC<MiniMapProps> = ({
  name,
  company,
  footprint,
  zoom = 14,
  style,
  onClick,
  children,
}) => {
  const { lat: latitude, lon: longitude, buildings, parcels } = footprint;

  const buildingsLayer = {
    paint: {
      'fill-outline-color': useTheme().palette.primary.main,
      'fill-color': useTheme().palette.primary.main,
      'fill-opacity': 0.65,
    },
  };

  const parcelsLayer = {
    paint: {
      'fill-outline-color': useTheme().palette.primary.main,
      'fill-color': useTheme().palette.background.default,
      'fill-opacity': 0.65,
    },
    filter: ['==', 'selected', true],
  };

  return (
    <MapGL
      reuseMaps
      // Load JSON styles which includes HERE POIs
      mapStyle={`../../map-style-streets-v11.json`}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      dragRotate={false}
      interactive={true}
      attributionControl={false}
      initialViewState={{
        latitude: latitude,
        longitude: longitude,
        zoom: zoom,
      }}
      minZoom={10}
      onClick={onClick}
      style={{ display: 'flex', ...style }}
    >
      {children}
      <PinMap
        title={company || ''}
        description={name}
        latitude={latitude}
        longitude={longitude}
      />
      {parcels.features.length > 0 && (
        <Source id="parcels-layer" type="geojson" data={parcels}>
          <Layer id="parcels-layer-fill" type="fill" {...parcelsLayer} />
        </Source>
      )}
      {buildings.features.length > 0 && (
        <Source id="building-layer" type="geojson" data={buildings}>
          <Layer id="building-layer-fill" type="fill" {...buildingsLayer} />
        </Source>
      )}
      <MapFooterAttributes />
    </MapGL>
  );
};

export default MiniMap;
