import { Box, ListItemButton, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export {};

export const ListItemButtonContainer = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 3, 2, 2),
}));

export const ListItemSeparator = styled(Box)(() => ({
  width: 278,
  margin: 'auto',
  borderTop: `1px solid ${grey[300]}`,
}));

export const ItemInfoConatiner = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
