import React from 'react';
import { ModalContainerProps } from './ModalContainer.types';
import {
  DialogActions,
  DialogContent,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { CloseModalIcon, WhiteCloseIcon } from '@components/atoms/Icons/icons';
import {
  ButtonClose,
  DialogContainer,
  DialogHeader,
} from './ModalContainer.styles';

const ModalContainer: React.FC<ModalContainerProps> = ({
  title,
  onClose,
  closeText,
  isOpen,
  children,
  totalModalWidth,
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );

  return (
    <DialogContainer onClose={onClose} open={isOpen} width={totalModalWidth}>
      <DialogHeader>
        <Typography variant="h2">{title}</Typography>
        <IconButton aria-label="close" onClick={onClose} size="small">
          <CloseModalIcon />
        </IconButton>
      </DialogHeader>
      <DialogContent dividers>{children}</DialogContent>
      {closeText && (
        <DialogActions>
          <ButtonClose
            variant="contained"
            endIcon={<WhiteCloseIcon />}
            size={isSmall ? 'medium' : 'small'}
            onClick={onClose}
          >
            {closeText}
          </ButtonClose>
        </DialogActions>
      )}
    </DialogContainer>
  );
};

export default ModalContainer;
