import React from 'react';
import { Typography, Stack, Skeleton } from '@mui/material';
import { XSStretchBox } from '@components/templates';
import {
  RiskLinearProgress,
  TitleItemContainer,
} from './SitesRiskIndicator.styles';

export default function SitesRiskIndicatorSkeleton() {
  return (
    <XSStretchBox>
      <TitleItemContainer>
        <Typography variant="leadSemi">
          <Skeleton width={150} />
        </Typography>
      </TitleItemContainer>

      <Stack direction="row" spacing={0} alignItems="center">
        <Skeleton>
          <RiskLinearProgress value={0} variant="determinate" />
        </Skeleton>
        <Typography variant="h2">
          <Skeleton width={40} />
        </Typography>
      </Stack>
    </XSStretchBox>
  );
}
