import { Skeleton, Typography } from '@mui/material';
import React from 'react';
import {
  LegendaryColor,
  LegendaryColumn,
  LegendaryItem,
  LegendContainer,
} from './LegendChart.styles';

const ItemSkeleton: React.FC = () => {
  return (
    <LegendaryItem>
      <Skeleton>
        <LegendaryColor />
      </Skeleton>
      <Typography variant="h3">
        <Skeleton />
      </Typography>
      <Typography variant="h4">
        <Skeleton />
      </Typography>
    </LegendaryItem>
  );
};

const ItemRowSkeleton: React.FC<{ itemArray: number[] }> = ({ itemArray }) => {
  return (
    <LegendaryColumn>
      {itemArray.map((item) => (
        <ItemSkeleton key={item} />
      ))}
    </LegendaryColumn>
  );
};

const LegendChartSkeleton: React.FC = () => {
  return (
    <LegendContainer>
      <ItemRowSkeleton itemArray={[1, 2]} />
      <ItemRowSkeleton itemArray={[1]} />
    </LegendContainer>
  );
};

export default LegendChartSkeleton;
