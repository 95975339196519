import { RecommendationAction } from '@modules/types';
import { ArrowRightAlt, Clear } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { RadioGrid } from '../../forms/RadioGrid';
import { RadioOption } from '../../forms/RadioGrid/RadioGrid.types';
import {
  ActionContainer,
  StepIcon,
  TitleAndDescriptionContainer,
} from './RecommendationStep.styles';
import { RecommendationStepProps } from './RecommendationStep.types';

const RecommendationStep: React.FC<RecommendationStepProps> = ({
  title,
  description,
  image,
  responses,
  selectOptionTitle,
  nextButtonTitle,
  unprioritizeButtonTitle,
  onResponseAction,
  alwaysDispay,
}) => {
  const [selectedOption, setOption] =
    useState<RadioOption<RecommendationAction>>();

  const handleNext = useCallback(
    () => onResponseAction(selectedOption?.value),
    [selectedOption, onResponseAction]
  );

  return (
    <Stack>
      <StepIcon src={image} alt={title} />
      <TitleAndDescriptionContainer>
        <Typography variant="h1" sx={{ wordBreak: 'break-word' }}>
          {title}
        </Typography>
        <Typography variant="paragraph" whiteSpace={'pre-wrap'}>
          {description}
        </Typography>
      </TitleAndDescriptionContainer>
      <Typography variant="paragraphMedium">{selectOptionTitle}</Typography>
      <ActionContainer>
        <RadioGrid
          id="recommendation-list-actions"
          mode="column"
          selected={selectedOption}
          onChange={setOption}
          data={responses?.map((response) => ({
            id: response.description,
            value: response.action,
            label: response.description,
          }))}
        />
        <Button
          id="recommendation-button-next"
          variant="contained"
          disabled={selectedOption === undefined}
          endIcon={<ArrowRightAlt />}
          onClick={handleNext}
          sx={{
            flex: 1,
          }}
        >
          {nextButtonTitle}
        </Button>
        <Button
          id="recommendation-button-dismiss"
          size="small"
          startIcon={<Clear />}
          onClick={() =>
            onResponseAction({ type: alwaysDispay ? 'BACK' : 'DISMISS' })
          }
        >
          {unprioritizeButtonTitle}
        </Button>
      </ActionContainer>
    </Stack>
  );
};

export default RecommendationStep;
