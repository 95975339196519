import { Box, Card, CardContent, styled } from '@mui/material';

export const CardContainer = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: space-between;
  height: 100%;
`;

export const CardIcon = styled('img')`
  width: 101px;
  height: 101px;
  margin-left: 8px;
  z-index: 1000;
`;

export const CardContentFlex = styled(CardContent)`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex: 1;
`;

export const TextContainer = styled(Box)`
  ${({ disabled }: { disabled: boolean }) =>
    disabled ? 'filter: brightness(50%);' : ''}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const DisabledOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0bb;
`;
