import { Skeleton } from '@mui/material';
import TitleIconResponsiveSkeleton from '@components/molecules/navigation/TitleIconResponsive/TitleIconResponsive.loading';
import { Button, useMediaQuery, Theme } from '@mui/material';
import React from 'react';
import ContactSkeleton from '@components/molecules/contact/ContactInfos/Contact.loading';
import {
  CoachContainer,
  Container,
  CoachDescription,
  CoachIntro,
  TextContainer,
} from './PlaybooksContact.styles';

const PlaybooksContactSkeleton: React.FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('desktop')
  );

  return (
    <Container id="playbooks-contact-card" breakpoint="desktop">
      <TextContainer>
        <TitleIconResponsiveSkeleton
          variant="display"
          src="/weechat.svg"
          alt="chat"
        >
          <Skeleton width={200} />
        </TitleIconResponsiveSkeleton>
        <CoachIntro variant="h1">
          <Skeleton width={'25%'} />
        </CoachIntro>
        <CoachDescription variant="lead">
          <Skeleton width={'75%'} />
        </CoachDescription>
      </TextContainer>
      <CoachContainer>
        <ContactSkeleton size="large" />
        <Skeleton width={isDesktop ? 130 : '100%'}>
          <Button size="large"></Button>
        </Skeleton>
      </CoachContainer>
    </Container>
  );
};

export default PlaybooksContactSkeleton;
