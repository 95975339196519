import React from 'react';
import { RiskItemProps } from './RiskItem.types';
import { RiskItemContainer, LabelContainer } from './RiskItem.styles';
import { RiskIcon } from '../RiskIcon';

const RiskItem: React.FC<RiskItemProps> = ({ riskIcon, label }) => {
  return (
    <RiskItemContainer>
      <RiskIcon name={riskIcon} />
      <LabelContainer variant="h5">{label}</LabelContainer>
    </RiskItemContainer>
  );
};

export default RiskItem;
