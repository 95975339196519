import React from 'react';
import { Skeleton, Typography, useMediaQuery, Theme } from '@mui/material';
import {
  GradientSection,
  Grid1frColumns,
} from '@components/templates/layouts/containers';
import { useGridColumn } from '@utils/dimensions';
import { PageCatalog, PaperInfo } from '@components/templates';
import PlaybooksContactSkeleton from '@components/organisms/contact/PlaybooksContact/PlaybooksContact.loading';
import PlaybookCardSkeleton from '@components/molecules/resources/PlaybookCard/PlaybookCard.loading';
import { TopWhiteSection } from '@components/molecules';
import { useTranslation } from 'react-i18next';
const PlaybooksSkeleton: React.FC<Record<string, never>> = () => {
  const columns = useGridColumn({ mobile: 1, tablet: 2, desktop: 3 });
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  const { t } = useTranslation();
  return (
    <>
      <GradientSection
        color={'linear-gradient(102.86deg, #015A66 0.05%, #41949F 101.01%);'}
      >
        <TopWhiteSection
          backText={t('navigation.dashboard')}
          description={t('playbooks.description')}
        >
          {t('app.services.playbooks')}
        </TopWhiteSection>
      </GradientSection>
      <PageCatalog>
        <Typography variant="h1">
          <Skeleton width={isSmall ? '100%' : 210} />
        </Typography>
        <Grid1frColumns columns={columns}>
          {[1, 2, 3, 4, 5, 6, 7].map((item) => (
            <PlaybookCardSkeleton key={item} />
          ))}
        </Grid1frColumns>
        <PaperInfo sx={{ marginTop: 6 }}>
          <PlaybooksContactSkeleton />
        </PaperInfo>
      </PageCatalog>
    </>
  );
};

export default PlaybooksSkeleton;
