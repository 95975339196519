import { FlexBox } from '@components/templates';
import { Button, Card, CardContent, CardMedia, styled } from '@mui/material';

export const CardContainer = styled(Card)(() => ({
  flex: 1,
}));

export const ArticleCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(5),
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(2.5),
  },
}));

export const ArticleCardMedia = styled(CardMedia)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  height: 240,
  [theme.breakpoints.only('mobile')]: {
    marginBottom: theme.spacing(2.5),
    height: 188,
  },
}));

export const CardIcon = styled('img')`
  width: 56px;
  height: 56px;
  margin: 4px 12px;
`;

export const ChipBox = styled(FlexBox)(({ theme }) => ({
  gap: theme.spacing(1),
}));

export const TitleBox = styled(FlexBox)(({ theme }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(1),
}));

export const ArticleButton = styled(Button)`
  align-self: flex-start;
`;
