import { Box, Button, styled, Theme } from '@mui/material';

export const BackButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [theme.breakpoints.only('mobile')]: {
    margin: theme.spacing(2, 0, 1),
  },
}));

export const WhiteLine = styled(Box)`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.background.paper};
  width: 32px;
  height: 4px;
  margin-bottom: 16px;
`;
