import React from 'react';
import { InfoTooltipProps } from './InfoTooltip.types';
import {
  SectionIcon,
  SmallTooltip,
  TooltipBox,
  TooltipTitle,
  TooltipContainer,
} from './InfoTooltip.styles';
import { Box, Typography } from '@mui/material';

const InfoTooltip: React.FC<InfoTooltipProps> = ({
  darkBackground,
  id,
  title,
  description,
  children,
}) => {
  return (
    <SmallTooltip
      enterTouchDelay={0}
      title={
        <TooltipBox>
          {title && (
            <TooltipTitle variant="h5">{title?.toUpperCase()}</TooltipTitle>
          )}
          <TooltipContainer>
            <Typography variant="paragraph">{description}</Typography>
            <Box>{children}</Box>
          </TooltipContainer>
        </TooltipBox>
      }
      placement="top-start"
      arrow
    >
      <SectionIcon
        id={id}
        src={darkBackground ? '/whiteinfo.svg' : '/info.svg'}
        alt={'info'}
      />
    </SmallTooltip>
  );
};

export default InfoTooltip;
