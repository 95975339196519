import React, { useState } from 'react';
import { ExpandableItemProps } from './ExpandableItem.types';
import {
  ExpandableBanner,
  ExpandableBorder,
  ExpandableContainer,
  TitleBanner,
} from './ExpandableItem.styles';
import { Button } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const ExpandableItem: React.FC<ExpandableItemProps> = ({
  title,
  isOpen,
  children,
}) => {
  const [isExpand, setIsExpand] = useState(isOpen);
  return (
    <ExpandableBorder>
      <ExpandableBanner
        isOpen={isExpand}
        onClick={() => setIsExpand(!isExpand)}
      >
        <TitleBanner variant="h3" isOpen={isExpand}>
          {title}
        </TitleBanner>
        <Button
          id="expand-typo-button"
          variant="text"
          size="xs"
          onClick={() => setIsExpand(!isExpand)}
          endIcon={isExpand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        />
      </ExpandableBanner>
      {isExpand && <ExpandableContainer>{children}</ExpandableContainer>}
    </ExpandableBorder>
  );
};

export default ExpandableItem;
