import { FlexBox } from '@components/templates';
import { Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const TitleText = styled(Typography)`
  color: #000000;
`;

export const TitleContainer = styled(FlexBox)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 0),
  margin: theme.spacing(0, 2, 2, 2),
  borderBottom: `1px solid ${grey[300]}`,
}));
