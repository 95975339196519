import { styled } from '@mui/material';
import Box from '@mui/material/Box';

export const ItemContainer = styled(Box)(() => ({
  minHeight: 18,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: 2,
}));
