import { Box, styled } from '@mui/material';

export const ItemContainerRiskGrade = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0),
}));

// AddPaddingContainer permit to reach a line height of 1.313 rem
// paragraphMediumSemi have a default line height of 1.25 rem
export const AddPaddingContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1, 0.125, 0),
}));
