import React from 'react';
import {
  CardActions,
  Button,
  Typography,
  Divider,
  Skeleton,
} from '@mui/material';
import {
  CardContainer,
  CardContentFlex,
  CardIcon,
  TextContainer,
} from './PlaybookCard.styles';

export default function PlaybookCardSkeleton() {
  return (
    <CardContainer id="playbook-card" sx={{ width: '100%' }}>
      <CardContentFlex>
        <TextContainer>
          <Typography variant="h3">
            <Skeleton width={100} />
          </Typography>
          <Typography variant="h1">
            <Skeleton width={150} />
          </Typography>
        </TextContainer>
        <Skeleton>
          <CardIcon />
        </Skeleton>
      </CardContentFlex>
      <Divider />
      <CardActions>
        <Skeleton>
          <Button size="small">Read now</Button>
        </Skeleton>
      </CardActions>
    </CardContainer>
  );
}
