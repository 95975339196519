import { Typography, Skeleton } from '@mui/material';
import React from 'react';
import {
  EventContainer,
  LiveEventItemContainer,
  LiveEventLabelContainer,
  ReviewButtonSkeleton,
  TitleEvent,
} from './EventCard.styles';

const EventCardSkeleton: React.FC = () => {
  return (
    <EventContainer elevation={1} color={'transparent'}>
      <Skeleton width={32} height={32} variant={'circular'} />
      <LiveEventItemContainer>
        <LiveEventLabelContainer>
          <Skeleton width={70} />
        </LiveEventLabelContainer>
        <TitleEvent variant="h5">
          <Skeleton width={120} />
        </TitleEvent>
        <Typography variant="paragraphSmall" width={120}>
          <Skeleton />
        </Typography>
        <ReviewButtonSkeleton size="small" />
      </LiveEventItemContainer>
    </EventContainer>
  );
};

export default EventCardSkeleton;
