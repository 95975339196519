import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Grid1frColumns } from '../layouts/containers';
import {
  ExpandActions,
  SideAction,
  MiddleAction,
  ExpandContainer,
} from './ExpandableGrid.styles';
import { ExpandableGridProps } from './ExpandableGrid.types';
import { useGridColumn } from '@utils/dimensions';

const ExpandableGrid = <T,>({
  items = [],
  renderItem,
  leftAction,
  mobile,
  tablet,
  desktop,
  isEmpty,
  isLoading,
  ...props
}: ExpandableGridProps<T>) => {
  const { t } = useTranslation();
  const columns = useGridColumn({ mobile, tablet, desktop });
  const canExpand = items?.length && !isLoading && items?.length > columns;
  const [isExpanded, setExpandable] = useState(false);

  const visibleItems =
    !canExpand || isExpanded ? items : items.slice(0, columns);

  return (
    <ExpandContainer {...props}>
      <Grid1frColumns columns={columns}>
        {visibleItems?.map((item) => renderItem(item))}
      </Grid1frColumns>
      <ExpandActions $isEmpty={isEmpty}>
        <SideAction>{leftAction}</SideAction>
        <MiddleAction>
          {canExpand && (
            <Button
              id="global-button-expand"
              sx={{ position: 'absolute' }}
              onClick={() => setExpandable((value) => !value)}
              size="small"
              endIcon={isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            >
              {t(isExpanded ? 'action.less' : 'action.more')}
            </Button>
          )}
        </MiddleAction>
        <SideAction />
      </ExpandActions>
    </ExpandContainer>
  );
};

export default ExpandableGrid;
