import React from 'react';
import { InfoRiskGradeProps } from './InfoRiskGrade.types';
import {
  ArrowAndLabelContainer,
  ArrowContainer,
  InfoContainer,
  LabelContainer,
  ValueContainer,
} from './InfoRiskGrade.styles';

const InfoRiskGrade: React.FC<InfoRiskGradeProps> = ({
  label,
  labelColor,
  value,
}) => {
  return (
    <InfoContainer>
      <LabelContainer variant="leadSemi" color={labelColor}>
        {label}
      </LabelContainer>
      <ArrowAndLabelContainer>
        <ArrowContainer />
        <ValueContainer variant="leadSemi">{value}</ValueContainer>
      </ArrowAndLabelContainer>
    </InfoContainer>
  );
};

export default InfoRiskGrade;
