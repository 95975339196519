import { SvgIcon, Typography } from '@mui/material';
import React from 'react';
import { Marker } from 'react-map-gl';
import { MapClusterProps } from './MapCluster.types';
import './MapCluster.css';
import { CountBox } from './MapCluster.styles';
const RADIUS = '15.91549430918954'; // 100%/2π

interface ChartBar {
  color: string;
  percent: number;
  offset: number;
}

//https://heyoka.medium.com/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72
const MapCluster: React.FC<MapClusterProps> = ({
  clusterId,
  count,
  data,
  size,
  onClick,
  clusterValue,
  longitude,
  latitude,
}) => {
  const segments = data.reduce<{ sumPercent: number; bars: ChartBar[] }>(
    (segments, current) => {
      const percent: number = (100 * current.value) / clusterValue;
      return {
        sumPercent: percent + segments.sumPercent,
        bars:
          current.value > 0
            ? segments.bars.concat({
                color: current.color,
                percent,
                offset: segments.sumPercent,
              })
            : segments.bars,
      };
    },
    {
      sumPercent: 0,
      bars: [],
    }
  );

  return !!longitude && !!latitude ? (
    <Marker
      longitude={longitude}
      latitude={latitude}
      draggable={false}
      onClick={() => onClick(clusterId, longitude, latitude)}
      style={{
        fontSize: size,
      }}
    >
      <SvgIcon fontSize="inherit" viewBox="0 0 42 42">
        <defs>
          <linearGradient id="clusterhovergradient" x2="0" y2="1">
            <stop offset="0%" stopColor="#fff" />
            <stop offset="80%" stopColor="#ccc" />
          </linearGradient>
        </defs>
        <circle className="donut-hole" cx="21" cy="21" r={RADIUS} fill="#fff" />
        <circle
          className="donut-ring"
          cx="21"
          cy="21"
          r={RADIUS}
          fill="transparent"
          stroke="#00008f"
          strokeWidth="3"
        />

        {segments.bars.map((segment) => (
          <circle
            key={segment.color}
            className="donut-segment"
            cx="21"
            cy="21"
            r={RADIUS}
            fill="transparent"
            stroke={segment.color}
            strokeWidth="4"
            strokeDasharray={`${segment.percent} ${100 - segment.percent}`}
            strokeDashoffset={100 - segment.offset + 25}
          />
        ))}
      </SvgIcon>

      <CountBox>
        <Typography variant="h2">{count}</Typography>
      </CountBox>
    </Marker>
  ) : null;
};

export default MapCluster;
