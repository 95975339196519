import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard, { ScreenLoader } from '@pages/Dashboard/Dashboard';
import Root from '@pages/Root';
import Portfolio from '@pages/Portfolio/Portfolio';
import IndividualSite from '@pages/IndividualSite/IndividualSite';
import RiskContact from '@pages/RiskCoachContact';
import Playbooks from '@pages/Playbooks/Playbooks';
import GlobalError from '@pages/GlobalError';
import Success from '@pages/Success';
import Error from '@pages/Error';
import IndividualSiteMap from '@pages/IndividualSiteMap';
import PortfolioMap from '@pages/PortfolioMap';
import Recommendation from '@pages/Recommendation/Recommendation';
import Recommendations from '@pages/Recommendations/Recommendations';
import LiveEvents from '@pages/LiveEvents/LiveEvents';
import { axaTheme as theme } from './components/themes/axa.theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  getTokenBuilder,
  useAuth,
  useVerifyAuthenticationOnFocus,
} from '@modules/services/services-auth';
import { IS_PRODUCTION, SMART_SERVICES_LOGOUT } from '@utils/urls';
import { useOpenEvents } from '@modules/services/services-events';
import { RouteTabs, ROUTE_DEFS } from './routes/routeDef';
import { Company } from '@modules/types';
import { useCompany } from '@modules/services/services-company';

const Application: React.FC<Record<string, never>> = () => {
  const {
    user,
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth();

  const [ready, setReady] = useState(false);

  useVerifyAuthenticationOnFocus();

  useEffect(() => {
    getTokenBuilder(isAuthenticated, getAccessTokenSilently, undefined, () =>
      setReady(true)
    )();
  }, [isAuthenticated, getAccessTokenSilently]);

  const liveEvents = useOpenEvents(!ready)?.data;
  let companyInfo: Company | undefined;
  try {
    companyInfo = useCompany({ enabled: ready })?.data;
  } catch (e) {
    /* empty */
  }

  const notifications = useMemo(
    () => ({
      'navigation-tab-events': liveEvents?.items.filter(
        (event) => event.affectedSites && event.affectedSites?.sites.length > 0
      ).length,
    }),
    [liveEvents]
  );

  const logoutUser = () => {
    logout({
      logoutParams: { returnTo: SMART_SERVICES_LOGOUT, federated: true },
    });
  };

  const { routes, tabs } = useMemo(
    () => ({ routes: ROUTE_DEFS(companyInfo), tabs: RouteTabs(companyInfo) }),
    [companyInfo]
  );

  if (isLoading) {
    return <ScreenLoader />;
  }
  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalError error={error} action={logoutUser} />
      </ThemeProvider>
    );
  }
  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.pathname } });
    return <ScreenLoader />;
  }

  // todo: improve GlobalError hoc duplication
  if (user && ready) {
    return (
      <HelmetProvider>
        {!IS_PRODUCTION && (
          <Helmet>
            <title>{process.env.REACT_APP_ENV} - Smart Site</title>
          </Helmet>
        )}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route
              path="/"
              element={
                <Root
                  logout={logoutUser}
                  routes={routes}
                  tabs={tabs}
                  notifications={notifications}
                />
              }
            >
              <Route path={routes.dashboard.path} element={<Dashboard />} />
              <Route
                path={routes['individual-site-map'].path}
                element={<IndividualSiteMap />}
              />
              <Route
                path={routes['individual-site'].path}
                element={<IndividualSite />}
              />
              <Route path={routes.portfolio.path} element={<Portfolio />} />
              <Route
                path={routes['portfolio-map'].path}
                element={<PortfolioMap />}
              />
              {companyInfo?.coachEnabled && (
                <>
                  <Route
                    path={routes['risk-coach'].path}
                    element={<RiskContact />}
                  />
                  <Route
                    path={routes['risk-coach-success'].path}
                    element={<Success />}
                  />
                  <Route
                    path={routes['risk-coach-error'].path}
                    element={<Error />}
                  />
                </>
              )}
              <Route
                path={routes.recommendation.path}
                element={<Recommendation />}
              />
              <Route
                path={routes.recommendations.path}
                element={<Recommendations />}
              />
              <Route path={routes.playbooks.path} element={<Playbooks />} />
              <Route path={routes.events.path} element={<LiveEvents />} />
              <Route path={routes.event.path} element={<LiveEvents />} />
              <Route path="*" element={<GlobalError error="404" />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </HelmetProvider>
    );
  }

  return <ScreenLoader />;
};

export default Application;
