import { Box, styled } from '@mui/material';

export const SquareContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: theme.shadows[1],
}));

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.25),
}));
