import React from 'react';
import { TitleSiteDrawerProps } from './TitleSiteDrawer.types';
import { IconButton } from '@mui/material';
import { TitleContainer, TitleText } from './TitleSiteDrawer.styles';
import { CloseIcon } from '../Icons/icons';

const TitleSiteDrawer: React.FC<TitleSiteDrawerProps> = ({
  title,
  onClose,
}) => {
  return (
    <TitleContainer>
      <TitleText variant="h2">{title}</TitleText>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </TitleContainer>
  );
};

export default TitleSiteDrawer;
