import React from 'react';
import { RiskGradingContainer } from './PortfolioRiskGrading.styles';
import { Skeleton, Typography } from '@mui/material';
import ItemPortfolioRiskGradingSkeleton from '@components/molecules/portfolio/ItemPortfolioRiskGrading/ItemPortfolioRiskGrading.loading';

const PortfolioRiskGradingSkeleton = () => {
  return (
    <>
      <RiskGradingContainer>
        <Skeleton>
          <Typography textTransform="uppercase" variant="h3">
            {'Titleloading'}
          </Typography>
        </Skeleton>
        <ItemPortfolioRiskGradingSkeleton />
        <ItemPortfolioRiskGradingSkeleton />
        <ItemPortfolioRiskGradingSkeleton />
        <ItemPortfolioRiskGradingSkeleton />
        <ItemPortfolioRiskGradingSkeleton />
      </RiskGradingContainer>
    </>
  );
};

export default PortfolioRiskGradingSkeleton;
