import React from 'react';
import { InfoScoreGridProps } from './InfoScoreGrid.types';
import {
  GridContainer,
  TitleContainer,
  TitleGrid,
} from './InfoScoreGrid.styles';
import { RiskInformationColumns } from './datadef';
import { RiskInformationGrid } from '../RiskInformationGrid';

const InfoScoreGrid: React.FC<InfoScoreGridProps> = ({
  title,
  items,
  color,
}) => {
  return (
    <GridContainer>
      <TitleContainer color={color}>
        <TitleGrid variant="leadSemi">{title}</TitleGrid>
      </TitleContainer>
      <RiskInformationGrid columns={RiskInformationColumns} rows={items} />
    </GridContainer>
  );
};

export default InfoScoreGrid;
