import { Box, styled } from '@mui/material';

export const BarProgress = styled(Box)<{
  height: number;
  width: number;
  color: string;
  opacity: boolean;
}>(({ height, color, width, opacity = true }) => ({
  height: height,
  width: `${width}%`,
  backgroundColor: color,
  opacity: opacity ? 0.3 : 1,
}));
