import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { SitesOverviewProps } from './SitesOverview.types';
import { SitesRiskIndicator } from '@components/molecules';
import ScopedDark from '@components/themes/ScopedDark';
import { useTranslation } from 'react-i18next';
import { SitesIcon } from '@components/atoms/Icons/icons';
import { OverviewContainer, OverviewDescription } from './SitesOverview.styles';

const SitesOverview: React.FC<SitesOverviewProps> = ({
  count,
  riskCount,
  href,
}) => {
  const { t } = useTranslation();
  return (
    <ScopedDark>
      <OverviewContainer>
        <Stack spacing={2} alignItems={'center'}>
          <Typography id="portfolio-overview-total" variant="d1">
            {count}
          </Typography>
          <OverviewDescription
            id="portfolio-overview-description"
            variant="lead"
          >
            {t('dashboard.portfolio.sites')}
          </OverviewDescription>
          <SitesRiskIndicator
            count={riskCount}
            totalCount={count}
            titleRisk={t('dashboard.portfolio.risk')}
            titleTooltip={t('dashboard.portfolio.tooltip.title')}
            descriptionTooltip={t('dashboard.portfolio.tooltip.description')}
          />
          <Button
            id="portfolio-overview-button-see-all"
            variant="contained"
            size="large"
            startIcon={<SitesIcon />}
            href={href}
          >
            {t('dashboard.portfolio.action')}
          </Button>
        </Stack>
      </OverviewContainer>
    </ScopedDark>
  );
};

export default SitesOverview;
