import React from 'react';
import { ParagraphTutoProps } from './ParagraphTuto.types';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { TitlesectionContainer } from './ParagraphTuto.styles';

const ParagraphTuto: React.FC<ParagraphTutoProps> = ({
  title,
  description,
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return (
    <Box>
      <TitlesectionContainer>
        <Typography variant={'h2'}>{title}</Typography>
      </TitlesectionContainer>
      <Typography variant={isSmall ? 'paragraph' : 'leadSemi'}>
        {description}
      </Typography>
    </Box>
  );
};

export default ParagraphTuto;
