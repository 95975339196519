import React from 'react';
import { RiskScanningPromotionProps } from './RiskScanningPromotion.types';
import {
  RedirectionButton,
  Separator,
  SquareContainer,
  TitleContainer,
} from './RiskScanningPromotion.styles';
import { Box, Typography } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { sanitizeUrl } from '@braintree/sanitize-url';

const RiskScanningPromotion: React.FC<RiskScanningPromotionProps> = ({
  title,
  description,
  buttonTitle,
  url,
}) => {
  return (
    <SquareContainer>
      <Box>
        <TitleContainer variant="h3">{title}</TitleContainer>
        <Typography variant="paragraphMedium">{description}</Typography>
      </Box>
      <Separator />
      <RedirectionButton
        id="redirection-risk-scanning-button"
        target="_blank"
        variant="text"
        size="xs"
        endIcon={<Launch />}
        href={url && sanitizeUrl(url)}
      >
        {buttonTitle}
      </RedirectionButton>
    </SquareContainer>
  );
};

export default RiskScanningPromotion;
