import { createTheme, Shadows } from '@mui/material';
import components from './muiComponents/components';
import { darkPalette, palette } from './palette/palette';
import typography from './typography/typography';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
  }
}

declare module '@mui/material/styles' {}

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 688,
      desktop: 944,
    },
  },
  shadows: ['none', '0px 0px 4px rgba(0, 0, 0, 0.2)'].concat(
    Array(23).fill('0px 4px 8px rgba(0, 0, 0, 0.2)')
  ) as Shadows,
  palette,
  typography,
});

export const axaTheme = createTheme(theme, {
  components: components(theme),
});

export const axaDarkTheme = createTheme(axaTheme, {
  palette: darkPalette,
});
