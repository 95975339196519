import { Box, styled } from '@mui/material';

export const ChartContainer = styled('div')`
  width: ${({ size }: { size: number }) => size}px;
  height: ${({ size }: { size: number }) => size}px;
  position: relative;
  display: inline-block;
  overflow: hidden;
`;

export const TotalContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DoughnutContainer = styled(Box)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;
