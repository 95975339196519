import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';

export const SectionIcon = styled('img')``;

export const SmallTooltip = styled(Tooltip)(() => ({
  [`& .${tooltipClasses.popper}`]: {
    maxWidth: 220,
  },
  marginLeft: 4,
}));

export const TooltipBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const TooltipContainer = styled(Box)`
  padding: 0.5rem 1rem;
`;

export const TooltipTitle = styled(Typography)`
  border-bottom: 1px solid #e5e5e5;
  padding: 0.5rem 1rem;
`;
