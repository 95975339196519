import {
  MapFilter,
  MapFilterControl,
} from '@components/molecules/locationMap/MapFiltersControl/MapFiltersControl.types';
import { Expression } from 'mapbox-gl';
import type { LayerProps } from 'react-map-gl';
import {
  buildMapboxColorExpression,
  isHeatmapFilter,
  isMapParentFilter,
  isMapScaleFilter,
  mapFilters,
} from './filters';

const radiusSiteValue = (totalValue: number, totalCount: number) =>
  [
    'interpolate',
    ['linear'],
    ['number', ['get', 'siteValue']],
    0,
    5,
    (10 * totalValue) / totalCount,
    50,
  ] as Expression;

export const unclusteredPointLayer = (
  filter: MapFilter,
  totalValue: number,
  totalCount: number
): LayerProps => ({
  id: 'unclustered-point',
  type: 'circle',
  source: 'locations',
  ...(isHeatmapFilter(filter) ? { minzoom: 7 } : null),
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': buildMapboxColorExpression(filter),
    'circle-radius': radiusSiteValue(totalValue, totalCount),
    'circle-opacity': isHeatmapFilter(filter)
      ? ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 1]
      : 1,
  },
});

export const pointSitesLiveLayer = (
  totalValue: number,
  totalCount: number,
  color: string
): LayerProps => ({
  type: 'circle',
  source: 'locations',
  filter: ['!', ['has', 'point_count']],
  minzoom: 5,
  paint: {
    'circle-color': color,
    'circle-radius': radiusSiteValue(totalValue, totalCount),
  },
});
export const valueHeatmapLayer = (
  totalValue: number,
  totalCount: number,
  maxZoom?: number
): LayerProps => ({
  id: 'value-heat',
  type: 'heatmap',
  source: 'locations',
  maxzoom: maxZoom ? maxZoom : 9,
  paint: {
    // Increase the heatmap weight based on frequency and property magnitude
    'heatmap-weight': [
      'interpolate',
      ['linear'],
      ['get', 'siteValue'],
      0,
      0,
      (5 * totalValue) / totalCount,
      5,
    ],
    // Increase the heatmap color weight by zoom level
    // heatmap-intensity is a multiplier on top of heatmap-weight
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
    // Begin color ramp at 0-stop with a 0-transparancy color
    // to create a blur-like effect.
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(33,102,172,0)',
      0.2,
      'rgb(103,169,207)',
      0.4,
      'rgb(209,229,240)',
      0.6,
      'rgb(253,219,199)',
      0.8,
      'rgb(239,138,98)',
      1,
      'rgb(178,24,43)',
    ],
    // Adjust the heatmap radius by zoom level
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 20, 9, 12],
    // Transition from heatmap to circle layer by zoom level
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 8, 0],
  },
});

const generateLevelProperties = (field: string, levels: (string | number)[]) =>
  levels.reduce(
    (properties, level) => ({
      ...properties,
      [field + level]: [
        '+',
        ['case', ['==', ['get', field], level], ['get', 'siteValue'], 0],
      ],
    }),
    {}
  );

const generateLevelPropertiesList = (fields: string[], levels: string[]) =>
  fields.reduce(
    (properties, field) => ({
      ...properties,
      ...generateLevelProperties(field, levels),
    }),
    {}
  );

const generateFiltersProperties = (filters: MapFilterControl[]) =>
  filters.reduce(
    (properties, filter) => ({
      ...properties,
      ...(isMapParentFilter(filter)
        ? generateLevelPropertiesList(
            filter.subfields.map((filter) => filter.property),
            filter.subfields
              .filter(isMapScaleFilter)
              .map((filter) => filter.scale.map((scale) => scale.value))
              .flat(Infinity) as string[]
          )
        : isMapScaleFilter(filter)
        ? generateLevelProperties(
            filter.property,
            filter.scale.map((scale) => scale.value).flat()
          )
        : null),
    }),
    {}
  );

export const clusterProperties = {
  siteValue: ['+', ['get', 'siteValue']],
  ...generateFiltersProperties(mapFilters),
};
