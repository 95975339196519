import React from 'react';
import { ChipGrade } from '@components/atoms/ChipGrade';
import { Box, Typography } from '@mui/material';
import { RiskGridContainer } from './RiskInformationGrid.styles';
import { RiskInformationGridProps } from './RiskInformationGrid.types';

const RiskInformationGrid: React.FC<RiskInformationGridProps> = ({
  columns,
  rows,
}) => {
  return (
    <RiskGridContainer>
      {columns.map(({ headerName }) => (
        <Typography key={headerName} variant="leadSemi">
          {headerName}
        </Typography>
      ))}
      {rows.map((row) => (
        <>
          <Typography variant="paragraph">{row.zone}</Typography>
          <Typography variant="paragraph">{row.score}</Typography>
          <Box>
            <ChipGrade
              label={row.grading.label}
              variant="filled"
              color={row.grading.color}
            />
          </Box>
        </>
      ))}
    </RiskGridContainer>
  );
};

export default RiskInformationGrid;
