import { LngLatBoundsLike, Projection } from 'mapbox-gl';

export const DEFAULT_MAP_PROPS = {
  reuseMaps: true,
  initialViewState: {
    latitude: 40.67,
    longitude: -103.59,
    zoom: 0,
  },
  // attributionControl: false,
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  dragRotate: false,
  maxBounds: [
    [-180, -65], // Southwest coordinates
    [180, 80], // Northeast coordinates
  ] as LngLatBoundsLike,
  minZoom: 0,
  maxZoom: 20,
  projection: {
    name: 'mercator',
  } as Projection,
};
