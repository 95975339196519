import TitleIconResponsiveSkeleton from '@components/molecules/navigation/TitleIconResponsive/TitleIconResponsive.loading';
import { Box, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import {
  InfoContainer,
  InfoContent,
  InfoItemContainer,
} from './InfoDetails.styles';

const InfoItemSkeleton: React.FC<{
  separator?: boolean;
  children?: React.ReactElement | React.ReactElement[];
}> = ({ separator, children }) => {
  return (
    <InfoItemContainer>
      <TitleIconResponsiveSkeleton marginIcon src={'/pin.svg'} alt={'risk'}>
        <Skeleton width={70} />
      </TitleIconResponsiveSkeleton>
      <InfoContent borderBottom={separator}>{children}</InfoContent>
    </InfoItemContainer>
  );
};

const InfoDetailsSkeleton: React.FC = () => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return (
    <InfoContainer id="site-component-details">
      {[1, 2, 3].map((item) => (
        <InfoItemSkeleton separator key={item}>
          <Box sx={{ width: isSmall && item === 1 ? '100%' : 250 }}>
            <Typography
              variant={isSmall ? 'paragraphMedium' : 'lead'}
              sx={{
                whiteSpace: 'pre-line',
              }}
            >
              <Skeleton width={250} />
              <Skeleton width={250} />
              <Skeleton width={250} />
              <Skeleton width={250} />
            </Typography>
            {item === 1 && (
              <Skeleton width={isSmall ? '100%' : 130} height={52} />
            )}
          </Box>
        </InfoItemSkeleton>
      ))}
    </InfoContainer>
  );
};

export default InfoDetailsSkeleton;
