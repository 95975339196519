import { Box, styled, Stack } from '@mui/material';

export const StepIcon = styled('img')`
  width: 56px;
  height: 56px;
`;

export const TitleAndDescriptionContainer = styled(Stack)(() => ({
  whiteSpace: 'normal',
}));

export const ActionContainer = styled(Box)(({ theme, width = 343 }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.up('tablet')]: {
    gap: theme.spacing(3),
    width,
    alignSelf: 'center',
  },
}));
