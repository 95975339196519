import {
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
} from '@mui/material';

export const CardIcon = styled('img')`
  width: 40px;
  height: 30px;
  margin-right: 8px;
`;

export const TitleItemContainer = styled(Box)`
  display: flex;
  align-items: start;
  gap: 8px;
  flex: 1 1 auto;
  flex-direction: row;
`;

export const RiskLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  flex: 1,
  border: 1,
  borderColor: 'white',
  borderRadius: 8,
  marginRight: 1,
  [theme.breakpoints.up('tablet')]: {
    width: 370,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#C91432',
  },
}));
