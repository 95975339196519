import { Menu } from '@mui/icons-material';
import React from 'react';
import { ButtonsContainer, IconButtonControl } from './MapMenuControl.styles';
import { MapMenuControlProps } from './MapMenuControl.types';

const MapMenuControl: React.FC<MapMenuControlProps> = ({ onOpen }) => {
  return (
    <ButtonsContainer>
      <IconButtonControl
        id="map-style"
        aria-label="map-style"
        onClick={onOpen}
        startIcon={<Menu />}
        variant={'contained-unchecked'}
      />
    </ButtonsContainer>
  );
};

export default MapMenuControl;
