import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery, Theme } from '@mui/material';
import { NavBar } from '@components/organisms/navigation/NavBar';
import { TabBar } from '@components/organisms/navigation/TabBar';
import { Drawer } from '@components/organisms/navigation/Drawer';
import {
  matchPathPattern,
  matchPathRouteDef,
  NotificationFeatures,
  RouteDef,
} from '@utils/routes';
import { useTranslation } from 'react-i18next';
import { Footer } from '@components/organisms/navigation/Footer';
import { TabDef } from '@components/organisms/navigation/Drawer/Drawer.types';
import { TFunction } from 'i18next';
import { createContext, useContext } from 'react';

export type DrawerContextType = {
  toggleDrawer: () => void;
};

export const DrawerContext = createContext<DrawerContextType>({
  toggleDrawer: () => {
    throw 'useDrawer is available only when toggleDrawer method is implemented on DrawerContext.Provider parent';
  },
});

export const useDrawer = () => {
  return useContext(DrawerContext);
};

const Application: React.FC<{
  logout: () => void;
  routes: RouteDef;
  tabs: (t: TFunction<'translation', undefined, 'translation'>) => TabDef[];
  notifications: NotificationFeatures;
}> = ({ logout, routes, tabs, notifications }) => {
  const isLarge = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('desktop')
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const routeEntries = tabs(useTranslation().t);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { tabPath, tabIndex, currentRoute } = useMemo(() => {
    const tabMatch = matchPathPattern(
      pathname,
      routeEntries?.map((item) => item.pattern)
    );

    const route = matchPathRouteDef(pathname, routes);

    return {
      currentRoute: route,
      tabPath: tabMatch?.match?.pattern?.path,
      tabIndex: tabMatch ? tabMatch.index : false,
    };
  }, [pathname, routes, tabs]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [tabPath]);

  const toggleDrawer =
    (open?: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event?.type === 'keydown' &&
        ((event as React.KeyboardEvent)?.key === 'Tab' ||
          (event as React.KeyboardEvent)?.key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen((drawerOpen) => (open !== undefined ? open : !drawerOpen));
    };

  const redirect = (id: string, path: string) => {
    setDrawerOpen(false);
    navigate(path);
  };

  return (
    <>
      {(isLarge || !(currentRoute?.route?.hideHeader && !drawerOpen)) && (
        <NavBar
          onLogout={logout}
          onMenuClick={toggleDrawer}
          appName={t('app.name')}
          portalNavigation={t('navigation.portal')}
          logout={t('navigation.logout')}
        />
      )}
      {isLarge ? (
        <TabBar
          items={routeEntries}
          value={tabIndex}
          notifications={notifications}
        />
      ) : (
        <Drawer
          currentPath={tabPath}
          items={routeEntries}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onRedirect={redirect}
          onLogout={logout}
          notifications={notifications}
        />
      )}
      <DrawerContext.Provider
        value={{ toggleDrawer: () => setDrawerOpen(true) }}
      >
        <Outlet />
      </DrawerContext.Provider>
      {!currentRoute?.route?.hideFooter && (
        <Footer
          footerPolicy={t('footer.policy')}
          footerSupport={t('footer.support')}
          footerCookies={t('footer.cookies')}
          footerLegal={t('footer.legal')}
        />
      )}
    </>
  );
};

export default Application;
