import React from 'react';
import { ColorBox, TextLegendItemContainer } from './LegendItemMobile.styles';
import { LegendItemMobileProps } from './LegendItemMobile.types';

const LegendItemMobile: React.FC<LegendItemMobileProps> = ({
  color,
  value,
  label,
  labelMobile,
}) => {
  return (
    <ColorBox color={color}>
      <TextLegendItemContainer variant="h5">
        {labelMobile ? labelMobile : !Array.isArray(value) ? value : label}
      </TextLegendItemContainer>
    </ColorBox>
  );
};

export default LegendItemMobile;
