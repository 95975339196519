import { Typography } from '@mui/material';
import React from 'react';
import {
  IconButtonContainer,
  ImageContainer,
  TitleMapStyleContainer,
} from './MapStyle.styles';
import { MapStyleProps } from './MapStyle.types';

const MapStyle: React.FC<MapStyleProps> = ({
  isSelected,
  mapStyle,
  onChangeMap,
  label,
}) => {
  return (
    <IconButtonContainer
      key={mapStyle.id}
      onClick={() => {
        onChangeMap(mapStyle);
      }}
    >
      <ImageContainer
        isSelected={isSelected}
        src={mapStyle.icon}
        alt={mapStyle.id}
      />
      <TitleMapStyleContainer>
        <Typography
          variant={'paragraphMediumSemi'}
          color={mapStyle.id === 'map-satellite' ? 'white' : '#00008F'}
        >
          {label}
        </Typography>
      </TitleMapStyleContainer>
    </IconButtonContainer>
  );
};

export default MapStyle;
