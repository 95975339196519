import React from 'react';
import { GradientSection, PageStep, PaperStep } from '@components/templates';
import { TopWhiteSection } from '@components/molecules';

import { useTranslation } from 'react-i18next';
import { RecommendationStep } from '@components/organisms';
import {
  RecommendationAction,
  RecommendationQuestion,
  RecommendationType,
} from '@modules/types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomReportItemDetail,
  IDCustomReportItem,
  useDisableRecommendation,
  useRecommendation,
} from '@modules/services/services-recommendations';
import RecommendationLoader from './Recommendation.loading';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';
import {
  useCompany,
  useOpenHazardReportPdf,
} from '@modules/services/services-company';
import { openNewTab } from '@utils/routes';

export const getRecommendationImage = (type: RecommendationType) => {
  switch (type) {
    case 'data-quality':
      return '/database.svg';
    case 'portfolio-risk':
      return '/portfolio.svg';
    case 'report':
      return '/report.svg';
    case 'human-element':
      return '/human-element.svg';
    case 'all-recommendations':
      return '/fidelity.svg';
    default:
      return '/portfolio.svg';
  }
};

export interface RecommendationProps {}

const Recommendation: React.FC<RecommendationProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutate: disableRecommendationMutate } = useDisableRecommendation();
  const { mutate: openHazardReportPdfMutate } = useOpenHazardReportPdf();
  const params = useParams();
  const { data: recommendation } =
    params?.id === IDCustomReportItem
      ? { data: CustomReportItemDetail }
      : useRecommendation(params?.id);
  const { data: company } = useCompany();
  if (!(recommendation && company)) {
    return <RecommendationLoader />;
  }

  const step = recommendation.items[0] as RecommendationQuestion;

  const responses = step.responses.filter(
    ({ action }) => action.type !== 'CONTACT' || company?.coachEnabled
  );

  const handleResponse = (action: RecommendationAction | undefined) => {
    switch (action?.type) {
      case 'EXTERNAL_LINK':
        openNewTab(action.data.url);
        break;
      case 'INTERNAL_LINK':
        navigate(sanitizeUrl(action.data.url));
        break;
      case 'PDF_LINK':
        company && company.id && openHazardReportPdfMutate(company.id);
        break;
      case 'CONTACT':
        navigate('/riskcoach');
        break;
      case 'DISMISS':
        disableRecommendationMutate(recommendation.id, {
          onSettled: () => navigate('/'),
        });
        break;
      case 'BACK':
        navigate('/');
        break;
      default:
        // todo: raise error
        break;
    }
  };

  return (
    <>
      <GradientSection color="linear-gradient(90deg, #015A66 0.37%, #41949F 68.63%)">
        <TopWhiteSection backText={t('navigation.back')}>
          {recommendation.title}
        </TopWhiteSection>
      </GradientSection>
      <PageStep>
        <PaperStep>
          <RecommendationStep
            id={step.id}
            title={step.title}
            description={step.description}
            type={step.type}
            responses={responses}
            onResponseAction={handleResponse}
            image={getRecommendationImage(step.type)}
            selectOptionTitle={
              params?.id === IDCustomReportItem
                ? ''
                : t('action.select.options')
            }
            nextButtonTitle={t('action.next')}
            unprioritizeButtonTitle={t('action.unprioritize')}
            alwaysDispay={params?.id === IDCustomReportItem}
          />
        </PaperStep>
      </PageStep>
    </>
  );
};

export default withErrorBoundary(Recommendation);
