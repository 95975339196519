import { ExpandLess } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  AccordionLegend,
  AccordionRisks,
  AccordionTitle,
  LegendColor,
  ColorBox,
} from './MapLegend.styles';
import {
  MapLegendHandle,
  MapLegendItemProps,
  MapLegendProps,
} from './MapLegend.types';

const LegendItem = ({ color, label }: MapLegendItemProps) => (
  <>
    <ColorBox>
      <LegendColor color={color} />
    </ColorBox>
    <Typography variant="paragraphSmall">{label}</Typography>
  </>
);

const MapLegend: React.ForwardRefRenderFunction<
  MapLegendHandle,
  MapLegendProps
> = ({ items, limit, title }, forwardedRef) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  React.useImperativeHandle(forwardedRef, () => ({
    open() {
      setExpanded(true);
    },
  }));

  return (
    <AccordionLegend
      id="map-legend-accordion"
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionTitle expandIcon={<ExpandLess />}>
        <Typography variant="h5">{title}</Typography>
      </AccordionTitle>
      <AccordionRisks>
        <Typography variant="h5">{limit.top.toUpperCase()}</Typography>
        <ColorBox />
        {items.map((item) => item && <LegendItem key={item.label} {...item} />)}
        <Typography variant="h5">{limit.bottom.toUpperCase()}</Typography>
        <ColorBox />
      </AccordionRisks>
    </AccordionLegend>
  );
};

export default React.forwardRef(MapLegend);
