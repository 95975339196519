import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Stack, StackProps } from '@mui/material';
import { ExpandButton } from './ExpandableList.styles';
import { ExpandableListProps } from './ExpandableList.types';

const ExpandableList = <T,>({
  items,
  renderItem,
  showCount,
  buttonText,
  ...props
}: ExpandableListProps<T> & StackProps) => {
  const canExpand = items.length > showCount;
  const [isExpanded, setExpandable] = useState(false);
  const visibleItems =
    !canExpand || isExpanded ? items : items.slice(0, showCount);

  return (
    <Stack {...props}>
      {visibleItems?.map((item) => renderItem(item))}

      {canExpand && (
        <ExpandButton
          id="gobal-button-expand"
          onClick={() => setExpandable((value) => !value)}
          size="small"
          endIcon={isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        >
          {buttonText(isExpanded)}
        </ExpandButton>
      )}
    </Stack>
  );
};

export default ExpandableList;
