import React from 'react';
import { DrawerProps } from './Drawer.types';
import {
  Box,
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { GridView, PersonOutlineOutlined } from '@mui/icons-material';
import { LogoutButton, SmallButton, TextContainer } from './Drawer.styles';
import { ProminentToolBar } from '../NavBar/NavBar.styles';
import { useTranslation } from 'react-i18next';
import { SMART_SERVICES_PRIVATE } from '@utils/urls';

interface ListItemLinkProps {
  id: string;
  label: string;
  path: string;
  selected: boolean;
}

const Drawer: React.FC<DrawerProps> = ({
  items,
  currentPath,
  onLogout,
  onRedirect,
  notifications,
  ...props
}) => {
  const { t } = useTranslation();

  function ListItemLink({
    id,
    selected,
    label,
    path,
    ...props
  }: ListItemLinkProps) {
    return (
      <ListItem disablePadding {...props}>
        <ListItemButton
          onClick={() => {
            onRedirect(id, path);
          }}
          selected={selected}
        >
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <MuiDrawer {...props}>
      <ProminentToolBar />
      <TextContainer>
        <Typography variant="d6">{t('app.name')}</Typography>
        <Typography variant="paragraph">
          {t('app.services.description')}
        </Typography>
      </TextContainer>
      <SmallButton
        size="small"
        href={SMART_SERVICES_PRIVATE}
        startIcon={<GridView fontSize="medium" />}
      >
        {t('navigation.portal')}
      </SmallButton>

      <Box sx={{ overflow: 'auto' }}>
        <List>
          {items?.map(({ id, label, path, pattern }) => [
            <ListItemLink
              id={id}
              key={id}
              selected={currentPath === pattern}
              path={path}
              label={
                label + (notifications[id] ? ` (${notifications[id]})` : '')
              }
            />,
            <Divider key={`divider-${label}`} />,
          ])}
        </List>
      </Box>
      <LogoutButton
        variant="outlined"
        endIcon={<PersonOutlineOutlined />}
        onClick={onLogout}
      >
        {t('navigation.logout')}
      </LogoutButton>
    </MuiDrawer>
  );
};

export default Drawer;
