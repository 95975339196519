import React from 'react';
import { ChipGradeStyled } from './ChipGrade.styles';
import { ChipGradeProps } from './ChipGrade.types';

const ChipGrade: React.FC<ChipGradeProps> = ({
  variant = 'outlined',
  label,
  light,
  color,
  bold,
}) => {
  return (
    <ChipGradeStyled
      bold={bold}
      icon={
        variant === 'outlined' ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <circle
              opacity={light ? 0.9 : 0.5}
              cx="10"
              cy="10"
              r="10"
              fill={color}
            />
            <circle
              opacity={light ? 0.9 : 0.3}
              cx="9.9987"
              cy="10.0002"
              r="6.66667"
              fill={light || color}
            />
          </svg>
        ) : null
      }
      size="small"
      variant={variant}
      label={label || 'No data'}
      $riskColor={color}
    />
  );
};

export default ChipGrade;
