import { ArrowRightAlt } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorStack } from '../ErrorGeneric/ErrorGeneric.styles';
import { ErrorImage } from './Error404.styles';
import { Error404Props } from './Error404.types';

const Error404: React.FC<Error404Props> = () => {
  const { t } = useTranslation();
  return (
    <ErrorStack spacing={{ mobile: 3, tablet: 5 }}>
      <ErrorImage src="/404.svg" />
      <Typography variant="h1">{t('errors.404.title')}</Typography>
      <Typography variant="lead">{t('errors.404.description')}</Typography>
      <Button
        id="error-404-button-next"
        endIcon={<ArrowRightAlt />}
        href="/"
        variant="contained"
      >
        {t('errors.404.action')}
      </Button>
    </ErrorStack>
  );
};

export default Error404;
