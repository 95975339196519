import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { RiskCoachProps } from './RiskCoach.types';
import {
  ContactButtonContainer,
  ContactPaper,
  Description,
  SectionIcon,
} from './RiskCoach.styles';
import { Contact } from '@components/molecules';
import { Message } from '@mui/icons-material';

const RiskCoach: React.FC<RiskCoachProps> = ({
  coachAction,
  coachDescription,
  coachTitle,
  ...props
}) => {
  return (
    <ContactPaper id="risk-coach-card">
      <Stack>
        <Stack alignItems="center" direction="row">
          <SectionIcon src="/weechat.svg" alt="chat" />
          <Typography variant="h1">{coachTitle}</Typography>
        </Stack>
        <Description variant="paragraphMedium">{coachDescription}</Description>
        <ContactButtonContainer>
          <Contact {...props} />
          <Button
            id="risk-coach-button-contact"
            aria-label="contact risk coach"
            variant="contained"
            endIcon={<Message />}
            href="/riskcoach"
            sx={{
              flex: 1,
            }}
          >
            {coachAction}
          </Button>
        </ContactButtonContainer>
      </Stack>
    </ContactPaper>
  );
};

export default RiskCoach;
