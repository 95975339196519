import React from 'react';
import { RiskGradeTutoProps } from './RiskGradeTuto.types';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { ExplanationPga } from '@components/atoms/ExplanationPga';
import { InfoRiskGrade } from '@components/molecules/portfolio/InfoRiskGrade';
import {
  ExampleContainer,
  ExplanationContainer,
  ListInfoRiskContainer,
  RiskGradeContainer,
} from './RiskGradeTuto.styles';
import { infoRiskList } from '@components/molecules/portfolio/InfoRiskGrade/datadef';
import { InfoRiskGradeProps } from '@components/molecules/portfolio/InfoRiskGrade/InfoRiskGrade.types';
import { useTranslation } from 'react-i18next';

const RiskGradeTuto: React.FC<RiskGradeTutoProps> = () => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  const { t } = useTranslation();
  return (
    <RiskGradeContainer>
      <Typography variant={isSmall ? 'h2' : 'd6'}>
        {isSmall
          ? t('portfolio.tutorial.earthquake_example_mobile')
          : t('portfolio.tutorial.earthquake_example')}
      </Typography>
      <ExampleContainer>
        <ListInfoRiskContainer>
          {infoRiskList.map((item: InfoRiskGradeProps, index) => (
            <InfoRiskGrade key={`${index}-${item.label}`} {...item} />
          ))}
        </ListInfoRiskContainer>
        <ExplanationContainer>
          <ExplanationPga
            title={t('portfolio.tutorial.pga')}
            description={t('portfolio.tutorial.pga_description')}
          />
        </ExplanationContainer>
      </ExampleContainer>
    </RiskGradeContainer>
  );
};

export default RiskGradeTuto;
