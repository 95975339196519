import { Chip, chipClasses, styled } from '@mui/material';

export const ChipGradeStyled = styled(Chip)<{
  $riskColor: string;
  bold?: boolean;
}>(({ $riskColor, bold }) => ({
  fontWeight: bold ? 600 : 400,
  [`&.${chipClasses.filled}`]: {
    width: 80,
    backgroundColor: $riskColor,
  },
  [`& .${chipClasses.icon}`]: {
    color: $riskColor || '#999999',
  },
  [`&.${chipClasses.outlined}`]: {
    justifyContent: 'flex-start',
    border: 'none',
    color: bold ? $riskColor : '#343C3D',
    textTransform: 'none',
    backgroundColor: $riskColor ? 'transparent' : '#e5e5e5',
  },
  [`&.${chipClasses.outlined} .${chipClasses.icon}`]: {
    margin: 0,
  },
}));
