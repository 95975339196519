import { MapClusterBar } from '@components/atoms/MapCluster/MapCluster.types';
import {
  MapFilter,
  MapFilterControl,
  MapHeatMapFilter,
  MapParentFilter,
  MapScaleFilter,
} from '@components/molecules/locationMap/MapFiltersControl/MapFiltersControl.types';
import { PerilRiskGrade } from '@modules/types';
import { allRisks } from '@utils/strings';
import { Expression, MapboxGeoJSONFeature } from 'mapbox-gl';
export const mapFilters: MapFilterControl[] = [
  {
    type: 'scale',
    field: 'riskGrade',
    property: 'riskGrade',
    legend: { top: 'High risk', bottom: 'Low risk' },
    scale: [
      { color: '#C91432', label: 'E', value: 'E' },
      { color: '#FF9900', label: 'D', value: 'D' },
      { color: '#F2CE0D', label: 'C', value: 'C' },
      { color: '#8AB957', label: 'B', value: 'B' },
      { color: '#138636', label: 'A', value: 'A' },
    ],
  },
  {
    type: 'scale',
    field: 'geocodeQuality',
    property: 'geocodeQuality',
    legend: { top: 'Low geocoding', bottom: 'High geocoding' },
    scale: [
      {
        color: '#C91432',
        label: 'Very poor',
        labelMobile: 'Very bad',
        value: [1],
      },
      {
        color: '#FF9900',
        label: 'Bad',
        value: [2],
      },
      {
        color: '#F2CE0D',
        label: 'Fair',
        value: [3],
      },
      {
        color: '#8AB957',
        label: 'Very good',
        value: [4],
      },
      {
        color: '#138636',
        label: 'Excellent',
        value: [5],
      },
    ],
  },
  {
    type: 'heatmap',
    field: 'insuredValue',
    property: 'siteValue',
  },
  {
    type: 'group',
    field: 'perils',
    subfields: allRisks.map((risk) => ({
      type: 'scale',
      field: risk,
      property: risk,
      legend: { top: 'Extreme risk', bottom: 'Negligible risk' },
      scale: [
        {
          color: '#C91432',
          label: 'Extreme',
          value: PerilRiskGrade.Extreme,
        },
        {
          color: '#ff9900',
          label: 'High',
          value: PerilRiskGrade.High,
        },

        {
          color: '#ffbc11',
          label: 'Moderate',
          value: PerilRiskGrade.Medium,
        },
        {
          color: '#8AB957',
          label: 'Low',
          value: PerilRiskGrade.Low,
        },

        {
          color: '#138636',
          label: 'Negligible',
          value: 'negligible',
        },
      ],
    })),
  },
];

export const buildMapboxColorExpression = (filter: MapFilter): Expression => {
  return [
    'case',
    ['boolean', ['feature-state', 'hover'], false],
    '#FFED1F',
    isMapScaleFilter(filter)
      ? ['match', ['get', filter.property]].concat(
          filter.scale
            .map((scale, index, array) =>
              index < array.length - 1
                ? Array.isArray(scale.value)
                  ? scale.value.map((value) => [value, scale.color])
                  : [scale.value, scale.color]
                : [scale.color]
            )
            .flat(Infinity) as string[]
        )
      : '#00008F',
  ] as Expression;
};

export const buildFilterMarkerData =
  (filter: MapScaleFilter) =>
  (element: MapboxGeoJSONFeature): MapClusterBar[] =>
    filter.scale.map((level) => {
      return {
        color: level.color,
        value: Array.isArray(level.value)
          ? level.value.reduce(
              (sum, current) =>
                element?.properties !== null
                  ? sum + element.properties[filter.property + current]
                  : sum,
              0
            )
          : element?.properties !== null
          ? element.properties[filter.property + level.value]
          : 0,
      };
    });

export function isMapParentFilter(
  value: MapFilterControl
): value is MapParentFilter {
  return value.type === 'group';
}

export function isMapFilter(value: MapFilterControl): value is MapFilter {
  return value.type !== 'group';
}

export function isMapScaleFilter(
  value: MapFilterControl
): value is MapScaleFilter {
  return value.type === 'scale';
}

export function isHeatmapFilter(
  value: MapFilterControl
): value is MapHeatMapFilter {
  return value.type === 'heatmap';
}
