import React from 'react';
import { RouteDef } from '@utils/routes';
import { TFunction } from 'i18next';
import { Domain, GridView, PersonOutlineOutlined } from '@mui/icons-material';
import { LiveIcon } from '@components/atoms/Icons/icons';
import { Company } from '@modules/types';

export const ROUTE_DEFS = (company?: Company): RouteDef => {
  const routes: RouteDef = {
    'individual-site-map': {
      title: 'Individual Site Map',
      path: 'portfolio/:id/map',
      hideFooter: true,
    },
    'portfolio-map': {
      title: 'Portfolio Map',
      path: 'portfolio/map',
      hideFooter: true,
      hideHeader: true,
    },
    'individual-site': {
      title: 'Individual Site',
      path: 'portfolio/:id',
    },
    portfolio: {
      title: 'Portfolio',
      path: 'portfolio',
    },
    recommendations: {
      title: 'Recommendations',
      path: 'suggestions',
    },
    recommendation: {
      title: 'Recommendation',
      path: 'suggestions/:id',
    },
    playbooks: {
      title: 'Riskguides',
      path: 'Riskguides',
    },
    events: {
      title: 'Live Event',
      path: 'events/:id',
      hideFooter: true,
    },
    event: {
      title: 'Live Events',
      path: 'events',
      hideFooter: true,
    },
    dashboard: {
      title: 'Dashboard',
      path: '',
    },
  };

  if (company?.coachEnabled) {
    routes['risk-coach'] = {
      title: 'Risk Coach',
      path: 'riskcoach',
    };
    routes['risk-coach-success'] = {
      title: 'Risk Coach Message Sent',
      path: 'riskcoach/success',
    };
    routes['risk-coach-error'] = {
      title: 'Risk Coach Message Error',
      path: 'riskcoach/error',
    };
  }

  return routes;
};

export const RouteTabs =
  (company?: Company) =>
  (t: TFunction<'translation', undefined, 'translation'>) => {
    const tabs = [
      {
        id: 'navigation-tab-dashboard',
        label: t('app.services.dashboard'),
        pattern: '/*',
        path: '/',
        icon: <GridView />,
      },
    ];

    if (company?.coachEnabled) {
      tabs.push({
        id: 'navigation-tab-risk-coach',
        label: t('app.services.coach'),
        pattern: '/riskcoach/*',
        path: '/riskcoach',
        icon: <PersonOutlineOutlined />,
      });
    }

    return tabs.concat([
      {
        id: 'navigation-tab-portfolio',
        label: t('app.services.portfolio'),
        pattern: '/portfolio/*',
        path: '/portfolio',
        icon: <Domain />,
      },
      {
        id: 'navigation-tab-events',
        label: t('app.services.events'),
        pattern: '/events/*',
        path: '/events',
        icon: <LiveIcon />,
      },
    ]);
  };
