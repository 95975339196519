import React, { useLayoutEffect, useRef, useState } from 'react';
import { BoxProps } from '@mui/material';
import {
  HorizontalContainer,
  HorizontalItem,
} from './HorizontalScrollView.styles';
import { HorizontalScrollViewProps } from './HorizontalScrollView.types';
import { useWindowWidth } from '@utils/window';

const HorizontalScrollView = <T,>({
  items,
  renderItem,
  keyExtractor,
  itemAutoWidth,
  ...props
}: HorizontalScrollViewProps<T> & BoxProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isScrollable, setIsScrollable] = useState(
    containerRef &&
      containerRef.current &&
      containerRef.current?.scrollWidth > containerRef.current?.clientWidth
  );
  const width = useWindowWidth();
  useLayoutEffect(() => {
    containerRef.current &&
      setIsScrollable(
        containerRef.current?.scrollWidth > containerRef.current?.clientWidth
      );
  }, [width]);
  return (
    <HorizontalContainer
      {...props}
      ref={containerRef}
      isScrollable={isScrollable}
    >
      {items?.map((item: T, index: number) => (
        <HorizontalItem
          key={
            (keyExtractor && keyExtractor(item)) || `horizontal-item-${index}`
          }
          sx={itemAutoWidth ? { width: { mobile: '95%', tablet: '45%' } } : {}}
        >
          {renderItem(item)}
        </HorizontalItem>
      ))}
    </HorizontalContainer>
  );
};

export default HorizontalScrollView;
