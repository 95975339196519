import React, { useMemo } from 'react';
import { LiveEventIconProps } from './LiveEventIcon.types';
import { IconContainerSize } from './LiveEventIcon.styles';

const LiveEventIcon: React.FC<LiveEventIconProps> = ({
  name,
  color = '#343C3D',
  iconColor = 'white',
  shadowLevel = 0,
}) => {
  const path = useMemo(() => {
    switch (name) {
      case 'hydrological':
        return (
          <g transform="translate(-11.5, -8)">
            <path
              d="M28 14C22.67 18.55 20 22.48 20 25.8C20 30.78 23.8 34 28 34C32.2 34 36 30.78 36 25.8C36 22.48 33.33 18.55 28 14ZM23.83 26C24.2 26 24.5 26.26 24.57 26.62C24.98 28.84 26.85 29.6 28.21 29.49C28.64 29.47 29 29.81 29 30.24C29 30.64 28.68 30.97 28.28 30.99C26.15 31.12 23.66 29.9 23.09 26.87C23.01 26.42 23.37 26 23.83 26Z"
              fill={iconColor}
            />
          </g>
        );
      case 'wildfire':
        return (
          <>
            <path
              d="M23.9808 16.6416C22.3061 12.2896 16.3434 12.0549 17.7834 5.72961C17.8901 5.26028 17.3888 4.89761 16.9834 5.14295C13.1114 7.42561 10.3274 12.0016 12.6634 17.9963C12.8554 18.4869 12.2794 18.9456 11.8634 18.6256C9.93275 17.1643 9.73008 15.0629 9.90075 13.5589C9.96475 13.0043 9.23942 12.7376 8.93008 13.1963C8.20475 14.3056 7.46875 16.0976 7.46875 18.7963C7.87408 24.7696 12.9194 26.6043 14.7328 26.8389C17.3248 27.1696 20.1301 26.6896 22.1461 24.8443C24.3648 22.7856 25.1754 19.5003 23.9808 16.6416ZM14.0821 22.0069C15.6181 21.6336 16.4074 20.5243 16.6208 19.5429C16.9728 18.0176 15.5968 16.5243 16.5247 14.1136C16.8767 16.1083 20.0128 17.3563 20.0128 19.5323C20.0981 22.2309 17.1754 24.5456 14.0821 22.0069Z"
              fill={iconColor}
            />
          </>
        );
      case 'geophysical':
        return (
          <>
            <path
              d="M8.27594 8.26955C4.00754 12.5392 4.00786 19.4614 8.27692 23.7307C10.2461 25.7002 12.8928 26.8435 15.6763 26.9273V28.8C8.76831 28.6252 3.20312 22.9521 3.20312 15.9996C3.20312 9.04719 8.76831 3.37473 15.6763 3.19995V5.07259C12.8905 5.14816 10.2408 6.2931 8.27594 8.26955Z"
              fill={iconColor}
            />
            <path
              d="M10.0639 10.0581C6.78252 13.3395 6.78186 18.6601 10.0626 21.9421C11.5581 23.4383 13.5627 24.3151 15.6764 24.3976V26.2702C10.0054 26.0915 5.55307 21.3488 5.73176 15.6767C5.90256 10.2555 10.2564 5.90082 15.6764 5.72998V7.60262C13.5617 7.67917 11.5554 8.55701 10.0639 10.0581Z"
              fill={iconColor}
            />
            <path
              d="M15.6685 8.25989V23.7397C11.3949 23.5594 8.07638 19.9475 8.25704 15.6729C8.42686 11.6513 11.6478 8.42974 15.6685 8.25989Z"
              fill={iconColor}
            />
            <path
              d="M16.3281 3.19995V28.8C23.2361 28.6252 28.8013 22.9521 28.8013 15.9996C28.8013 9.04719 23.2361 3.37473 16.3281 3.19995ZM20.2802 6.46328C20.3003 6.43896 20.3223 6.4163 20.3459 6.39527L21.3904 5.47538C24.4156 7.03492 26.6268 9.81761 27.4631 13.1174C27.109 13.2416 26.595 13.5346 26.2901 14.218C26.2711 14.2617 26.2576 14.3077 26.2497 14.3547C26.2093 14.5685 26.0198 15.2417 25.2259 15.1517C25.127 15.1399 25.0328 15.104 24.9507 15.0479L23.2505 13.8888C23.1369 13.8113 23.0518 13.6989 23.0084 13.5685L22.0559 10.7273C22.0221 10.6274 22.0142 10.521 22.0329 10.4172L22.2484 9.20719C22.2484 9.20719 22.3663 8.32015 21.6299 8.10135C21.5317 8.07309 21.4279 8.07013 21.328 8.09215C20.7624 8.2137 18.4848 8.60039 20.2802 6.46328ZM24.3552 24.3572C23.0774 25.6404 21.5208 26.6116 19.8066 27.1951C19.5766 26.7233 19.5169 26.0396 20.6244 25.5245C20.6244 25.5245 22.5847 24.4203 20.8048 23.1357C20.2957 22.7825 19.8171 22.387 19.3743 21.953C18.7213 21.2956 18.7239 20.2331 19.3806 19.579L20.4924 18.4666C20.5269 18.4321 20.5581 18.3963 20.5909 18.3585C20.6865 18.2445 20.972 17.9659 21.3031 18.2363C21.4991 18.3996 21.7287 18.5175 21.9754 18.5822C22.5447 18.7251 23.5097 18.8122 24.4856 18.188C24.5299 18.1594 24.5729 18.1288 24.614 18.0967C24.951 17.8338 27.0417 16.2641 27.7649 17.1111C27.5114 19.8484 26.323 22.3886 24.3552 24.3572Z"
              fill={iconColor}
            />
          </>
        );
      case 'meteorological':
        return (
          <>
            <path
              d="M18.6693 21.8666C18.6693 23.6266 17.2293 25.0666 15.4693 25.0666C13.7093 25.0666 12.2693 23.6266 12.2693 21.8666H14.4026C14.4026 22.4532 14.8826 22.9332 15.4693 22.9332C16.0559 22.9332 16.5359 22.4532 16.5359 21.8666C16.5359 21.2799 16.0559 20.7999 15.4693 20.7999H5.33594V18.6666H15.4693C17.2293 18.6666 18.6693 20.1066 18.6693 21.8666ZM23.4693 10.6666C23.4693 8.60789 21.7946 6.93323 19.7359 6.93323C17.6773 6.93323 16.0026 8.60789 16.0026 10.6666H18.1359C18.1359 9.78123 18.8506 9.06656 19.7359 9.06656C20.6213 9.06656 21.3359 9.78123 21.3359 10.6666C21.3359 11.5519 20.6213 12.2666 19.7359 12.2666H5.33594V14.3999H19.7359C21.7946 14.3999 23.4693 12.7252 23.4693 10.6666ZM22.9359 15.4666H5.33594V17.5999H22.9359C23.8213 17.5999 24.5359 18.3146 24.5359 19.1999C24.5359 20.0852 23.8213 20.7999 22.9359 20.7999V22.9332C24.9946 22.9332 26.6693 21.2586 26.6693 19.1999C26.6693 17.1412 24.9946 15.4666 22.9359 15.4666Z"
              fill={iconColor}
            />
          </>
        );
      case 'no-event':
        return (
          <>
            <path
              d="M14.117 21.0701L16.0692 19.1179L18.0215 21.0701L18.8696 20.222L16.9173 18.2698L18.8696 16.3175L18.0215 15.4694L16.0692 17.4217L14.117 15.4694L13.2689 16.3175L15.2211 18.2698L13.2689 20.222L14.117 21.0701ZM21.8699 9.86875H21.0698V8.26855H19.4696V9.86875H13.0688V8.26855H11.4687V9.86875H10.6686C9.78045 9.86875 9.07636 10.5888 9.07636 11.4689L9.06836 22.6703C9.06836 23.5504 9.78045 24.2705 10.6686 24.2705H21.8699C22.75 24.2705 23.4701 23.5504 23.4701 22.6703V11.4689C23.4701 10.5888 22.75 9.86875 21.8699 9.86875ZM21.8699 22.6703H10.6686V13.8692H21.8699V22.6703Z"
              fill="white"
            />
          </>
        );
      default:
        return (
          <>
            <path
              d="M16 16C14.9 16 13.9583 15.6083 13.175 14.825C12.3917 14.0417 12 13.1 12 12C12 10.9 12.3917 9.95833 13.175 9.175C13.9583 8.39167 14.9 8 16 8C17.1 8 18.0417 8.39167 18.825 9.175C19.6083 9.95833 20 10.9 20 12C20 13.1 19.6083 14.0417 18.825 14.825C18.0417 15.6083 17.1 16 16 16ZM8 24V21.2C8 20.6333 8.14583 20.1125 8.4375 19.6375C8.72917 19.1625 9.11667 18.8 9.6 18.55C10.6333 18.0333 11.6833 17.6458 12.75 17.3875C13.8167 17.1292 14.9 17 16 17C17.1 17 18.1833 17.1292 19.25 17.3875C20.3167 17.6458 21.3667 18.0333 22.4 18.55C22.8833 18.8 23.2708 19.1625 23.5625 19.6375C23.8542 20.1125 24 20.6333 24 21.2V24H8Z"
              fill={iconColor}
            />
          </>
        );
    }
  }, [name, iconColor]);
  return (
    <IconContainerSize shadow={shadowLevel}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="16" fill="inherit" />
        {path}
      </svg>
    </IconContainerSize>
  );
};

export default LiveEventIcon;
