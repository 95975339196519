import React, { useCallback, useState } from 'react';
import { GlobalMapProps } from './GlobalMap.types';

import MapGL, { FullscreenControl, Layer, Source } from 'react-map-gl';
import {
  clusterProperties,
  unclusteredPointLayer,
  valueHeatmapLayer,
} from './layers';
import { MapFiltersControl } from '@components/molecules/locationMap/MapFiltersControl';
import { useTranslation } from 'react-i18next';
import { MapFilterControl } from '@components/molecules/locationMap/MapFiltersControl/MapFiltersControl.types';
import { ZoomControl } from '@components/molecules/locationMap/ZoomControl';
import { MapLegend } from '@components/molecules/locationMap/MapLegend';
import { range } from '@utils/numbers';
import { MapSitePopup } from '@components/molecules/locationMap/MapSitePopup';
import { MapCluster } from '@components/atoms/MapCluster';
import { isMapScaleFilter } from './filters';
import { MapBackControl } from '@components/molecules/locationMap/MapBackControl';
import { usePortfolioMap } from './useSiteMap';
import './GlobalMap.css';
import { Theme, useMediaQuery } from '@mui/material';
import { MapFilterHintControl } from '@components/molecules/locationMap/MapFilterHintControl';
import { MapSettingsDrawer } from '@components/molecules/locationMap/MapSettingsDrawer';
import { MapStyleLegendButtonsControl } from '@components/molecules/locationMap/MapStyleLegendButtonsControl';
import { MapMenuControl } from '@components/molecules/locationMap/MapMenuControl';
import { useDrawer } from '@pages/Root';
import { MapLegendMobile } from '@components/molecules/locationMap/MapLegendMobile';
import { MapStyleControl } from '@components/molecules/locationMap/MapStyleControl';
import { DEFAULT_MAP_PROPS } from '@utils/map';
import { useIsFullScreen } from '@utils/window';
import { MapSiteDrawer } from '@components/molecules/locationMap/MapSiteDrawer';
import { MapFooterAttributes } from '@components/molecules/locationMap/MapFooterAttributes';
import { MapAttributesModal } from '@components/molecules/locationMap/MapAttributesModal';

const GlobalMap: React.FC<GlobalMapProps> = ({
  data,
  mapStyles,
  filters,
  totalValue,
  companyCurrency,
}) => {
  const { t } = useTranslation();

  const { toggleDrawer } = useDrawer();

  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('desktop')
  );
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );

  const siteMap = usePortfolioMap(data, filters);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [legendMobileOpen, setLegendMobileOpen] = useState(false);
  const [isOpenModalAttributes, setIsOpenModalAttributes] = useState(false);

  const [mapStyle, onChangeMap] = useState(mapStyles[0]);
  const isFullScreen = useIsFullScreen();

  const filterNameExtractor = useCallback(
    (field: MapFilterControl | string | undefined, isSubfield?: boolean) =>
      t(
        isSubfield
          ? `portfolio.risk.types.${field}`
          : `portfolio.map.filters.${field}`
      ),
    [t]
  );

  return (
    <MapGL
      {...DEFAULT_MAP_PROPS}
      onRender={siteMap.onRender}
      mapStyle={mapStyle.style}
      interactiveLayerIds={['unclustered-point']}
      onClick={siteMap.onClick}
      ref={siteMap.mapRef}
      attributionControl={false}
      onMouseEnter={siteMap.onMouseEnter}
      onMouseLeave={siteMap.onMouseLeave}
      style={{ position: isFullScreen ? 'absolute' : 'relative' }}
    >
      <Source
        key={siteMap.clusters !== null ? 'clusters-source' : 'point-source'}
        id="locations"
        type="geojson"
        generateId
        data={siteMap.locationsSource}
        cluster={siteMap.clusters !== null}
        clusterRadius={80}
        clusterMaxZoom={14}
        clusterProperties={clusterProperties}
      >
        <Layer
          {...unclusteredPointLayer(
            siteMap.selectedFilter,
            totalValue,
            data.length
          )}
        />
        {siteMap.clusters === null && (
          <Layer {...valueHeatmapLayer(totalValue, data.length)} />
        )}
      </Source>

      {siteMap.clusters !== null &&
        Object.values(siteMap.clusters).map((element) => (
          <MapCluster
            clusterId={element?.properties?.cluster_id}
            key={element.id}
            //@ts-ignore
            latitude={element.geometry.coordinates[1]}
            //@ts-ignore
            longitude={element.geometry.coordinates[0]}
            count={element.properties?.point_count}
            size={range(
              0,
              totalValue / 2,
              40,
              200,
              element?.properties?.siteValue
            )}
            onClick={siteMap.onClusterClick}
            clusterValue={element?.properties?.siteValue}
            data={siteMap.buildMarkerData(element)}
          />
        ))}

      {isSmall ? (
        <>
          <MapFilterHintControl
            nameExtractor={filterNameExtractor}
            filter={siteMap.selectedFilter}
            onOpen={() => setSettingsOpen(true)}
          />
          <MapSettingsDrawer
            open={settingsOpen}
            onClose={() => setSettingsOpen(false)}
            items={filters}
            nameExtractor={filterNameExtractor}
            selected={siteMap.selectedFilter}
            onItemChange={siteMap.setSelectedFilter}
            selectedMapStyle={mapStyle}
            mapStyles={mapStyles}
            onChangeMap={onChangeMap}
            titleMapStyle={t('portfolio.map.legend.map-type').toUpperCase()}
            titleModeSelection={t(
              'portfolio.map.legend.mode-selection'
            ).toUpperCase()}
            buttonCloseTitle={t('portfolio.map.close')}
          />
          {isMapScaleFilter(siteMap.selectedFilter) && (
            <MapLegendMobile
              open={legendMobileOpen}
              onClose={() => setLegendMobileOpen(false)}
              selected={siteMap.selectedFilter}
              title={t('portfolio.map.legend.title').toUpperCase()}
              buttonCloseTitle={t('portfolio.map.close')}
              selectedField={t(
                `portfolio.map.filters.${
                  siteMap.selectedFilter.parentField
                    ? siteMap.selectedFilter.parentField
                    : siteMap.selectedFilter.field
                }`
              ).toUpperCase()}
            />
          )}
          <MapStyleLegendButtonsControl
            onOpenMapStyle={() => setSettingsOpen(true)}
            onOpenLegend={() => setLegendMobileOpen(true)}
            displayLegend={isMapScaleFilter(siteMap.selectedFilter)}
          />
          {siteMap.selectedLocation && siteMap.selectedLocation.length > 0 && (
            <MapSiteDrawer
              open={!!siteMap.selectedLocation}
              onClose={siteMap.unselectLocation}
              site={siteMap.selectedLocation[0]}
            />
          )}
          <MapMenuControl onOpen={toggleDrawer} />
        </>
      ) : (
        <>
          <ZoomControl />
          <FullscreenControl position="bottom-left" />
          <MapFiltersControl
            items={filters}
            nameExtractor={filterNameExtractor}
            selected={siteMap.selectedFilter}
            onItemChange={siteMap.setSelectedFilter}
          />

          {isMapScaleFilter(siteMap.selectedFilter) && (
            <MapLegend
              ref={siteMap.mapLegendRef}
              items={siteMap.selectedFilter.scale}
              limit={siteMap.selectedFilter.legend}
              title={t('portfolio.map.legend.title')}
            />
          )}
          <MapStyleControl
            selectedMapStyle={mapStyle}
            mapStyles={mapStyles}
            onChangeMap={onChangeMap}
            title={t('portfolio.map.legend.map-type')}
          />
          {siteMap.selectedLocation && siteMap.selectedLocation.length > 0 && (
            <MapSitePopup
              onClose={siteMap.unselectLocation}
              sites={siteMap.selectedLocation}
              anchor={isSmall ? 'bottom' : 'left'}
              companyCurrency={companyCurrency}
            />
          )}
        </>
      )}
      <MapBackControl
        title={t('navigation.sites')}
        redirection="../portfolio?sort=summary"
      />
      <MapFooterAttributes
        riskSources={t('footer.risk-attributes-map')}
        firstRiskSourcesMobile={t('footer.risk-attributes-map-munich')}
        secondRiskSourcesMobile={t('footer.risk-attributes-map-flood')}
        isSmallFormat={isMobile}
        openModal={() => setIsOpenModalAttributes(true)}
      />
      <MapAttributesModal
        isOpen={isOpenModalAttributes && isMobile}
        onClose={() => setIsOpenModalAttributes(false)}
        title={t('footer.risk-attributes-title-modal')}
      />
    </MapGL>
  );
};

export default GlobalMap;
