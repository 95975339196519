import React from 'react';
import { MapViewProps } from './MapView.types';
import {
  BorderSection,
  DescriptionContainer,
  RightSectionContainer,
  MapImage,
  SectionContainer,
  TitleContainer,
  ButtonRedirectionMap,
} from './MapView.styles';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

const MapView: React.FC<MapViewProps> = ({
  title,
  description,
  titleButton,
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return (
    <SectionContainer>
      {!isSmall && <MapImage src={'/map-view.png'} />}
      <RightSectionContainer>
        <TitleContainer>
          <Typography variant="d6">{title}</Typography>
        </TitleContainer>
        <DescriptionContainer>
          <Typography variant="paragraphMedium">{description}</Typography>
        </DescriptionContainer>
        {!isSmall && <BorderSection />}
        <ButtonRedirectionMap
          id="portfolio-button-map"
          variant="contained"
          href="/portfolio/map"
          endIcon={<ArrowForward />}
        >
          {titleButton}
        </ButtonRedirectionMap>
      </RightSectionContainer>
    </SectionContainer>
  );
};

export default MapView;
