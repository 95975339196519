import { FlexColumnBox } from '@components/templates';
import {
  Box,
  Paper,
  Typography,
  styled,
  Button,
  Skeleton,
} from '@mui/material';

export const EventContainer = styled(Paper)(({ theme, color = '#343C3D' }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  borderLeft: `4px solid ${color}`,
  boxSizing: 'content-box',
  padding: theme.spacing(2, 2, 2, 1.5),
}));
export const LiveEventItemContainer = styled(FlexColumnBox)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 2),
  flex: 1,
  gap: theme.spacing(1),
}));

export const LiveEventLabelContainer = styled(Box)(() => ({
  alignSelf: 'flex-end',
}));

export const TitleEvent = styled(Typography)(() => ({
  flex: 1,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const ReviewButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-end',
  padding: 0,
  minHeight: 'inherit',
  [theme.breakpoints.only('mobile')]: {
    display: 'none',
  },
}));

export const ReviewButtonSkeleton = styled(Skeleton)(({ theme }) => ({
  alignSelf: 'flex-end',
  padding: 0,
  minHeight: 'inherit',
  [theme.breakpoints.only('mobile')]: {
    display: 'none',
  },
  width: 50,
}));
