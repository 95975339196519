import { FlexBox } from '@components/templates';
import {
  styled,
  Drawer,
  drawerClasses,
  Chip,
  Accordion,
  accordionSummaryClasses,
  AccordionDetails,
  Divider,
  chipClasses,
  Box,
} from '@mui/material';

export const SettingsHeader = styled(FlexBox)`
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  height: 16px;
`;

export const TitleDivider = styled(Divider)(({ theme }) => ({
  maxWidth: 300,
  width: '95%',
  margin: theme.spacing(1, 0),
}));

export const MapStylesContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 0, 2.5, 0),
}));

export const SettingsDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${drawerClasses.paper}`]: {
    padding: theme.spacing(2, 2.5),
    alignItems: 'flex-start',
  },
}));

export const ChipNoBorder = styled(Chip)(({ isSelected }) => ({
  borderWidth: 0,
  color: isSelected ? '#3D3DAA' : '#343C3D',
  [`& .${chipClasses.icon}`]: { marginLeft: 0, fontSize: 14 },
}));

export const AccordionOutlined = styled(Accordion)(() => ({
  ['&:before']: {
    content: 'none',
  },
  [`& .${accordionSummaryClasses.root}`]: {
    padding: 0,
    minHeight: 0,
  },
  [`& .${accordionSummaryClasses.root} .${accordionSummaryClasses.content}`]: {
    margin: 0,
  },
  [`& .${accordionSummaryClasses.root} .${accordionSummaryClasses.expandIconWrapper}`]:
    {
      display: 'none',
    },
  [`& .${accordionSummaryClasses.root} .${accordionSummaryClasses.expandIconWrapper}`]:
    {
      display: 'none',
    },
}));

AccordionOutlined.defaultProps = {
  elevation: 0,
};

export const AccordionDetailsOutlined = styled(AccordionDetails)`
  align-items: flex-start;
  padding: 0 16px;
`;
