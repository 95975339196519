import { Box, styled } from '@mui/material';

export const RiskGradeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 0,
  [theme.breakpoints.up('tablet')]: {
    alignSelf: 'flex-start',
  },
  alignItems: 'start',
  justifyContent: 'space-between',
}));
