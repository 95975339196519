import React from 'react';
import { Typography, useMediaQuery, Theme, Skeleton } from '@mui/material';
import { HorizontalScrollView } from '@components/templates/HorizontalScrollView';
import { BoxSection, ColoredBox } from '@components/templates';
import { ExpandableGrid } from '@components/templates';
import { RegularBreakpointsColumn } from '@components/templates/ExpandableGrid/ExpandableGrid.types';

const SectionTitleSkeleton = () => (
  <>
    <Typography variant="d6">
      <Skeleton width={250} />
    </Typography>
    <Typography variant="paragraph">
      <Skeleton width={300} />
    </Typography>
  </>
);

const ResourcesSectionSkeleton = ({
  renderItem,
  color = 'paper',
  breakpointsColumn = {
    mobile: 1,
    tablet: 2,
    desktop: 3,
  },
  items = ['1', '2', '3'],
}: {
  color?: string;
  renderItem: (key: string) => React.ReactNode;
  breakpointsColumn?: RegularBreakpointsColumn;
  items?: string[];
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return isSmall ? (
    <ColoredBox color={color}>
      <BoxSection color={color}>
        <SectionTitleSkeleton />
      </BoxSection>
      <HorizontalScrollView
        itemAutoWidth
        spacing={2}
        marginSpacing={2.5}
        keyExtractor={(item) => item}
        items={items}
        renderItem={renderItem}
      />
    </ColoredBox>
  ) : (
    <BoxSection color={color}>
      <SectionTitleSkeleton />
      <ExpandableGrid
        items={items}
        renderItem={renderItem}
        isLoading={true}
        {...breakpointsColumn}
      />
    </BoxSection>
  );
};

export default ResourcesSectionSkeleton;
