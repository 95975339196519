import { Event } from '@modules/types/events.types';

// Todo: handle timezone
export function getDateFormatDDMMYYYY(date: Date) {
  if (!date) {
    return '';
  }
  let day =
    new Date(date).getDate() > 9
      ? new Date(date).getDate().toString()
      : '0' + new Date(date).getDate().toString();
  let month =
    new Date(date).getMonth() + 1 > 9
      ? (new Date(date).getMonth() + 1).toString()
      : '0' + (new Date(date).getMonth() + 1).toString();
  let year = new Date(date).getFullYear().toString();

  return day + '/' + month + '/' + year;
}

export function getStartedDate(
  event: Event
): { label: string; value: string } | null {
  return event.startedAt
    ? {
        label: 'Date started',
        value: getDateFormatDDMMYYYY(new Date(event.startedAt)),
      }
    : null;
}

export function getMonitoringEndDate(event: Event) {
  return event.status !== 'active' && event.publicationDate
    ? {
        label: 'Monitoring ended',
        value: getDateFormatDDMMYYYY(new Date(event.publicationDate)),
      }
    : null;
}

export function getMainEventDate(event: Event) {
  return getMonitoringEndDate(event) || getStartedDate(event);
}
