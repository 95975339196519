import React from 'react';
import {
  Skeleton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { TitleIconResponsiveProps } from './TitleIconResponsive.types';
import { SectionIcon } from './TitleIconResponsive.styles';

export default function TitleIconResponsiveSkeleton({
  src,
  alt,
  children,
  marginIcon,
  variant = 'head',
  ...props
}: TitleIconResponsiveProps) {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );

  return (
    <Stack alignItems="center" direction="row" spacing={0.5} {...props}>
      <Skeleton>
        <SectionIcon
          marginIcon={marginIcon}
          variant={variant}
          src={src}
          alt={alt}
        />
      </Skeleton>
      <Typography
        variant={
          variant === 'display'
            ? isSmall
              ? 'd6'
              : 'd4'
            : isSmall
            ? 'h2'
            : 'h1'
        }
      >
        {children}
      </Typography>
    </Stack>
  );
}
