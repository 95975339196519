import { PaperPage } from '@components/templates';
import { styled } from '@mui/material';

export const SectionsContainer = styled(PaperPage)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.only('mobile')]: {
    gap: theme.spacing(2),
  },
  gap: theme.spacing(5),
}));
