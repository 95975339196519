import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { ContactProps } from './Contact.types';

export default function Contact({
  id,
  name,
  size = 'medium',
  role,
  ...props
}: ContactProps) {
  return (
    <Stack
      key={`contact-${id}`}
      direction="row"
      sx={{ alignItems: 'center', gap: 1, paddingRight: 0.5 }}
      {...props}
    >
      <Avatar
        className={size}
        id="risk-coach-avatar"
        alt={name}
        src={'/avatar.png'}
      />
      <Stack spacing={0}>
        <Typography
          id="risk-coach-name"
          variant={size === 'large' ? 'h1' : 'h2'}
        >
          {name}
        </Typography>
        <Typography
          id="risk-coach-role"
          variant={size === 'large' ? 'lead' : 'paragraphSmall'}
        >
          {role}
        </Typography>
      </Stack>
    </Stack>
  );
}
