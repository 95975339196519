import { FlexColumnBox } from '@components/templates';
import { Box, Button, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const SquareContainer = styled(FlexColumnBox)(({ theme }) => ({
  padding: theme.spacing(2.5, 2.5, 3.25, 2.5),
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(2.5, 2.5, 2.25, 2.5),
  },
  boxShadow: theme.shadows[1],
  gap: 16,
}));

export const TitleContainer = styled(Typography)(() => ({
  marginBottom: 8,
  textTransform: 'uppercase',
}));

export const Separator = styled(Box)(() => ({
  borderBottom: `1px solid ${grey[400]}`,
}));

export const RedirectionButton = styled(Button)(() => ({
  alignSelf: 'end',
}));
