import { Box, Button, Stack, styled, Theme, Typography } from '@mui/material';
export const RedLine = styled(Box)`
  background-color: ${({ theme }: { theme: Theme }) =>
    theme.palette.danger.main};
  width: 32px;
  height: 4px;
`;

RedLine.defaultProps = { mb: 1 };

export const TopSectionStack = styled(Stack)(
  ({ theme, $marginTop = false }) => ({
    justifyContent: 'space-between',
    [theme.breakpoints.only('mobile')]: {
      marginTop: theme.spacing($marginTop ? 3 : 0),
    },
    [theme.breakpoints.up('tablet')]: {
      marginTop: theme.spacing($marginTop ? 4 : 0),
    },
  })
);
`
  justify-content: space-between;
`;

TopSectionStack.defaultProps = { direction: 'row' };

export const BackButton = styled(Button)(({ theme, $noMargin }) => ({
  padding: 0,
  margin: theme.spacing(!$noMargin ? 1 : 0, 0),
}));

export const Title = styled(Typography)`
  display: block;
  white-space: pre-line;
  overflow-wrap: anywhere;
  max-width: 420px;
  margin-top: ${({ headline }: { headline: boolean }) => (headline ? 0 : 2)}px;
`;
