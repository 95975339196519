import { RefObject, useEffect, useMemo, useState } from 'react';
import { MapRef, ViewStateChangeEvent } from 'react-map-gl';
import { useNavigate, useParams } from 'react-router-dom';
import { Event, EventList } from '@modules/types/events.types';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import { UUID } from '@modules/types';
import { debounce } from '@mui/material';

export const useEventState = (events?: EventList) => {
  const params = useParams();
  const navigate = useNavigate();
  const setSelectedEvent = (id?: UUID) => {
    navigate(`/events${id ? '/' + id : ''}`);
  };

  const selectedEvent = events?.items?.find((event) => event.id === params.id);
  return {
    selectedEvent,
    setSelectedEvent,
  };
};

export const useEventMap = (
  mapRef: RefObject<MapRef>,
  events?: EventList,
  selectedEvent?: Event
) => {
  const [visibleEvents, setVisibleEvents] = useState<UUID[]>([]);

  const eventsToDisplayFootprint = useMemo(
    () =>
      selectedEvent
        ? visibleEvents.includes(selectedEvent.id)
          ? visibleEvents
          : visibleEvents.concat(selectedEvent.id)
        : visibleEvents,
    [selectedEvent, visibleEvents]
  );

  const onMove = debounce((event: ViewStateChangeEvent) => {
    const zoomLevel = event.viewState.zoom;
    const map = mapRef.current?.getMap();
    if (map && events && zoomLevel > 3) {
      const bounds = new LngLatBounds([
        event.viewState.longitude - 9,
        event.viewState.latitude - 9,
        event.viewState.longitude + 9,
        event.viewState.latitude + 9,
      ]);
      const visibles = events.items
        .filter(
          (event) =>
            event.status === 'active' &&
            bounds.contains([event.longitude, event.latitude])
        )
        .map((event) => event.id);
      setVisibleEvents(visibles);
    } else {
      setVisibleEvents([]);
    }
  }, 300);

  const zoomOnEvent = (
    event: Event,
    zoomMinLevel: number,
    area?: GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon>,
    keepActualZoom?: boolean
  ) => {
    const zoomOnPoint = () => {
      mapRef.current?.easeTo({
        center: { lon: event.longitude, lat: event.latitude },
        zoom: keepActualZoom
          ? Math.max(zoomMinLevel, mapRef.current.getZoom())
          : zoomMinLevel,
        duration: 500,
      });
    };
    const zoomOnFootprint = (
      event: Event,
      footprint: GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon>
    ) => {
      const bounds = new mapboxgl.LngLatBounds();
      footprint.geometry.coordinates
        .flat(footprint.geometry.coordinates.length > 1 ? 2 : 1)
        .concat([[event?.longitude, event?.latitude]])
        .forEach((coordinate) => {
          bounds.extend(coordinate as [number, number]);
        });

      mapRef.current?.fitBounds(bounds, { padding: 200 });
    };
    if (area) {
      try {
        zoomOnFootprint(event, area);
      } catch (e) {
        zoomOnPoint();
      }
    } else {
      zoomOnPoint();
    }
  };

  const onZoomIn =
    (zoomMinLevel: number, keepActualZoom?: boolean) => (event?: Event) => {
      if (mapRef.current && event && event?.longitude && event?.latitude) {
        zoomOnEvent(event, zoomMinLevel, undefined, keepActualZoom);
      }
    };

  const onZoomOnFootprint =
    (zoomMinLevel: number, keepActualZoom?: boolean) =>
    (
      event?: Event,
      areaOfInterest?: GeoJSON.Feature<GeoJSON.MultiPolygon | GeoJSON.Polygon>
    ) => {
      if (mapRef.current && event && event?.longitude && event?.latitude) {
        zoomOnEvent(event, zoomMinLevel, areaOfInterest, keepActualZoom);
      }
    };

  useEffect(() => {
    onZoomIn(2.5, true)(selectedEvent);
  }, [mapRef.current, selectedEvent]);

  return {
    onMove,
    onZoomOnFootprint,
    eventsToDisplayFootprint,
  };
};
