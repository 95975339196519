import { Layers, LegendToggle } from '@mui/icons-material';
import React from 'react';
import {
  ButtonsContainer,
  IconButtonControl,
} from './MapStyleLegendButtonsControl.styles';
import { MapStyleLegendButtonsControlProps } from './MapStyleLegendButtonsControl.types';

const MapStyleLegendButtonsControl: React.FC<
  MapStyleLegendButtonsControlProps
> = ({ onOpenMapStyle, onOpenLegend, displayLegend }) => {
  return (
    <ButtonsContainer>
      <IconButtonControl
        id="map-style"
        aria-label="map-style"
        onClick={onOpenMapStyle}
        startIcon={<Layers />}
        variant={'contained-unchecked'}
      />
      {displayLegend && (
        <IconButtonControl
          id="map-legend"
          aria-label="map-legend"
          onClick={onOpenLegend}
          startIcon={<LegendToggle />}
          variant={'contained-unchecked'}
        />
      )}
    </ButtonsContainer>
  );
};

export default MapStyleLegendButtonsControl;
