import React, { ComponentType } from 'react';
import ErrorBoundary from '@pages/GlobalError';

export const withErrorBoundary = <P extends Record<string, object>>(
  WrappedComponent: ComponentType<P>
) => {
  // Using a functional component rather than directly returning the JSX allows React to diff correctly.
  const HOC: React.FC<P> = (props) => {
    return (
      <ErrorBoundary>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };
  HOC.displayName = `WithErrorBoundary(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;
  return HOC;
};
