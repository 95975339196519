// import { ArrowForward } from '@mui/icons-material';
import { ArrowForward } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { currencyFormat } from '@utils/numbers';
import React from 'react';
import { InfoItemContainer } from '../InfoDetails/InfoDetails.styles';
import { ListItemButtonContainer, ListItemSeparator } from './SiteItem.styles';
import { SiteItemProps } from './SiteItem.types';

const SiteItem: React.FC<SiteItemProps> = ({
  site,
  companyCurrency,
  onClick,
  isLastItem,
  titleField,
}) => {
  return (
    <>
      <ListItemButtonContainer onClick={onClick}>
        <InfoItemContainer>
          <Typography color="#00008F" variant="paragraphSemi">
            {site.name}
          </Typography>
          <Typography variant="paragraphSmall">
            {titleField}
            {currencyFormat(site.siteValue, companyCurrency)}
          </Typography>
        </InfoItemContainer>
        <ArrowForward sx={{ fontSize: 16, color: '#00008F' }} />
      </ListItemButtonContainer>
      {!isLastItem && <ListItemSeparator />}
    </>
  );
};

export default SiteItem;
