import React from 'react';
import {
  CardActions,
  Button,
  Typography,
  Divider,
  Skeleton,
} from '@mui/material';
import {
  CardContainer,
  CardContentFlex,
  CardIcon,
  ContentContainer,
  TextContainer,
} from './RecommendationCard.styles';
import { getRecommendationImage } from '@pages/Recommendation/Recommendation';

export default function RecommendationCardSkeleton() {
  return (
    <CardContainer>
      <CardContentFlex>
        <Typography mb={2} variant="h3" color="#015A66">
          <Skeleton width={150} />
        </Typography>
        <ContentContainer>
          <Skeleton sx={{ marginRight: 1 }}>
            <CardIcon src={getRecommendationImage('portfolio-risk')} />
          </Skeleton>
          <TextContainer>
            <Typography variant="h3" sx={{ wordBreak: 'break-word' }}>
              <Skeleton width={150} />
            </Typography>
            <Typography variant="paragraphSmall">
              <Skeleton width={250} />
            </Typography>
            <Typography variant="paragraphSmall">
              <Skeleton />
            </Typography>
            <Typography variant="paragraphSmall">
              <Skeleton width={250} />
            </Typography>
          </TextContainer>
        </ContentContainer>
      </CardContentFlex>
      <Divider />
      <CardActions>
        <Skeleton>
          <Button id="button-recommendation" size="small">
            get started
          </Button>
        </Skeleton>
      </CardActions>
    </CardContainer>
  );
}
