import { IMPROVE_MAP_LINK, MAPBOX_LINK, OSM_LINK } from '@utils/urls';

export const mapBoxData = {
  title: 'Mapbox',
  link: MAPBOX_LINK,
};

export const OSMData = {
  title: 'OpenStreetMap',
  link: OSM_LINK,
};

export const improveMapData = {
  title: 'Improve the map',
  link: IMPROVE_MAP_LINK,
};
