import { Box, Typography, styled } from '@mui/material';

export const SectionIcon = styled('img')``;

export const LabelContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

export const LabelText = styled(Typography)({
  color: '#00008F',
  marginRight: 10,
});
