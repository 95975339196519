import { NAV_BAR_MARGIN } from '@components/organisms/navigation/NavBar/NavBar.styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  styled,
} from '@mui/material';

export const AccordionMapStyle = styled(Accordion)`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  align-items: flex-start;
  position: absolute;
  bottom: 22px;
  box-shadow: none;
  left: ${NAV_BAR_MARGIN};
  &:before {
    opacity: 0;
  }
`;

export const AccordionTitle = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: 'row-reverse',
  backgroundColor: 'white',
  minHeight: 32,
  borderBottom: `3px solid #f0f0f0`,
  [`& .${accordionSummaryClasses.content}`]: {
    justifyContent: 'center',
    textTransform: 'uppercase',
    margin: theme.spacing(0, 0, 0, 1),
  },
}));

export const AccordionDetailsContainer = styled(AccordionDetails)(
  ({ theme }) => ({
    height: 96,
    padding: theme.spacing(2, 1.75, 2, 1.75),
    backgroundColor: '#FFFFFF',
  })
);
