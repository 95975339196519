import React from 'react';
import { ArrowButtonSmallProps } from './ArrowButtonSmall.types';

import { ArrowRight } from '@components/atoms/Icons/icons';
import { ButtonBaseProps, Typography } from '@mui/material';
import { LeftIcon, StyledButton, Title } from './ArrowButtonSmall.styles';

const ArrowButtonSmall: React.FC<ArrowButtonSmallProps & ButtonBaseProps> = ({
  icon,
  alt,
  title,
  ...props
}) => {
  return (
    <StyledButton {...props}>
      <Title>
        <LeftIcon src={icon} alt={alt} />
        <Typography variant="h5" color="primary">
          {title.toUpperCase()}
        </Typography>
      </Title>
      <ArrowRight />
    </StyledButton>
  );
};

export default ArrowButtonSmall;
