import React from 'react';
import { CardActions, Button, Typography, Divider } from '@mui/material';
import {
  CardContainer,
  CardContentFlex,
  CardIcon,
  DisabledOverlay,
  TextContainer,
} from './PlaybookCard.styles';
import { PlaybookCardProps } from './PlaybookCard.types';
import { Playbook } from '@modules/types';
import { Launch } from '@mui/icons-material';
import { sanitizeUrl } from '@braintree/sanitize-url';

const getThemeColor = (category?: string) => {
  switch (category?.toLowerCase()) {
    case 'human element':
      return '#8A7333';
    case 'natural hazard':
      return '#914146';
    case 'guidance':
      return '#4976BA';
    case 'loos prevention':
      return '#5B7B73';
  }
  return '#00008f';
};

export default function PlaybookCard({
  title,
  categories,
  image,
  url,
  disabled,
  playbookAvailableButtonTitle,
  playbookUnavailableButtonTitle,
}: PlaybookCardProps & Playbook) {
  return (
    <CardContainer id="playbook-card">
      <CardContentFlex>
        <TextContainer disabled={disabled}>
          <Typography
            id="playbook-card-category"
            variant="h3"
            color={getThemeColor(categories[0]?.name)}
          >
            {categories.map((category) => category?.name?.toUpperCase())}
          </Typography>
          <Typography
            id="playbook-card-title"
            variant="h1"
            sx={{ wordBreak: 'break-word' }}
          >
            {title}
          </Typography>
        </TextContainer>

        {disabled && <DisabledOverlay />}
        <CardIcon
          src={
            /*TODO : remove mock url*/ `${
              image?.url?.startsWith('/documents')
                ? process.env.REACT_APP_PORTAL_API_BASE_URL
                : ''
            }${image?.url}`
          }
          alt={image.description}
        />
      </CardContentFlex>
      <Divider />
      <CardActions
        sx={{ justifyContent: disabled ? 'flex-end' : 'flex-start' }}
      >
        {!disabled ? (
          <Button
            id="playbook-button-read"
            target="_blank"
            href={sanitizeUrl(
              `${
                !url?.startsWith('http')
                  ? process.env.REACT_APP_PORTAL_API_BASE_URL
                  : ''
              }${url}`
            )}
            size="small"
            endIcon={<Launch />}
          >
            {playbookAvailableButtonTitle}
          </Button>
        ) : (
          <Button
            id="playbook-button-disabled"
            size="small"
            sx={{ color: '#999999', pointerEvents: 'none' }}
          >
            {playbookUnavailableButtonTitle}
          </Button>
        )}
      </CardActions>
    </CardContainer>
  );
}
