import React from 'react';
import { MapFooterAttributesProps } from './MapFooterAttributes.types';
import { Link, Typography, useTheme } from '@mui/material';
import {
  FooterContainer,
  InfoLogo,
  LogosContainer,
  MapBoxLogo,
  MobileFooterContainer,
} from './MapFooterAttributes.styles';
import { OSMData, improveMapData, mapBoxData } from './datadef';

const MapFooterAttributes: React.FC<MapFooterAttributesProps> = ({
  isSmallFormat,
  riskSources,
  firstRiskSourcesMobile,
  secondRiskSourcesMobile,
  openModal,
}) => {
  const theme = useTheme();
  return (
    <FooterContainer isSmallFormat={isSmallFormat && firstRiskSourcesMobile}>
      {!isSmallFormat || !firstRiskSourcesMobile ? (
        <Typography variant="paragraphSmall">
          <Link
            underline="none"
            target="_blank"
            href={mapBoxData.link}
            color={theme.palette.text.primary}
          >
            {mapBoxData.title}
          </Link>
          {' © '}
          <Link
            underline="none"
            target="_blank"
            href={OSMData.link}
            color={theme.palette.text.primary}
          >
            {OSMData.title}
          </Link>{' '}
          <Link href={improveMapData.link} target="_blank">
            {improveMapData.title}
          </Link>
          <MapBoxLogo src="/mapbox-logo.svg" />
          {riskSources && '| ' + riskSources}
        </Typography>
      ) : (
        <MobileFooterContainer>
          <LogosContainer>
            <MapBoxLogo src="/mapbox-logo.svg" />
            <InfoLogo onClick={openModal} src="/info-map-attributes.svg" />
          </LogosContainer>
          <Typography variant="miniMobile" sx={{ whiteSpace: 'nowrap' }}>
            {firstRiskSourcesMobile}
            <br />
            {secondRiskSourcesMobile}
          </Typography>
        </MobileFooterContainer>
      )}
    </FooterContainer>
  );
};

export default MapFooterAttributes;
