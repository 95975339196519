import { Box, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const SquareContainer = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[1],
}));

export const TitleContainer = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: grey[100],
}));

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  whiteSpace: 'pre-line',
}));
