import { FlexBox } from '@components/templates';
import { Typography, styled } from '@mui/material';

export const StartPanel = styled(FlexBox)(({ theme }) => ({
  position: 'absolute',
  pointerEvents: 'none',
  cursor: 'none',
  [theme.breakpoints.only('desktop')]: {
    top: 0,
    left: 0,
    bottom: 0,
  },
  [theme.breakpoints.not('desktop')]: {
    left: 0,
    bottom: 0,
    right: 0,
  },
  [theme.breakpoints.only('mobile')]: {
    height: 90,
  },
  [theme.breakpoints.only('tablet')]: {
    height: 98,
  },
}));

export const PopupDelineationContainer = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));
