import React from 'react';
import { ModalGeocodingExplanationProps } from './ModalGeocodingExplanation.types';
import { ModalContainer } from '@components/organisms/ModalContainer';
import { Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  GeocodingDesktopImage,
  GeocodingMobileImage,
  GroupSectionContainer,
  ImageSectionContainer,
  ModalGeocodingContainer,
  SectionContainer,
  SquareContainer,
} from './ModalGeocodingExplanation.styles';
import { useTranslation } from 'react-i18next';

const ModalGeocodingExplanation: React.FC<ModalGeocodingExplanationProps> = ({
  isOpen,
  closeText,
  onClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  const paragraphTypo = isSmall ? 'paragraph' : 'leadSemi';
  return (
    <ModalContainer
      isOpen={isOpen}
      closeText={closeText}
      onClose={onClose}
      totalModalWidth={'75%'}
      title={t('portfolio.geocoding.title_modal')}
    >
      <ModalGeocodingContainer>
        <SectionContainer>
          <Typography variant={'d6'}>
            {t('portfolio.geocoding.title')}
          </Typography>
          <Typography variant={'h1'}>
            {t('portfolio.geocoding.geocoding_system')}
          </Typography>
        </SectionContainer>
        <Typography variant={paragraphTypo}>
          {t('portfolio.geocoding.introduction')}
        </Typography>
        <ImageSectionContainer>
          {isSmall ? (
            <GeocodingMobileImage
              src="/geocoding-mobile.jpg"
              alt="geocoding quality mobile"
            />
          ) : (
            <GeocodingDesktopImage
              src="/geocoding-desktop.jpg"
              alt="geocoding quality desktop"
            />
          )}
          <GroupSectionContainer>
            <SectionContainer>
              <Typography variant={'h2'}>
                {t('portfolio.geocoding.why_geocoding')}
              </Typography>
              <Typography variant={paragraphTypo}>
                {t('portfolio.geocoding.geocoding_quality')}
              </Typography>
            </SectionContainer>
            <Typography
              variant={paragraphTypo}
              color={theme.palette.primary.main}
            >
              {t('portfolio.geocoding.convertion')}
            </Typography>
            <Typography variant={paragraphTypo}>
              {t('portfolio.geocoding.transform')}
            </Typography>
            <Typography variant={paragraphTypo}>
              {t('portfolio.geocoding.system')}
            </Typography>
            <SectionContainer>
              <Typography variant={'h2'}>
                {t('portfolio.geocoding.human_factor')}
              </Typography>
              <Typography variant={paragraphTypo}>
                {t('portfolio.geocoding.complexity')}
              </Typography>
            </SectionContainer>
          </GroupSectionContainer>
        </ImageSectionContainer>
        <SquareContainer>
          <Typography variant={'h2'} color={theme.palette.primary.main}>
            {t('portfolio.geocoding.need_your_help')}
          </Typography>
          <Typography variant={paragraphTypo}>
            {t('portfolio.geocoding.accurate_data')}
          </Typography>
        </SquareContainer>
      </ModalGeocodingContainer>
    </ModalContainer>
  );
};

export default ModalGeocodingExplanation;
