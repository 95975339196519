import React from 'react';
import { Typography } from '@mui/material';
import { LiveEventLabel } from '@components/atoms/LiveEventLabel';
import { EventStatusProps } from './EventStatus.types';

const EventStatus: React.FC<EventStatusProps> = ({ status, ...props }) => {
  return status !== 'active' ? (
    <Typography variant="h5" {...props}>
      PAST EVENT
    </Typography>
  ) : (
    <LiveEventLabel label="Live event" {...props} />
  );
};

export default EventStatus;
