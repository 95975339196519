export const IS_PRODUCTION = !(
  process.env.REACT_APP_ENV?.length !== undefined &&
  process.env.REACT_APP_ENV.length > 0 &&
  !process.env.REACT_APP_ENV?.startsWith('$(') &&
  !process.env.REACT_APP_ENV?.startsWith('(')
); //TODO: Remove $(REACT-APP-ENV) empty azure pipeline workaround
export const SMART_SERVICES_HOST = process.env.REACT_APP_PORTAL_API_BASE_URL;
export const SMART_SERVICES_COOKIES = `${SMART_SERVICES_HOST}/cookie-policy`;
export const SMART_SERVICES_PRIVACY = `${SMART_SERVICES_HOST}/privacy-policy`;
export const SMART_SERVICES_SUPPORT = `${SMART_SERVICES_HOST}/group/hub/support`;
export const SMART_SERVICES_FIXED_ASSETS = `${SMART_SERVICES_HOST}/smartsite`;
export const SMART_SERVICES_LOGOUT = `${SMART_SERVICES_HOST}/c/portal/logout`;
export const SMART_SERVICES_PRIVATE = `${SMART_SERVICES_HOST}/group/hub`;
export const SMART_SERVICES_SOURCE_MUNICH =
  'https://www.munichre.com/rmp/en/products/location-risk-intelligence/natural-hazards-edition.html';
export const SMART_SERVICES_SOURCE_JBA = 'https://www.jbaconsulting.com/';
export const SMART_SERVICES_RISK_SCANNING =
  'https://riskscanning.axaxl.com/surveys ';
export const MAPBOX_LINK = 'https://www.mapbox.com/about/maps';
export const OSM_LINK = 'https://www.openstreetmap.org/about';
export const IMPROVE_MAP_LINK = 'https://www.mapbox.com/map-feedback/';
