import React from 'react';
import { GradientSection } from '@components/templates/layouts/containers';
import { PaperPage } from '@components/templates/layouts/papers';
import { TopSection } from '@components/molecules';
import { TierColumns } from '@components/templates';
import { Skeleton } from '@mui/material';
import PortfolioRiskValueSkeleton from '@components/organisms/portfolio/PortfolioRiskValue/PortfolioRiskValue.loading';
import SiteLinksSkeleton from '@components/organisms/portfolio/SiteLinks/SiteLinks.loading';
import InfoDetailsSkeleton from '@components/molecules/portfolio/InfoDetails/InfoDetail.loading';

const IndividualSiteSkeleton: React.FC<Record<string, never>> = () => {
  return (
    <>
      <GradientSection color="ocean">
        <TopSection
          back
          backText={<Skeleton width={70} />}
          href={'/portfolio'}
          title={<Skeleton width={70} />}
        />
      </GradientSection>
      <TierColumns>
        <Skeleton width={342} height={60} />
        <PaperPage>
          <PortfolioRiskValueSkeleton displayFeedbackLinkSkeleton={true} />
        </PaperPage>
        <PaperPage column={1} sx={{ flex: 1 }}>
          <SiteLinksSkeleton />
        </PaperPage>
        <PaperPage>
          <InfoDetailsSkeleton />
        </PaperPage>
      </TierColumns>
    </>
  );
};

export default IndividualSiteSkeleton;
