import { Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material';

export const NAV_BAR_MARGIN = '7.5%';

export const NavImage = styled('img')(() => ({
  height: 48,
}));

export const ProminentToolBar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'stretch',
  flexDirection: 'column',
  [theme.breakpoints.only('mobile')]: {
    height: 72,
    justifyContent: 'center',
    padding: theme.spacing(1.5, 2.5),
  },
  [theme.breakpoints.only('tablet')]: {
    height: 64,
    justifyContent: 'center',
    padding: theme.spacing(1, 5),
  },
  [theme.breakpoints.only('desktop')]: {
    height: 128,
    padding: `8px ${NAV_BAR_MARGIN} 16px`,
    justifyContent: 'space-between',
  },
}));

export const BrandBar = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ShortcutsBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
  [theme.breakpoints.not('desktop')]: {
    display: 'none',
  },
}));
export const LogoButton = styled(IconButton)`
  padding: 0px;
`;

export const BurgerButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.only('desktop')]: {
    display: 'none',
  },
}));

export const ServiceTitle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  textAlign: 'end',
  [theme.breakpoints.only('mobile')]: {
    whiteSpace: 'pre-wrap',
  },
}));

export const ServiceButton = styled(Button)`
  font-size: 0.875rem;
`;

export const LogoutButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.375, 2),
}));
