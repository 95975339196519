import React from 'react';
import { useMediaQuery, Theme } from '@mui/material';
import { ColumnLarge, ColumnSmall, TierContainer } from './TierColumns.styles';
import { TierColumnsProps } from './TierColumns.types';

const TierColumns: React.FC<TierColumnsProps> = ({ children }) => {
  const isMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('desktop')
  );
  return (
    <TierContainer>
      <ColumnLarge>
        {children?.filter((children) => isMedium || !children?.props.column)}
      </ColumnLarge>
      <ColumnSmall>
        {children?.filter((children) => !isMedium && !!children?.props.column)}
      </ColumnSmall>
    </TierContainer>
  );
};

export default TierColumns;
