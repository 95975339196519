import { ButtonControl } from '@components/atoms/buttons';
import { Box, styled } from '@mui/material';

export const SummaryContainer = styled(ButtonControl)(() => ({}));

export const TextContainer = styled(Box)((/*{ theme }*/) => ({}));

export const SummaryImage = styled('img')(({ theme }) => ({
  marginRight: theme.spacing(2),
}));
