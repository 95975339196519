export enum AlertType {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface TooltipMapMessageProps {
  text: string;
  type?: AlertType;
}
