import { Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorStack } from './ErrorGeneric.styles';
import { ErrorGenericProps } from './ErrorGeneric.types';

const ErrorGeneric: React.FC<ErrorGenericProps> = ({ action }) => {
  const { t } = useTranslation();

  return (
    <ErrorStack spacing={5}>
      <Typography variant="h1">{t('errors.generic.title')}</Typography>
      <Typography variant="paragraph">
        {t('errors.generic.description')}
      </Typography>
      <Button
        id="error-generic-button-refresh"
        href={action ? undefined : '/'}
        variant="contained"
        onClick={() => {
          action && action();
        }}
      >
        {t('errors.generic.action')}
      </Button>
    </ErrorStack>
  );
};

export default ErrorGeneric;
