import React, { useMemo } from 'react';
import { Box, BoxProps, useMediaQuery, Theme } from '@mui/material';
import { ResourcesSectionProps } from './ResourcesSection.types';
import { HorizontalScrollView } from '@components/templates/HorizontalScrollView';
import { BoxSection, ColoredBox } from '@components/templates';
import { ExpandableGrid } from '@components/templates';
import { Identifiable, PartialBy, RenderListProps } from '@modules/types';
import { SectionTitle } from '@components/molecules/resources/SectionTitle';

const ResourcesSection = <T extends Identifiable>({
  icon,
  title = '',
  description,
  items: itemsProp,
  renderItem: renderItemProp,
  keyExtractor: keyExtractorProp = (item) => item?.id,
  leftAction,
  color = 'paper',
  breakpointsColumn = {
    mobile: 1,
    tablet: 2,
    desktop: 3,
  },
  empty,
  titleTooltip,
  descriptionTooltip,
  ...props
}: BoxProps & PartialBy<ResourcesSectionProps<T>, 'keyExtractor'>) => {
  const isEmpty = useMemo(
    () => !!empty && !itemsProp?.length,
    [itemsProp, empty]
  );
  const { items, renderItem, keyExtractor } = useMemo(
    () =>
      isEmpty
        ? { items: [null], renderItem: empty?.renderEmpty, keyExtractorProp }
        : {
            items: itemsProp,
            renderItem: renderItemProp,
            keyExtractor: keyExtractorProp,
          },
    [isEmpty]
  ) as RenderListProps<T>;
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return isSmall ? (
    <ColoredBox color={color} {...props}>
      <BoxSection color={color}>
        <SectionTitle
          title={title}
          icon={icon}
          description={description}
          isEmpty={isEmpty}
          empty={empty}
          titleTooltip={titleTooltip}
          descriptionTooltip={descriptionTooltip}
        />
      </BoxSection>
      <HorizontalScrollView
        itemAutoWidth
        spacing={2}
        marginSpacing={2.5}
        keyExtractor={keyExtractor}
        items={items}
        renderItem={renderItem}
      />
      {!isEmpty && <Box sx={{ paddingLeft: 2 }}>{leftAction}</Box>}
    </ColoredBox>
  ) : (
    <BoxSection color={color} {...props}>
      <SectionTitle
        title={title}
        icon={icon}
        description={description}
        isEmpty={isEmpty}
        empty={empty}
        titleTooltip={titleTooltip}
        descriptionTooltip={descriptionTooltip}
      />
      <ExpandableGrid
        leftAction={!isEmpty && leftAction}
        isEmpty={isEmpty}
        items={items}
        renderItem={renderItem}
        {...breakpointsColumn}
      />
    </BoxSection>
  );
};

export default ResourcesSection;
