import React from 'react';
import { PinMapProps } from './PinMap.types';
import { Typography } from '@mui/material';

import { PinIcon } from '@components/atoms/Icons/icons';
import { Marker } from 'react-map-gl';
import { PinContainer, PinText } from './PinMap.styles';

const PinMap: React.FC<PinMapProps> = ({
  title,
  description,
  latitude,
  longitude,
  height = '54px',
}) => {
  return !!longitude && !!latitude ? (
    <Marker
      longitude={longitude}
      latitude={latitude}
      draggable={false}
      style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        fontSize: height,
        top: 0,
        left: 0,
      }}
    >
      <PinContainer>
        <PinIcon />
        <PinText>
          <Typography variant="h3">{title}</Typography>
          <Typography
            variant="paragraphSmall"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {description}
          </Typography>
        </PinText>
      </PinContainer>
    </Marker>
  ) : null;
};

export default PinMap;
