import { sanitizeUrl } from '@braintree/sanitize-url';
import { matchPath } from 'react-router-dom';

export interface RouteInfo {
  title: string;
  path: string;
  hideFooter?: boolean;
  hideHeader?: boolean;
}

export type RouteDef = {
  [name: string]: RouteInfo;
};

export type NotificationFeatures = { [name: string]: number | undefined };

export function matchPathRouteDef(pathname: string, routes: RouteDef) {
  for (const name in routes) {
    const possibleMatch = matchPath(routes[name].path, pathname);
    if (possibleMatch) {
      return {
        name,
        route: routes[name],
      };
    }
  }
}

export function matchPathPattern(
  pathname: string,
  patterns: readonly string[]
) {
  for (let i = patterns.length - 1; i >= 0; i -= 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return { match: possibleMatch, index: i };
    }
  }

  return null;
}

export function openNewTab(url: string) {
  const newWindow = window.open();
  if (newWindow) {
    newWindow.opener = null;
    newWindow.location = sanitizeUrl(url);
  }
}

export function openNewPdf(url: string) {
  return window.open(url, '_blank');
}
