import React from 'react';
import { PlaybookCard, TopWhiteSection } from '@components/molecules';
import {
  GradientSection,
  Grid1frColumns,
} from '@components/templates/layouts/containers';
import { useTranslation } from 'react-i18next';
import { usePlaybooks } from '@modules/services/services-resources';

import { Typography } from '@mui/material';
import { useGridColumn } from '@utils/dimensions';
import { PageCatalog, PaperInfo } from '@components/templates';
import { PlaybooksContact } from '@components/organisms/contact/PlaybooksContact';
import { useCoach } from '@modules/services/services-company';
import PlaybooksSkeleton from './Playbooks.loading';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';

const Screen: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();

  const { data: playbooks, isError: isPlaybooksError } = usePlaybooks();
  const { data: coach } = useCoach();

  const columns = useGridColumn({ mobile: 1, tablet: 2, desktop: 3 });

  if (!(playbooks || isPlaybooksError)) {
    return <PlaybooksSkeleton />;
  }

  return (
    <>
      <GradientSection
        color={'linear-gradient(102.86deg, #015A66 0.05%, #41949F 101.01%);'}
      >
        <TopWhiteSection
          backText={t('navigation.dashboard')}
          description={t('playbooks.description')}
        >
          {t('app.services.playbooks')}
        </TopWhiteSection>
      </GradientSection>
      <PageCatalog>
        <Typography variant="h1">{t('playbooks.catalog')}</Typography>
        {playbooks !== undefined && playbooks.playbooks.length > 0 && (
          <Grid1frColumns columns={columns}>
            {playbooks?.playbooks?.map((item) => (
              <PlaybookCard
                key={item.id}
                playbookAvailableButtonTitle={t('action.read')}
                playbookUnavailableButtonTitle={t('action.coming-soon')}
                {...item}
              />
            ))}
          </Grid1frColumns>
        )}
        {coach && (
          <PaperInfo sx={{ marginTop: 6 }}>
            <PlaybooksContact
              coach={coach}
              title={t('playbooks.contact.title')}
              description={t('playbooks.contact.description')}
              introduction={t('playbooks.contact.introduction')}
              action={t('dashboard.coach.action')}
            />
          </PaperInfo>
        )}
      </PageCatalog>
    </>
  );
};

export default withErrorBoundary(Screen);
