import { Box, styled } from '@mui/material';

export const BarWrapper = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const BarContainer = styled(Box)`
  flex: 1;
  height: 4px;
  background: #cccccc;
  border-radius: 4px;
  overflow: hidden;
`;

export const Filler = styled(Box)<{ value: number }>`
  height: inherit;
  border-radius: inherit;
  overflow: hidden;
  width: ${({ value }: { value: number }) => value}%;
`;

export const FillerBackground = styled(Box)(({ theme, color, value }) => ({
  height: 'inherit',
  background: theme.palette[color || 'danger'].gradient,
  width: `${100 * (100 / value)}%`,
}));
