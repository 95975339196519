import { NAV_BAR_MARGIN } from '@components/organisms/navigation/NavBar/NavBar.styles';
import { styled } from '@mui/material';
import { ButtonMap } from '../MapFiltersControl/MapFiltersControl.styles';

export const BackButton = styled(ButtonMap)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  margin: theme.spacing(2),
  [theme.breakpoints.only('mobile')]: {
    width: 48,
  },
  [theme.breakpoints.only('desktop')]: {
    margin: theme.spacing(2.25, 0),
    left: NAV_BAR_MARGIN,
  },
}));
