import React from 'react';
import {
  BoxProps,
  MenuItem,
  OutlinedInput,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import {
  ButtonMap,
  FiltersContainer,
  SelectMap,
} from './MapFiltersControl.styles';
import { MapFilter, MapFiltersControlProps } from './MapFiltersControl.types';
import { isMapParentFilter } from '@components/organisms/map/GlobalMap/filters';
import { ExpandMore } from '@mui/icons-material';
import { useMap } from 'react-map-gl';

const MapFiltersControl = ({
  selected,
  items,
  onItemChange,
  nameExtractor,
  ...props
}: MapFiltersControlProps & Omit<BoxProps, 'onChange'>) => {
  const map = useMap();

  const handleItemChange = (event: SelectChangeEvent) => {
    const filter = items.reduce<MapFilter | undefined>((filter, current) => {
      if (filter) return filter;
      if (current.field === event.target.value) return current as MapFilter;
      if (isMapParentFilter(current))
        return current.subfields.find(
          (subfilter) => subfilter.field === event.target.value
        ) as MapFilter;
    }, undefined);

    if (filter) {
      onItemChange(filter);
    }
  };

  return (
    <FiltersContainer {...props}>
      {items.map((item) =>
        !isMapParentFilter(item) ? (
          <ButtonMap
            id={`map-button-filter-${item.field}`}
            key={item.field}
            onClick={() => {
              onItemChange(item);
            }}
            size="small"
            variant={
              selected?.field === item.field
                ? 'contained'
                : 'contained-unchecked'
            }
          >
            {nameExtractor(item.field)}
          </ButtonMap>
        ) : (
          <SelectMap
            id={`map-select-filter-${item.field}`}
            key={item.field}
            displayEmpty
            variant="filled"
            IconComponent={ExpandMore}
            value={selected?.field || ''}
            onChange={handleItemChange}
            MenuProps={{
              container: map.current?.getContainer(),
            }}
            className={
              item.subfields?.find(
                (subfield) => subfield.field === selected?.field
              )
                ? 'Select-checked'
                : undefined
            }
            input={<OutlinedInput />}
            renderValue={(selectedItem: string) => {
              if (
                selectedItem.length === 0 ||
                !item.subfields?.find(
                  (subfield) => subfield.field === selectedItem
                )
              ) {
                return (
                  <Typography variant="paragraphSmall">
                    {nameExtractor(item.field)?.toUpperCase()}
                  </Typography>
                );
              }

              return (
                <Typography variant="paragraphSmall">
                  {nameExtractor(selectedItem, true)}
                </Typography>
              );
            }}
            inputProps={{ 'aria-label': 'Select risk' }}
          >
            <MenuItem disabled value="">
              {nameExtractor(item.field)}
            </MenuItem>
            {item.subfields.map((subfield) => (
              <MenuItem key={subfield.field} value={subfield.field}>
                {nameExtractor(subfield.field, true).toUpperCase()}
              </MenuItem>
            ))}
          </SelectMap>
        )
      )}
    </FiltersContainer>
  );
};
export default MapFiltersControl;
