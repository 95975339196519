import { FlexColumnBox } from '@components/templates';
import { Box, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const SiteContainer = styled(FlexColumnBox)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 2),
}));

export const InfoRiskContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  margin: theme.spacing(1),
  rowGap: theme.spacing(2),
  gridTemplateColumns: '1fr 90px',
}));

export const ButtonExpandableContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 0),
  alignSelf: 'center',
}));

export const SiteSummarySeparator = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${grey[300]}`,
  [theme.breakpoints.not('desktop')]: {
    padding: theme.spacing(1.75, 0, 0, 0),
    margin: theme.spacing(1.75, 0, 0, 0),
  },
  [theme.breakpoints.only('desktop')]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    margin: theme.spacing(0.5, 0, 0, 0),
  },
}));
