import { Box, useTheme } from '@mui/material';
import { getColorsLiveEvent, getColorsLiveEventHover } from '@utils/colors';
import React, { useState } from 'react';
import { Marker } from 'react-map-gl';
import { LiveEventIcon } from '../LiveEventIcon';
import { MapEventMarkerProps } from './MapEventMarker.types';
import {
  NumberOfSites,
  SitesImpactedNotificationContainer,
} from './MapEventMarker.styles';

const MapEventMarker: React.FC<MapEventMarkerProps> = ({
  event,
  onClick,
  isSelected,
  numberSitesImpacted = 0,
  isDisplay = true,
}) => {
  const theme = useTheme();
  const [isHover, setIsHover] = useState<boolean>(false);
  return !!event.longitude && !!event.latitude && isDisplay ? (
    <Marker
      longitude={event.longitude}
      latitude={event.latitude}
      draggable={false}
      onClick={() => onClick && onClick(event.id)}
      style={{
        fontSize: 50,
      }}
    >
      <Box
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <LiveEventIcon
          name={event.category}
          color={
            !isHover
              ? getColorsLiveEvent(
                  theme,
                  event,
                  isSelected || numberSitesImpacted > 0
                )
              : getColorsLiveEventHover(
                  theme,
                  event,
                  isSelected || numberSitesImpacted > 0
                )
          }
          iconColor={
            (isSelected && event.status === 'active') || numberSitesImpacted > 0
              ? theme.palette.neutral.main
              : theme.palette.neutral.contrastText
          }
          shadowLevel={isSelected ? 1 : isHover ? 2 : 1}
        />
        {numberSitesImpacted > 0 && (
          <SitesImpactedNotificationContainer>
            <NumberOfSites
              numberSites={numberSitesImpacted}
              variant="paragraphLarge"
            >
              {numberSitesImpacted}
            </NumberOfSites>
          </SitesImpactedNotificationContainer>
        )}
      </Box>
    </Marker>
  ) : null;
};

export default MapEventMarker;
