import { Items } from '@modules/types';
import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

// eslint-disable-next-line @typescript-eslint/ban-types
export const throttle = (fn: Function, wait = 300) => {
  let inThrottle: boolean,
    lastFn: ReturnType<typeof setTimeout>,
    lastTime: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this,
      // eslint-disable-next-line prefer-rest-params
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

type LocalStateHook<T> = [
  T | undefined,
  (value: T | ((prev: T | undefined) => T)) => void
];

export function useLocalState<T>(
  key: string,
  defaultValue: T
): LocalStateHook<T> {
  const [state, setState] = useState<T | undefined>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue && storedValue !== 'undefined'
      ? JSON.parse(storedValue)
      : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export type CombinedQueryResults<U> = Pick<
  UseQueryResult<U, AxiosError>,
  'isLoading' | 'data'
>;
export function combineQueries<T>(
  results: UseQueryResult<Items<T>, unknown>[]
): CombinedQueryResults<Items<T>> {
  return results
    .slice(1)
    .reduce<CombinedQueryResults<Items<T>>>((combined, currentQuery) => {
      return {
        isLoading: combined.isLoading || currentQuery.isLoading,
        data: combined.data
          ? {
              items: combined.data.items.concat(
                currentQuery?.data?.items || []
              ),
            }
          : currentQuery.data,
      };
    }, results[0]);
}
