import { LinkBehavior } from '@components/atoms/buttons';
import { Components, LinkProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import type {} from '@mui/x-data-grid/themeAugmentation';

const DRAWER_WIDTH = 320;

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'contained-unchecked': true;
  }
}

const components = (theme: Theme): Components => ({
  MuiStack: {
    defaultProps: {
      spacing: 1,
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        marginHorizontal: 16,
        backgroundColor: 'white',
      },
    },
  },
  MuiPaper: {
    defaultProps: {
      square: true,
      elevation: 2,
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeMedium: {
        fontSize: '1rem',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
      },
      indicator: {
        height: 3,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      labelIcon: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        fontWeight: '600',
        minHeight: 48,
      },
      iconWrapper: {
        fontSize: '0.5rem',
      },
    },
  },
  MuiDrawer: {
    defaultProps: {
      anchor: 'right',
    },
    styleOverrides: {
      root: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
        [theme.breakpoints.only('mobile')]: {
          width: '100%',
        },
      },
      paper: {
        width: DRAWER_WIDTH,
        boxSizing: 'border-box',
        [theme.breakpoints.only('mobile')]: {
          width: '100%',
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
        fontWeight: '600',
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        padding: theme.spacing(3, 2.5),
        '&.Mui-selected': {
          backgroundColor: '#f0f0f0',
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      arrow: {
        color: theme.palette.background.paper,
      },
      tooltip: {
        backgroundColor: theme.palette.background.paper,
        color: 'rgba(0, 0, 0, 0.87)',
        borderRadius: 0,
        padding: 0,
        fontSize: theme.typography.pxToRem(12),
        filter: `drop-shadow(${theme.shadows[2]})`,
      },
    },
  },
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
    } as LinkProps,
  },
  MuiButtonBase: {
    defaultProps: {
      // @ts-ignore
      rel: 'noopener noreferrer',
      LinkComponent: LinkBehavior,
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained-unchecked' },
        style: {
          backgroundColor: 'white',
          padding: '4px 10px 1px',
          borderBottom: `3px solid #f0f0f0`,
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          '&.Mui-disabled': {
            backgroundColor: 'transparent !important',
            border: 'none !important',
          },
        },
      },
    ],
    defaultProps: {
      color: 'primary',
    },
    styleOverrides: {
      root: {
        letterSpacing: 1,
        borderRadius: 0,
        fontWeight: 600,
        fontSize: '0.875rem',
        '&.Mui-disabled': {
          backgroundColor: '#f0f0f0',
          borderBottom: `3px solid #cccccc`,
          paddingTop: 9,
        },
        '&.MuiButton-sizeXs': {
          minHeight: 16,
          padding: 0,
          fontSize: '0.75rem',
        },
      },
      sizeLarge: {
        paddingLeft: theme.spacing(7),
        paddingRight: theme.spacing(7),
        minHeight: 64,
      },
      sizeMedium: {
        minHeight: 48,
      },
      sizeSmall: {
        minHeight: 32,
        fontSize: '0.75rem',
      },
      iconSizeSmall: {
        fontSize: 12,
        ['& > *:nth-of-type(1)']: {
          fontSize: 12,
        },
      },
      iconSizeMedium: {
        fontSize: 12,
        ['& > *:nth-of-type(1)']: {
          fontSize: 12,
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        height: 48,
        width: 48,
        [theme.breakpoints.only('mobile')]: {
          height: 64,
          width: 64,
        },
        '&.large': {
          height: 80,
          width: 80,
          [theme.breakpoints.only('mobile')]: {
            height: 64,
            width: 64,
          },
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: '#e5e5e5',
      },
    },
  },
  MuiCardActions: {
    styleOverrides: {
      root: {
        backgroundColor: grey[50],
        justifyContent: 'flex-end',
        padding: theme.spacing(1, 2),
      },
    },
  },
  MuiChip: {
    defaultProps: {
      color: 'neutral',
    },
    styleOverrides: {
      root: {
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      iconSmall: {
        fontSize: '0.625rem',
        marginLeft: 8,
      },
      avatarSmall: {
        marginLeft: 8,
      },
      sizeSmall: {
        fontSize: '0.75rem',
      },
      outlined: {
        borderWidth: 2,
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: `
        @font-face {
          font-family: 'Publico';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Publico'), local('PublicoHeadline-Bold-Web'), url(/fonts/PublicoHeadline-Bold-Web.woff) format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Source Sans Pro';
          src: local('Source Sans Pro'), url('/fonts/SourceSansPro.woff') format('woff');
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Source Sans Pro';
          src: url('/fonts/SourceSansPro-SemiBold.woff') format('woff');
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Source Sans Pro';
          src: url('/fonts/SourceSansPro-Bold.woff') format('woff');
          font-weight: 700;
          font-style: normal;
          font-display: swap;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
  },
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiPagination: {
    defaultProps: {
      shape: 'rounded',
    },
    styleOverrides: {
      root: {
        margin: '16px 0 0 0',
      },
      ul: {
        '& > :first-child': {
          margin: '0 16px 0 0',
        },
        '& > :last-child': {
          margin: '0 0 0 16px',
        },
      },
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        color: theme.palette.primary.main,
        ...theme.typography.h3,
        textTransform: 'uppercase',
        borderRadius: 0,
        border: 0,
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          color: theme.palette.secondary.main,
          borderBottom: '3px solid #D24723',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
          opacity: 1,
          color: grey[600],
        },
      },
    },
  },
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
      square: true,
    },
    styleOverrides: {},
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        padding: theme.spacing(0, 3, 0, 0),
        alignItems: 'center',
        display: 'flex',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        ...theme.typography.h5,
        color: grey[600],
        minHeight: 32,
        padding: theme.spacing(0, 2, 0, 3),
        borderBottom: '1px solid #E5E5E5',
        textTransform: 'uppercase',
      },
    },
  },
  MuiDataGrid: {
    defaultProps: {
      headerHeight: 32,
      rowHeight: 56,
    },
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        border: 'none',
      },
      main: {
        boxShadow: theme.shadows[2],
      },
      cell: {
        height: 56,
        padding: '0px 16px',
        '&:focus': { outline: 'none' },
        '&:focus-within': { outline: 'none' },
        whiteSpace: 'normal !important',
        wordWrap: 'break-word !important',
        '&.address': {
          padding: '0px 4px 0px 16px',
          whiteSpace: 'pre-line',
          overflowWrap: 'anywhere !important',
        },
        '&.geocoding-quality': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      columnHeaders: {
        height: 32,
        backgroundColor: 'white',
        borderBottom: `1px solid ${grey[600]}`,
      },
      columnHeader: {
        padding: '0px 8px',
        '&:focus': { outline: 'none' },
        '&:focus-within': { outline: 'none' },
      },
      columnHeaderTitle: {
        ...theme.typography.h5,
        color: grey[600],
        textTransform: 'uppercase',
      },
      columnSeparator: {
        display: 'none',
      },
      sortIcon: {
        height: 12,
        width: 12,
      },
      footerContainer: {
        border: 'none',
        [theme.breakpoints.only('mobile')]: {
          justifyContent: 'center',
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: 0,
        marginRight: theme.spacing(1),
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 0,
        marginRight: theme.spacing(1),
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: 0,
        borderBottom: '3px solid #cccccc',
        boxShadow: theme.shadows[1],
        margin: 0,
        padding: theme.spacing(3, 2),
        [theme.breakpoints.only('mobile')]: {
          padding: theme.spacing(2, 1),
        },
        '&:has(.Mui-checked)': {
          borderBottom: `3px solid ${theme.palette.primary.main}`,
        },
      },
      label: {
        ...theme.typography.paragraphSemi,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        margin: theme.spacing(0, 0, 1, 0),
        fontSize: '0.875rem',
        color: '#111B1D',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        borderRadius: 0,
        ['& .MuiOutlinedInput-notchedOutline']: {
          border: `1px solid ${grey[600]}`,
        },
        ['&.Mui-focused .MuiOutlinedInput-notchedOutline, &.Select-checked .MuiOutlinedInput-notchedOutline']:
          {
            border: `2px solid ${theme.palette.primary.main}`,
          },
        ['& > div:focus']: {
          backgroundColor: 'white',
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        zIndex: 2000,
        margin: 'auto',
        '& .MuiDialogTitle-root': {
          padding: theme.spacing(2, 2, 2, 3),
        },
        '& .MuiDialogContent-root': {
          padding: theme.spacing(3),
          [theme.breakpoints.only('mobile')]: {
            padding: theme.spacing(3, 2),
          },
        },
        '& .MuiDialogActions-root': {
          padding: theme.spacing(2, 3, 2, 2),
        },
      },
    },
  },
});

export default components;
