import React from 'react';
import { Typography } from '@mui/material';
import {
  BackButton,
  RedLine,
  Title,
  TopSectionStack,
} from './TopSection.styles';
import { TopSectionProps } from './TopSection.types';
import ScopedDark from '@components/themes/ScopedDark';
import { ArrowBack } from '@mui/icons-material';
import { FlexBox } from '@components/templates';

const TopSection: React.FC<TopSectionProps> = ({
  back,
  headline,
  title,
  backText,
  children,
  action,
  href,
  ...props
}) => {
  return (
    <TopSectionStack $marginTop={!back || !title} id="banner-section">
      <FlexBox {...props}>
        <ScopedDark>
          {!!back && (
            <BackButton
              id="navigation-button-back"
              aria-label="back"
              size="small"
              href={href || '/'}
              $noMargin={!title}
              startIcon={<ArrowBack fontSize="large" />}
            >
              {backText}
            </BackButton>
          )}
          {title && (
            <>
              <RedLine />
              <Typography id="navigation-header-headline" variant="h3">
                {headline?.toUpperCase()}
              </Typography>
              <Title
                id="navigation-header-title"
                headline={headline}
                variant="d5"
              >
                {title}
              </Title>
            </>
          )}
          {action}
        </ScopedDark>
      </FlexBox>
      {children}
    </TopSectionStack>
  );
};

export default TopSection;
