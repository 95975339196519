import React from 'react';
import { Typography, Skeleton, Stack } from '@mui/material';
import RadioGridLoading from '@components/organisms/forms/RadioGrid/RadioGrid.loading';
import {
  ActionContainer,
  StepIcon,
  TitleAndDescriptionContainer,
} from './RecommendationStep.styles';
import ResponsiveButtonLoading from '@components/atoms/button.loading';

export default function RecommendationStepSkeleton() {
  return (
    <Stack>
      <Skeleton>
        <StepIcon />
      </Skeleton>
      <TitleAndDescriptionContainer>
        <Typography variant="h1">
          <Skeleton width={'70%'} />
        </Typography>
        <Typography variant="paragraph">
          <Skeleton width={'70%'} />
        </Typography>
      </TitleAndDescriptionContainer>
      <Typography variant="paragraphMedium">
        <Skeleton width={342} />
      </Typography>
      <ActionContainer>
        <RadioGridLoading />
        <ResponsiveButtonLoading
          size={'large'}
          id="recommendation-button-next-loading"
        />
        <ResponsiveButtonLoading
          size={'small'}
          id="recommendation-button-dismiss-loading"
        />
      </ActionContainer>
    </Stack>
  );
}
