import React from 'react';
import { FormControl, FormLabel, Radio } from '@mui/material';
import { RadioGridGroup, RadioLabel } from './RadioGrid.styles';
import { RadioGridProps } from './RadioGrid.types';

const RadioGrid = <T,>({
  id,
  data,
  selected,
  hint,
  onChange,
  mode,
  ...props
}: RadioGridProps<T>) => {
  const handleTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      data?.find(
        (option) => option.id === (event.target as HTMLInputElement).value
      )
    );
  };
  return (
    <FormControl {...props}>
      <FormLabel id={id}>{hint}</FormLabel>
      <RadioGridGroup
        aria-labelledby={id}
        name={id}
        id={id}
        value={selected?.id}
        onChange={handleTopicChange}
        mode={mode}
      >
        {data?.map((item) => (
          <RadioLabel
            key={item.id}
            control={<Radio id={`option-${item.id}`} />}
            label={item.label}
            value={item.id}
          />
        ))}
      </RadioGridGroup>
    </FormControl>
  );
};

export default RadioGrid;
