import { Box, Paper, styled } from '@mui/material';

export const ListSiteContainer = styled(Paper)`
  width: 318px;
`;

export const TitleListContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 0, 2),
  color: '#343C3D',
}));

export const PaginationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(0, 0, 2, 0),
}));
