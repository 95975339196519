import MapStyle from '@components/atoms/MapStyle/MapStyle';
import React from 'react';
import { MapContainer } from './SelectMapStyle.styles';
import { SelectMapStyleProps } from './SelectMapStyle.types';

const SelectMapStyle: React.FC<SelectMapStyleProps> = ({
  selectedMapStyle,
  mapStyles,
  onChangeMap,
}) => {
  return (
    <MapContainer>
      {mapStyles.map((mapStyle) => (
        <MapStyle
          key={mapStyle.id}
          isSelected={selectedMapStyle.id === mapStyle.id}
          mapStyle={mapStyle}
          onChangeMap={onChangeMap}
          label={mapStyle.label}
        />
      ))}
    </MapContainer>
  );
};

export default SelectMapStyle;
