import React from 'react';
import { Tab, TabProps, Tabs } from '@mui/material';
import { To } from 'react-router-dom';
import { TabBarProps } from './TabBar.types';

interface LinkTabProps extends TabProps {
  label?: string;
  to: To;
  icon?: string | React.ReactElement;
}

function LinkTab(props: LinkTabProps) {
  return <Tab iconPosition="start" {...props} />;
}

const TabBar: React.FC<TabBarProps> = ({ items, notifications, ...props }) => {
  return (
    <Tabs
      aria-label="navigation tabs"
      variant="fullWidth"
      textColor="primary"
      {...props}
    >
      {items?.map((item, index) => (
        <LinkTab
          id={item.id}
          key={item.label}
          label={
            item.label +
            (notifications[item.id] ? ` (${notifications[item.id]})` : '')
          }
          to={item.path}
          value={index}
          icon={item.icon}
        />
      ))}
    </Tabs>
  );
};

export default TabBar;
