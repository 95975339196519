import { ButtonClose } from '@components/atoms/ButtonClose';
import { RiskIcon } from '@components/atoms/RiskIcon';
import { isMapParentFilter } from '@components/organisms/map/GlobalMap/filters';
import { ExpandMore } from '@mui/icons-material';
import { AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { MapFilter } from '../MapFiltersControl/MapFiltersControl.types';
import { SelectMapStyle } from '../SelectMapStyle';
import {
  AccordionOutlined,
  AccordionDetailsOutlined,
  ChipNoBorder,
  SettingsDrawer,
  TitleDivider,
  SettingsHeader,
  MapStylesContainer,
} from './MapSettingsDrawer.styles';
import { MapSettingsDrawerProps } from './MapSettingsDrawer.types';

const MapSettingsDrawer: React.FC<MapSettingsDrawerProps> = ({
  selected,
  items,
  selectedMapStyle,
  onItemChange,
  mapStyles,
  onChangeMap,
  onClose,
  nameExtractor,
  titleMapStyle,
  titleModeSelection,
  buttonCloseTitle,
  ...props
}) => {
  const changeAndClose =
    (item: MapFilter) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event?.type === 'keydown' &&
        ((event as React.KeyboardEvent)?.key === 'Tab' ||
          (event as React.KeyboardEvent)?.key === 'Shift')
      ) {
        return;
      }
      onItemChange(item);
      onClose && onClose();
    };

  return (
    <SettingsDrawer anchor="bottom" onClose={onClose} {...props}>
      {mapStyles !== null && mapStyles.length > 1 && (
        <>
          <SettingsHeader>
            <Typography variant="h5">{titleMapStyle.toUpperCase()}</Typography>
            <ButtonClose onClose={onClose} title={buttonCloseTitle} />
          </SettingsHeader>
          <MapStylesContainer>
            <SelectMapStyle
              selectedMapStyle={selectedMapStyle}
              mapStyles={mapStyles}
              onChangeMap={onChangeMap}
            />
          </MapStylesContainer>
        </>
      )}
      <Typography variant="h5">{titleModeSelection.toUpperCase()}</Typography>
      <TitleDivider />
      {items.map((item) => {
        return !isMapParentFilter(item) ? (
          <ChipNoBorder
            key={item.field}
            label={nameExtractor(item.field)}
            isSelected={selected && selected.field === item.field}
            icon={<RiskIcon name={item.field} />}
            size="small"
            variant="outlined"
            onClick={changeAndClose(item)}
          />
        ) : (
          <AccordionOutlined
            key={item.field}
            defaultExpanded={selected && selected.parentField === item.field}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="filter-content"
              id="filter-header"
            >
              <ChipNoBorder
                key={item.field}
                label={nameExtractor(item.field)}
                icon={<RiskIcon name={item.field} />}
                size="small"
                isSelected={selected && selected.parentField === item.field}
                variant="outlined"
              />
            </AccordionSummary>
            <AccordionDetailsOutlined
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              {item.subfields.map((subfilter) => (
                <ChipNoBorder
                  key={subfilter.field}
                  label={nameExtractor(subfilter.field, true)}
                  icon={<RiskIcon name={subfilter.field} />}
                  size="small"
                  variant="outlined"
                  sx={{ borderWidth: 0 }}
                  isSelected={selected && selected.field === subfilter.field}
                  onClick={changeAndClose({
                    ...subfilter,
                    parentField: item.field,
                  })}
                />
              ))}
            </AccordionDetailsOutlined>
          </AccordionOutlined>
        );
      })}
    </SettingsDrawer>
  );
};

export default MapSettingsDrawer;
