import { Box, Typography, styled } from '@mui/material';

export const MapEventContainerStorybook = styled(Box)`
  width: 700px;
  height: 700px;
`;

export const SitesImpactedNotificationContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  bottom: 40,
  left: 20,
  width: 29,
  height: 20,
  borderRadius: 20,
  backgroundColor: theme.palette.notification.main,
}));

export const NumberOfSites = styled(Typography)(({ numberSites, theme }) => ({
  position: 'relative',
  bottom: 14,
  left: numberSites > 9 ? 9 : 13,
  color: theme.palette.neutral.contrastText,
}));
