import { PerilRiskGrade, TotalRiskGrade } from '@modules/types';
import currencyMap from './currencyMap.json';

/**
 * Linearly interpolates between two numbers based on a given ratio.
 * @param {number} x - The starting value.
 * @param {number} y - The ending value.
 * @param {number} a - The ratio between the two values (0 to 1).
 * @returns {number} The interpolated value.
 */
const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;

/**
 * Calculates the ratio between two numbers based on a given value.
 * @param {number} x - The starting value.
 * @param {number} y - The ending value.
 * @param {number} a - The value to find the ratio for.
 * @returns {number} The ratio between x and y.
 */
const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));

/**
 * Restricts a value within a specified range.
 * @param {number} a - The value to clamp.
 * @param {number} min - The minimum value of the range. Default is 0.
 * @param {number} max - The maximum value of the range. Default is 1.
 * @returns {number} The clamped value.
 */
const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));

/**
 * Maps a value from one range to another range using linear interpolation.
 * @param {number} x1 - The starting value of the input range.
 * @param {number} y1 - The ending value of the input range.
 * @param {number} x2 - The starting value of the output range.
 * @param {number} y2 - The ending value of the output range.
 * @param {number} a - The value to be mapped.
 * @returns {number} The mapped value.
 *
 * // Example of usage:
 * const mapped = range(0, 10, 100, 200, 5);
 * console.log(mapped); // Output: 150
 */
export const range = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  a: number
) => lerp(x2, y2, invlerp(x1, y1, a));

export const valueDividerLookup = [
  { value: 1e12, symbol: 't' },
  { value: 1e9, symbol: 'b' },
  { value: 1e6, symbol: 'm' },
  { value: 1e3, symbol: 'k' },
  { value: 1, symbol: '' },
];

export function shortNumberFormatter(num: number, currency = 'USD'): string {
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const divider = valueDividerLookup.find((divider) => num >= divider.value);
  const currencySymbol =
    (currencyMap as { [key: string]: string })[currency] || '$';
  if (divider) {
    const quotient = num / divider.value;
    return (
      currencySymbol +
      quotient.toFixed(quotient < 10 ? 1 : 0).replace(rx, '$1') +
      divider.symbol
    );
  } else {
    return currencySymbol + '0';
  }
}

export function currencyFormat(value?: number, currency = 'USD'): string {
  const currencySymbol =
    (currencyMap as { [key: string]: string })[currency] || '$';
  if (value === undefined) return currencySymbol + '0.00';
  return (
    currencySymbol + value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
}

export function currencySymbole(currency = 'USD'): string {
  const currencySymbol =
    (currencyMap as { [key: string]: string })[currency] || '$';
  return currencySymbol;
}

// todo : use palette ?
export const riskColors = {
  [PerilRiskGrade.Negligible]: {
    color: '#138636',
    label: 'Negligible',
    grade: 'A',
  },
  [PerilRiskGrade.Low]: { color: '#8AB957', label: 'Low', grade: 'B' },
  [PerilRiskGrade.Medium]: { color: '#F2CE0D', label: 'Moderate', grade: 'C' },
  [PerilRiskGrade.High]: { color: '#FF9900', label: 'High', grade: 'D' },
  [PerilRiskGrade.Extreme]: {
    color: '#B7001E',
    light: '#EA3223',
    label: 'Extreme',
    grade: 'E',
  },
};

export const riskGradeColors = {
  A: '#138636',
  B: '#8ab957',
  C: '#f2ce0d',
  D: '#ff9900',
  E: '#c91432',
};

export const getRiskLevel = (riskGrade: PerilRiskGrade) =>
  riskColors[riskGrade];

export const getRiskGrade = (risk?: TotalRiskGrade) =>
  risk
    ? {
        label: risk,
        color: riskGradeColors[risk],
      }
    : undefined;

export const sitesItemPerPage = 3;
