import {
  Button,
  Dialog,
  dialogClasses,
  DialogTitle,
  paperClasses,
  styled,
} from '@mui/material';

export const DialogContainer = styled(Dialog)(({ theme, width }) => ({
  [`& > .${dialogClasses.container} > .${paperClasses.root}`]: {
    [theme.breakpoints.not('mobile')]: {
      maxWidth: width,
    },
    [theme.breakpoints.only('mobile')]: {
      margin: theme.spacing(1),
    },
  },
}));

export const DialogHeader = styled(DialogTitle)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const ButtonClose = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  [theme.breakpoints.only('mobile')]: {
    flex: 1,
  },
}));
