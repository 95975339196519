import { ResponsiveDirectionBox } from '@components/templates';
import React from 'react';
import { PortfolioRiskList } from '../PortfolioRiskList';
import { PortfolioValueChart } from '../PortfolioValueChart';
import { PortfolioRiskValueProps } from './PortfolioRiskValue.types';
import { PortfolioResponsiveSeparator } from './PortfolioRiskValue.styles';

const PortfolioRiskValue: React.FC<PortfolioRiskValueProps> = ({
  riskTooltip,
  valueDescription,
  valueTitle,
  titleValueDonut,
  children,
  openModal,
  companyCurrency,
  siteValueDescription,
  description,
  redirectionText,
  sourceText,
  wrongValueText,
  redirectionLink,
  onSendMessage,
  errorMessage,
  coachID,
  ...props
}) => {
  return (
    <ResponsiveDirectionBox>
      <PortfolioRiskList
        {...props}
        tooltip={riskTooltip}
        openModal={openModal}
        description={description}
        redirectionText={redirectionText}
        sourceText={sourceText}
      />
      {props.total && props.total > 0 && (
        <>
          <PortfolioResponsiveSeparator />
          <PortfolioValueChart
            {...props}
            description={valueDescription}
            title={valueTitle}
            titleValueDonut={titleValueDonut}
            companyCurrency={companyCurrency}
            tooltipDescription={siteValueDescription}
            wrongValueText={wrongValueText}
            redirectionLink={redirectionLink}
            onSendMessage={onSendMessage}
            errorMessage={errorMessage}
            coachID={coachID}
          >
            {children}
          </PortfolioValueChart>
        </>
      )}
    </ResponsiveDirectionBox>
  );
};

export default PortfolioRiskValue;
