import { Add, Remove } from '@mui/icons-material';
import React from 'react';
import { useMap } from 'react-map-gl';
import { IconButtonControl, ZoomContainer } from './ZoomControl.styles';
import { ZoomControlProps } from './ZoomControl.types';

const ZoomControl: React.FC<ZoomControlProps> = ({ smallMargin }) => {
  const map = useMap();
  return (
    <ZoomContainer $smallMargin={smallMargin}>
      <IconButtonControl
        id="map-zoom-in"
        aria-label="zoom-in"
        key={'zoom-in'}
        onClick={map.current?.zoomIn}
        startIcon={<Add />}
        variant={'contained-unchecked'}
      />
      <IconButtonControl
        id="map-zoom-out"
        aria-label="zoom-out"
        key={'zoom-out'}
        onClick={map.current?.zoomOut}
        startIcon={<Remove />}
        variant={'contained-unchecked'}
      />
    </ZoomContainer>
  );
};

export default ZoomControl;
