import { Box, Paper, styled } from '@mui/material';
import { FlexBox } from '../layouts';

export const TopSectionContainer = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const PaginationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 0.5),
  gap: theme.spacing(3),
  overflow: 'hidden',
  flex: 1,
}));

export const PADDING_PAGINATION_PAPPER = 0;

export const ListItemsContainer = styled(FlexBox)(() => ({}));

export const ItemContainer = styled(Box)(({ $dynamicWidth }) => ({
  flexShrink: 0,
  width: $dynamicWidth ? 0.93 * $dynamicWidth : 300,
  marginRight: $dynamicWidth ? 0.07 * $dynamicWidth : 324,
}));

export const SliderContainer = styled(Box)(({ $offset }) => ({
  transform: `translateX(-${$offset}px)`,
  display: 'flex',
  transition: 'transform 0.5s ease-in-out',
}));
