import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { DoughnutContainer, TotalContainer } from './DonutChart.styles';
import { Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';

const DonutChartSkeleton: React.FC = () => {
  const isMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('desktop')
  );
  const [size, borderRadius, cutout] = isMedium ? [178, 40, 70] : [232, 60, 95];

  return (
    <DoughnutContainer>
      <Doughnut
        key={`doughnut-${isMedium}-loading`}
        width={size}
        height={size}
        data={{
          datasets: [
            {
              data: [1, 2, 3].map((item) => item),
              borderRadius,
            },
          ],
        }}
        options={{
          cutout,
          maintainAspectRatio: false,
          responsive: false,
        }}
      />
      <TotalContainer>
        <Typography variant="paragraphSemi">
          <Skeleton width={15} />
        </Typography>
        <Typography sx={{ marginTop: -1 }} variant="chartValue">
          <Skeleton width={30} />
        </Typography>
      </TotalContainer>
    </DoughnutContainer>
  );
};

export default DonutChartSkeleton;
