import { Typography } from '@mui/material';
import React from 'react';
import { currencyFormat } from '@utils/numbers';
import { BarDef } from '../../chartDef';
import {
  LegendaryColor,
  LegendaryColumn,
  LegendaryItem,
  LegendContainer,
} from './LegendChart.styles';
import { LegendChartProps } from './LegendChart.types';

const Item: React.FC<BarDef & { currency?: string }> = ({
  name,
  value,
  currency,
  color,
}) => {
  return (
    <LegendaryItem>
      <LegendaryColor color={color} />
      <Typography variant="h3">{name}</Typography>
      <Typography variant="h4">{currencyFormat(value, currency)}</Typography>
    </LegendaryItem>
  );
};

const LegendChart: React.FC<LegendChartProps> = ({
  data,
  currency,
  ...props
}) => {
  return (
    <LegendContainer {...props}>
      <LegendaryColumn>
        {data?.slice(0, Math.ceil(data.length / 2))?.map((bar) => (
          <Item {...bar} key={bar.name} currency={currency} />
        ))}
      </LegendaryColumn>
      <LegendaryColumn>
        {data?.slice(Math.ceil(data.length / 2))?.map((bar) => (
          <Item {...bar} key={bar.name} currency={currency} />
        ))}
      </LegendaryColumn>
    </LegendContainer>
  );
};

export default LegendChart;
