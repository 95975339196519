import { Event } from '@modules/types/events.types';
import { Theme } from '@mui/material';

export const getColorsLiveEvent = (
  theme: Theme,
  event: Event,
  isSelected?: boolean
) => {
  return event?.status === 'active'
    ? isSelected
      ? theme.palette.selected.main
      : theme.palette[event.category]?.main || theme.palette.other.main
    : theme.palette.text.primary;
};

export const getColorsLiveEventHover = (
  theme: Theme,
  event: Event,
  isSelected?: boolean
) => {
  return event?.status === 'active'
    ? isSelected
      ? theme.palette.selected.main
      : theme.palette[event.category]?.dark || theme.palette.other.dark
    : theme.palette.text.primary;
};
