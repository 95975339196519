import React from 'react';
import { PaperAlert, PageAlert } from '@components/templates';
import { ArrowBack } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';

export interface SuccessProps {}

const Success: React.FC<SuccessProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { title, description } = location.state || {
    title: t('navigation.success'),
  };
  return (
    <PageAlert>
      <PaperAlert>
        <img src="/tick.svg" />
        <Typography variant="h2">{title}</Typography>
        <Typography variant="paragraphMedium" whiteSpace="pre-line">
          {description}
        </Typography>
        <Button
          id="success-button-next"
          sx={{
            alignSelf: 'center',
            marginTop: 3,
          }}
          size="large"
          variant="contained"
          startIcon={<ArrowBack />}
          href="/"
        >
          {t('navigation.dashboard')}
        </Button>
      </PaperAlert>
    </PageAlert>
  );
};

export default withErrorBoundary(Success);
