import React, { useLayoutEffect, useRef, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { HorizontalScrollView } from '../HorizontalScrollView';
import {} from '../layouts';
import {
  ItemContainer,
  ListItemsContainer,
  PADDING_PAGINATION_PAPPER,
  PaginationContainer,
  SliderContainer,
  TopSectionContainer,
} from './HorizontalPaginatonList.styles';
import { HorizontalPaginatonListProps } from './HorizontalPaginatonList.types';
import { PaginationArrow } from '@components/molecules/navigation/PaginationArrow';
import { useWindowWidth } from '@utils/window';

const HorizontalPaginatonList = <T,>({
  ...props
}: HorizontalPaginatonListProps<T>) => {
  const [page, setPage] = useState(1);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('desktop')
  );
  const width = useWindowWidth();

  const [countPerPage, setCountPerPage] = useState(
    width < 1350 ? 3 : width < 1500 ? 4 : 5
  );
  const [animationOffset, setAnimationOffset] = useState(0);
  const [widthItem, setWidthItem] = useState(300);
  const totalPages = Math.ceil(props.items.length / countPerPage);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const newCountPerPage = width < 1350 ? 3 : width < 1500 ? 4 : 5;
      const newTotalPages = Math.ceil(props.items.length / newCountPerPage);
      setWidthItem(
        (containerRef.current.offsetWidth - PADDING_PAGINATION_PAPPER) /
          newCountPerPage
      );
      setCountPerPage(newCountPerPage);
      page < newTotalPages ? onPageChange(page) : onPageChange(newTotalPages);
    }
  }, [width]);

  const onPageChange = (newPage: number) => {
    if (containerRef.current) {
      const offset =
        (newPage - 1) *
        (containerRef.current.offsetWidth - PADDING_PAGINATION_PAPPER);
      setAnimationOffset(offset);
      setPage(newPage);
    } else {
      setAnimationOffset(0);
    }
  };

  return isSmall ? (
    <HorizontalScrollView itemAutoWidth {...props} />
  ) : (
    <TopSectionContainer>
      <PaginationArrow
        page={page}
        onPageChange={onPageChange}
        count={totalPages}
      >
        <PaginationContainer ref={containerRef}>
          <SliderContainer $offset={animationOffset}>
            <ListItemsContainer>
              {props.items.map((item, index) => (
                <ItemContainer $dynamicWidth={widthItem} key={index}>
                  {props.renderItem(item)}
                </ItemContainer>
              ))}
            </ListItemsContainer>
          </SliderContainer>
        </PaginationContainer>
      </PaginationArrow>
    </TopSectionContainer>
  );
};

export default HorizontalPaginatonList;
