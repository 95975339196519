import { FlexBox } from '@components/templates';
import { styled } from '@mui/material';

export const RiskGridContainer = styled(FlexBox)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  border: '1px solid #ccc',
  [theme.breakpoints.only('mobile')]: {
    gridTemplateColumns: '2fr 1fr 2fr',
  },
  ['& > *']: {
    border: '1px solid #ccc',
    padding: theme.spacing(1.5, 1.25),
    [theme.breakpoints.only('mobile')]: {
      padding: theme.spacing(1.25, 1),
    },
  },
  ['& > *:nth-child(-n+3)']: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
  },
}));
