import { LayerProps } from 'react-map-gl';
import { Expression } from 'mapbox-gl';
export const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'locations',
  minzoom: 7,
  paint: {
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#FFED1F',
      '#C91432',
    ],
    'circle-radius': 10,
    'circle-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 1],
  },
};

const categoryAOIExpression: Expression = [
  'case',
  ['==', ['get', 'status'], 'closed'],
  '#343C3D',
  [
    'match',
    ['get', 'category'],
    'hydrological',
    '#4976BA',
    'geophysical',
    '#A79666',
    'meteorological',
    '#41949F',
    'wildfire',
    '#E28972',
    '#AC7074',
  ],
];

const categoryDelineationExpression: Expression = [
  'case',
  ['==', ['get', 'status'], 'closed'],
  '#343C3D',
  [
    'match',
    ['get', 'category'],
    'hydrological',
    '#3A5E94',
    'geophysical',
    '#6E5C28',
    'meteorological',
    '#015A66',
    'wildfire',
    '#D75D3D',
    '#743438',
  ],
];

export const areaOfInterestLayer: LayerProps = {
  id: 'aoi',
  type: 'fill',
  source: 'events',
  minzoom: 5,
  filter: ['==', ['get', 'kind'], 'areaOfInterest'],
  paint: {
    'fill-color': categoryAOIExpression,
    'fill-opacity': ['interpolate', ['linear'], ['zoom'], 6, 0, 7, 0.3],
  },
};

export const areaOfInterestBorderLayer: LayerProps = {
  id: 'aoi-border',
  type: 'line',
  source: 'events',
  minzoom: 5,
  filter: ['==', ['get', 'kind'], 'areaOfInterest'],
  paint: {
    'line-width': 3,
    'line-color': categoryAOIExpression,
    'line-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 7, 0.7],
  },
};

export const delineationLayer: LayerProps = {
  id: 'delineation',
  type: 'fill',
  source: 'events',
  minzoom: 5,
  filter: ['==', ['get', 'kind'], 'delineation'],
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#FFBC11',
      categoryDelineationExpression,
    ],
    'fill-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 7, 1],
  },
};
