import ArticleCardSkeleton from '@components/molecules/resources/ArticleCard/ArticleCard.loading';
import PlaybookCardSkeleton from '@components/molecules/resources/PlaybookCard/PlaybookCard.loading';
import RecommendationCardSkeleton from '@components/molecules/resources/RecommendationCard/RecommendationCard.loading';
import TopSectionSkeleton from '@components/molecules/navigation/TopSection/TopSection.loading';
import ResourcesSectionSkeleton from '@components/organisms/resources/ResourcesSection/ResourcesSection.loading';
import RiskCoachSkeleton from '@components/organisms/contact/RiskCoach/RiskCoach.loading';
import SitesOverviewSkeleton from '@components/organisms/portfolio/SitesOverview/SiteOverview.loading';
import { GradientSection, Page, PageSection } from '@components/templates';
import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import EventCardDashboardSkeleton from '@components/molecules/events/EventCardDashboard/EventCardDashboard.loading';

const DashboardLoader: React.FC<Record<string, never>> = () => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return (
    <>
      <GradientSection>
        <TopSectionSkeleton headline={'loading'}>
          {!isSmall && <RiskCoachSkeleton />}
        </TopSectionSkeleton>
      </GradientSection>
      <Page>
        <ResourcesSectionSkeleton
          renderItem={(key) => <RecommendationCardSkeleton key={key} />}
        />
        <ResourcesSectionSkeleton
          renderItem={(key) => <EventCardDashboardSkeleton key={key} />}
          breakpointsColumn={{
            mobile: 1,
            tablet: 2,
            desktop: 4,
          }}
          items={['1', '2', '3', '4']}
        />
        {isSmall && (
          <PageSection>
            <RiskCoachSkeleton />
          </PageSection>
        )}

        <PageSection color="primary">
          <SitesOverviewSkeleton />
        </PageSection>
        <ResourcesSectionSkeleton
          renderItem={(key) => <ArticleCardSkeleton key={key} />}
        />
        <ResourcesSectionSkeleton
          renderItem={(key) => <PlaybookCardSkeleton key={key} />}
        />
      </Page>
    </>
  );
};

export default DashboardLoader;
