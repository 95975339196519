import { Typography } from '@mui/material';
import React from 'react';
import {
  SummaryContainer,
  SummaryImage,
  TextContainer,
} from './SummaryButton.styles';
import { SummaryButtonProps } from './SummaryButton.types';

const SummaryButton: React.FC<SummaryButtonProps> = ({
  src,
  title,
  description,
  ...props
}) => {
  return (
    <SummaryContainer {...props}>
      <SummaryImage src={src} />
      <TextContainer>
        <Typography variant="h3">{title.toUpperCase()}</Typography>
        <Typography variant="paragraphMedium">{description}</Typography>
      </TextContainer>
    </SummaryContainer>
  );
};

export default SummaryButton;
