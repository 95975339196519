import React from 'react';
import {
  CardContainer,
  CardContent,
  TextContainer,
} from './EventCardDashboard.styles';
import { Button, CardActions, Skeleton, Typography } from '@mui/material';
import { ArrowRightAlt } from '@mui/icons-material';

const EventCardDashboardSkeleton = () => {
  return (
    <CardContainer id="live-event-dashboard-card">
      <CardContent elevation={2}>
        <Skeleton variant="circular" width={32} height={32} />
        <TextContainer>
          <Typography variant="h5">
            <Skeleton width={100} />
          </Typography>
          <Typography variant="h5">
            <Skeleton width={50} />
          </Typography>
          <Typography variant="paragraphSmall">
            <Skeleton width={150} />
          </Typography>
        </TextContainer>
      </CardContent>
      <CardActions>
        <Skeleton>
          <Button
            id="live-event-button-start"
            size="small"
            endIcon={<ArrowRightAlt />}
          >
            Go to live events
          </Button>
        </Skeleton>
      </CardActions>
    </CardContainer>
  );
};

export default EventCardDashboardSkeleton;
