import { ArticleItems, PlaybookItems } from '@modules/types';
import { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { apiPortalClient } from '.';

export const useArticles = (): UseQueryResult<ArticleItems, AxiosError> => {
  const apiCall = async () => {
    const response = await apiPortalClient.get<ArticleItems>(
      '/o/smartsite/articles/v1/list'
    );
    return response.data;
  };

  return useQuery(['/articles'], apiCall, {
    useErrorBoundary: false,
    retry: 1,
  });
};

export const usePlaybooks = (): UseQueryResult<PlaybookItems, AxiosError> => {
  const apiCall = async () => {
    const response = await apiPortalClient.get<PlaybookItems>(
      '/o/smartsite/playbooks/v1/list'
    );
    return {
      playbooks: response.data.playbooks.concat([
        {
          id: '146554',
          title: 'Cutting, welding and other hot work',
          url: 'https://smartservices.axaxl.com',
          image: {
            url: '/worker.svg',
            description: 'worker',
          },
          categories: [
            {
              id: '146427',
              name: 'human element',
            },
          ],
          disabled: true,
        },
        {
          id: '146576',
          title: 'Respond to windstorms and hurricanes',
          url: 'https://smartservices.axaxl.com',
          image: {
            url: '/tornado.svg',
            description: 'tornado',
          },
          categories: [
            {
              id: '146428',
              name: 'natural hazard',
            },
          ],
          disabled: true,
        },
        {
          id: '146587',
          title: 'Understand and respond to flood',
          url: 'https://smartservices.axaxl.com',
          image: {
            url: '/flooded-house.svg',
            description: 'flooded house',
          },
          categories: [
            {
              id: '146428',
              name: 'natural hazard',
            },
          ],
          disabled: true,
        },
        {
          id: '536710',
          title: 'Water leakage',
          url: 'https://smartservices.axaxl.com',
          image: {
            url: '/flood.svg',
            description: 'Flooded House',
          },
          categories: [
            {
              id: '51415',
              name: 'human element',
            },
          ],
          disabled: true,
        },
        {
          id: '536711',
          title: 'Preventing hail damage',
          url: 'https://smartservices.axaxl.com/',
          image: {
            url: '/car.svg',
            description: 'Flooded House',
          },
          categories: [
            {
              id: '51416',
              name: 'natural hazard',
            },
          ],
          disabled: true,
        },
        {
          id: '536713',
          title: 'Earthquake design of building',
          url: 'https://smartservices.axaxl.com',
          image: {
            url: '/hotel.svg',
            description: 'Earthquake',
          },
          categories: [
            {
              id: '51416',
              name: 'natural hazard',
            },
          ],
          disabled: true,
        },
      ]),
    };
  };
  return useQuery(['/playbooks'], apiCall, {
    useErrorBoundary: false,
    retry: 1,
  });
};
