import React from 'react';
import { SiteSummaryCardProps } from './SiteSummaryCard.types';
import { IndividualSiteSummary } from '../IndividualSiteSummary';
import { ListSiteSummary } from '../ListSiteSummary';
import { TitlePopup } from '@components/molecules/portfolio/Title/TitlePopup';

const SiteSummaryCard: React.FC<SiteSummaryCardProps> = ({
  sites,
  selectedSite,
  onSelectSite,
  companyCurrency,
}) => {
  return (
    <>
      {selectedSite ? (
        <>
          <TitlePopup title={selectedSite.name} />
          <IndividualSiteSummary
            site={selectedSite}
            companyCurrency={companyCurrency}
          />
        </>
      ) : (
        <ListSiteSummary
          sites={sites}
          onClick={onSelectSite}
          companyCurrency={companyCurrency}
        />
      )}
    </>
  );
};

export default SiteSummaryCard;
