import { FlexColumnBox } from '@components/templates';
import { Box, Typography, styled } from '@mui/material';

export const RiskComponentContainer = styled(FlexColumnBox)(({ theme }) => ({
  gap: 16,
  alignItems: 'center',
  [theme.breakpoints.not('mobile')]: {
    flex: '0 1 315px',
  },
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0, 2.75, 0),
}));

export const TitleContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
