import { useEffect, useState } from 'react';
import { throttle } from './functions';

export function useWindowWidth(options?: { throttleInterval?: number }) {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = throttle(() => {
      setWidth(window.innerWidth);
    }, options?.throttleInterval);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}

export function useIsFullScreen() {
  const [isFullScreen, setIsFullScreen] = useState(
    screen.height === window.innerHeight
  );
  useEffect(() => {
    const handleResize = () => {
      setIsFullScreen(screen.height === window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return isFullScreen;
}
