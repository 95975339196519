import { RiskProgress } from '@components/atoms/RiskProgress';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { TitleItemContainer } from './RiskLevel.styles';

const RiskLevelSkeleton: React.FC = () => {
  return (
    <Box>
      <TitleItemContainer>
        <Typography variant="paragraphSmall">
          <Skeleton width={30} />
        </Typography>
        <Skeleton variant="circular" width={13} height={13} />
      </TitleItemContainer>
      <Stack direction="row">
        <Skeleton width={'100%'}>
          <RiskProgress value={0} />
        </Skeleton>
        <Typography variant="paragraphSmall">
          <Skeleton width={10} />
        </Typography>
      </Stack>
    </Box>
  );
};

export default RiskLevelSkeleton;
