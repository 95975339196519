import React from 'react';
import { ExplanationPgaProps } from './ExplanationPga.types';
import {
  SquareContainer,
  TitleContainer,
  DescriptionContainer,
} from './ExplanationPga.styles';
import { Typography } from '@mui/material';

const ExplanationPga: React.FC<ExplanationPgaProps> = ({
  title,
  description,
}) => {
  return (
    <SquareContainer>
      <TitleContainer variant="h2">{title}</TitleContainer>
      <DescriptionContainer>
        <Typography variant="leadSemi">{description}</Typography>
      </DescriptionContainer>
    </SquareContainer>
  );
};

export default ExplanationPga;
