import { NAV_BAR_MARGIN } from '@components/organisms/navigation/NavBar/NavBar.styles';
import { styled, Box, Button, buttonClasses } from '@mui/material';

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.only('desktop')]: {
    top: theme.spacing(2.5),
    right: NAV_BAR_MARGIN,
  },
}));

export const IconButtonControl = styled(Button)((/*{ theme }*/) => ({
  padding: 0,
  minWidth: 48,
  minHeight: 32,
  [`& .${buttonClasses.startIcon}`]: {
    margin: 0,
  },
}));
