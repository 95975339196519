import {
  MapFilter,
  MapFilterControl,
} from '@components/molecules/locationMap/MapFiltersControl/MapFiltersControl.types';
import { SiteMapFeature, SiteSummary } from '@modules/types';
import { throttle } from '@utils/functions';
import { MapRef } from 'react-map-gl';
import {
  GeoJSONSource,
  LngLat,
  MapboxEvent,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
  PointLike,
} from 'mapbox-gl';
import {
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  buildMapboxColorExpression,
  buildFilterMarkerData,
  isMapScaleFilter,
  isHeatmapFilter,
} from './filters';
import { MapLegend } from '@components/molecules/locationMap/MapLegend';

export type LocationMap = ReturnType<typeof useLocationMap>;

export const useLocationMap = <T = never>(
  data: SiteSummary[],
  options?: {
    interactiveLayers: {
      ids: string[];
      onLayerClick: (item: T) => void;
    };
  }
) => {
  const mapRef = useRef<MapRef>(null);
  const [hoveredFeature, setHoveredFeature] = useState<
    (MapboxGeoJSONFeature & { lnglat: LngLat }) | undefined
  >();
  const locationsSource: GeoJSON.FeatureCollection<GeoJSON.Geometry> = useMemo(
    () => ({
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
        },
      },
      features: data.map((site) => ({
        type: 'Feature',
        properties: {
          ...site,
          ...site.perilGradeSummary,
          address: site?.address?.standard,
          siteValue: site?.insuredValues?.total,
        },
        geometry: {
          type: 'Point',
          coordinates: [site.lon, site.lat],
        },
      })),
    }),
    [data]
  );
  const [selectedLocation, setSelectedLocation] = useState<
    SiteMapFeature[] | null
  >(null);

  const onClick = useCallback(
    (event: MapLayerMouseEvent) => {
      if (options?.interactiveLayers) {
        const featuresLayer = mapRef.current?.queryRenderedFeatures(
          event.point,
          {
            layers: options.interactiveLayers.ids,
          }
        );

        if (
          featuresLayer &&
          featuresLayer.length > 0 &&
          featuresLayer[0].properties &&
          featuresLayer[0].properties.id
        ) {
          options.interactiveLayers.onLayerClick(
            featuresLayer[0].properties as T
          );
        }
      }

      if (selectedLocation) {
        setSelectedLocation(null);
        return;
      }

      // handle multiple circle with different radius on same location
      const bbox = [
        [event.point.x - 16, event.point.y - 16],
        [event.point.x + 16, event.point.y + 16],
      ] as [PointLike, PointLike];
      // Find features intersecting the bounding box.
      const features = mapRef.current?.getLayer('unclustered-point')
        ? mapRef.current?.queryRenderedFeatures(bbox, {
            layers: ['unclustered-point'],
          })
        : [];
      if (!(features && features.length > 0)) {
        return;
      }

      const sites = features.map((feature) => feature.properties);
      setSelectedLocation(sites as SiteMapFeature[]);
    },
    [selectedLocation, mapRef?.current]
  );

  const setHover = useCallback(
    (hover: boolean) => (event: MapLayerMouseEvent) => {
      if (event.features && event.features.length > 0 && mapRef.current) {
        for (let feature of event.features) {
          mapRef.current.setFeatureState(
            { source: feature.source, id: feature.id },
            { hover }
          );
        }
        setHoveredFeature(
          hover ? { ...event.features[0], lnglat: event.lngLat } : undefined
        );
      }
    },
    [mapRef?.current]
  );

  const onMouseEnter = useCallback(setHover(true), [setHover]);
  const onMouseLeave = useCallback(setHover(false), [setHover]);

  return {
    mapRef,
    hoveredFeature,
    locationsSource,
    selectedLocation,
    setSelectedLocation,
    unselectLocation: () => setSelectedLocation(null),
    onClick,
    onMouseEnter,
    onMouseLeave,
  };
};
export const usePortfolioMap = (
  data: SiteSummary[],
  filters: MapFilterControl[]
) => {
  const {
    mapRef,
    locationsSource,
    selectedLocation,
    setSelectedLocation,
    onClick,
    onMouseEnter,
    onMouseLeave,
    unselectLocation,
  } = useLocationMap(data);

  const mapLegendRef = useRef<ElementRef<typeof MapLegend>>(null);
  const [selectedFilter, setSelectedFilter] = useState<MapFilter>(
    filters[0] as MapFilter
  );

  const buildMarkerData = useCallback(
    isMapScaleFilter(selectedFilter)
      ? buildFilterMarkerData(selectedFilter)
      : () => [],
    [selectedFilter]
  );

  const [clusters, setClusters] = useState<Record<
    string,
    MapboxGeoJSONFeature
  > | null>(null);

  const onRender: (e: MapboxEvent) => void = useCallback(
    throttle((e: MapboxEvent) => {
      if (isHeatmapFilter(selectedFilter)) {
        setClusters(null);
        return;
      }

      const newMarkers: Record<string, MapboxGeoJSONFeature> = {};
      const map = e.target;
      const features = map.querySourceFeatures('locations');

      for (const feature of features) {
        const props = feature.properties;
        if (!(props && props.cluster)) continue;
        const id = props.cluster_id;
        newMarkers[id] = feature;
      }

      if (
        clusters === null ||
        Object.keys(clusters).join(',') !== Object.keys(newMarkers).join(',')
      ) {
        setClusters(newMarkers);
      }
    }, 300),
    [selectedFilter, clusters]
  );

  const onClusterClick = useCallback(
    (clusterId?: number, longitude?: number, latitude?: number) => {
      const mapboxSource = mapRef?.current?.getSource(
        'locations'
      ) as GeoJSONSource;

      if (clusterId && longitude && latitude) {
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          mapRef?.current?.easeTo({
            center: { lon: longitude, lat: latitude },
            zoom,
            duration: 500,
          });
        });
      }
    },
    [mapRef?.current]
  );

  useEffect(() => {
    mapRef.current
      ?.getMap()
      .setPaintProperty(
        'unclustered-point',
        'circle-color',
        buildMapboxColorExpression(selectedFilter)
      );

    mapLegendRef.current?.open();
  }, [selectedFilter]);

  return {
    mapRef,
    mapLegendRef,
    locationsSource,
    clusters,
    selectedLocation,
    setSelectedLocation,
    buildMarkerData,
    selectedFilter,
    setSelectedFilter,
    unselectLocation,
    onRender,
    onClick,
    onClusterClick,
    onMouseEnter,
    onMouseLeave,
  };
};
