import { RegularBreakpointsColumn } from '@components/templates/ExpandableGrid/ExpandableGrid.types';
import { Breakpoint, Theme, useMediaQuery, useTheme } from '@mui/material';

export function useGridColumn(gridColumns: RegularBreakpointsColumn): number {
  const theme: Theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  const screen =
    keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up(key)
      );
      return !output && matches ? key : output;
    }, null) || 'mobile';

  return gridColumns[screen];
}
