import React from 'react';
import { ItemCardProps } from './ItemCard.types';
import { Typography } from '@mui/material';
import { ItemContainer } from './ItemCard.styles';

const ItemCard: React.FC<ItemCardProps> = ({ title, value }) => {
  return (
    <ItemContainer>
      <Typography variant="paragraphSmallSemi">
        {title}
        {': '}
      </Typography>
      <Typography variant="paragraphSmall">{value}</Typography>
    </ItemContainer>
  );
};

export default ItemCard;
