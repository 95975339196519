import { CountrySummary } from '@modules/types';
import {
  GridColDef,
  gridNumberComparator,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { shortNumberFormatter } from '@utils/numbers';
import { allRisks, extractCountry } from '@utils/strings';
import { renderRiskGrade } from './SiteCells';
import { TFunction } from 'i18next';
import { generateRiskGradesDef } from './datadef';

export const getColumnsPerCountry = (
  t: TFunction<'translation', undefined, 'translation'>,
  currency: string
): GridColDef<CountrySummary>[] => [
  {
    field: 'country',
    headerName: 'Country',
    flex: 1,
    minWidth: 100,
    valueGetter: (params) => extractCountry(params.row.country.isoCode2),
  },
  {
    field: 'sumTiv',
    headerName: 'Value',
    flex: 1.5,
    filterable: false,
    disableColumnMenu: true,
    minWidth: 164,
    valueGetter: (params) => ({
      value: params.row.sumTiv,
      grade: currency,
    }),
    sortComparator: (v1, v2, param1, param2) =>
      gridStringOrNumberComparator(v1.grade, v2.grade, param1, param2) ||
      gridNumberComparator(v1.value, v2.value, param1, param2),
    valueFormatter: (params) =>
      shortNumberFormatter(params?.value.value, currency),
  },
  {
    field: 'sitesCount',
    headerName: 'Sites',
    flex: 1.5,
    minWidth: 130,
    valueGetter: (params) => params.row.sitesCount,
  },
  {
    field: 'riskGradeCountry',
    headerName: 'Grade',
    flex: 1,
    minWidth: 100,
    valueGetter: (params) => ({
      value: params.row.sumTiv,
      grade: params.row.riskGrade,
    }),
    sortComparator: (v1, v2, param1, param2) =>
      gridStringOrNumberComparator(v1.grade, v2.grade, param1, param2) ||
      gridNumberComparator(v1.value, v2.value, param1, param2),
    renderCell: renderRiskGrade,
  },
  ...generateRiskGradesDef(allRisks, t, 'country'),
];
