import React from 'react';

import { MiniMap } from '@components/molecules';
import { useParams } from 'react-router-dom';
import { useSite, useSiteFootprint } from '@modules/services/services-sites';
import { ScreenLoader } from './Dashboard/Dashboard';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';
import { useCompany } from '@modules/services/services-company';
import { MapBackControl } from '@components/molecules/locationMap/MapBackControl';
import { useTranslation } from 'react-i18next';

const Screen: React.FC<Record<string, never>> = () => {
  const company = useCompany()?.data?.description;
  const params = useParams();
  const { t } = useTranslation();

  const { data: site } = useSite(params.id);
  const { data: siteFootprint } = useSiteFootprint(params.id);

  if (!(site && siteFootprint)) {
    return <ScreenLoader />;
  }

  return (
    <MiniMap
      company={company}
      name={site?.name}
      footprint={siteFootprint.footprint}
    >
      <MapBackControl
        title={t('navigation.individual-site')}
        redirection={`/portfolio/${params.id}`}
      />
    </MiniMap>
  );
};
export default withErrorBoundary(Screen);
