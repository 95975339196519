import React from 'react';
import { SourceDefinitionProps } from './SourceDefinition.types';
import { Box, Link, Theme, Typography, useMediaQuery } from '@mui/material';
import { sanitizeUrl } from '@braintree/sanitize-url';

const SourceDefinition: React.FC<SourceDefinitionProps> = ({
  name,
  description,
  href,
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  return (
    <Box>
      <Typography variant={isSmall ? 'paragraph' : 'paragraphSemi'}>
        <Link
          href={sanitizeUrl(href)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </Link>{' '}
        {description}
      </Typography>
    </Box>
  );
};

export default SourceDefinition;
