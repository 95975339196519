import { NAV_BAR_MARGIN } from '@components/organisms/navigation/NavBar/NavBar.styles';
import { FlexBox } from '@components/templates';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
} from '@mui/material';
import { styled } from '@mui/material';

export const AccordionLegend = styled(Accordion)`
  position: absolute;
  z-index: 4;
  bottom: 22px;
  right: ${NAV_BAR_MARGIN};
`;

export const AccordionRisks = styled(AccordionDetails)`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 16px;
  justify-content: center;
  align-items: center;
`;

export const AccordionTitle = styled(AccordionSummary)((/*{ theme }*/) => ({
  flexDirection: 'row-reverse',
  minHeight: 32,
  borderBottom: `3px solid #f0f0f0`,
  [`& .${accordionSummaryClasses.content}`]: {
    justifyContent: 'center',
    textTransform: 'uppercase',
    margin: 0,
  },
}));

export const LegendItemContainer = styled(Box)`
  display: flex;
  gap: 8px;
`;

export const ColorBox = styled(FlexBox)`
  justify-content: center;
`;

export const LegendColor = styled(Box)`
  width: 40px;
  height: 40px;
  background-color: ${({ color }: { color: string }) => color};
`;
