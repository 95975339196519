import { Typography } from '@mui/material';
import React from 'react';
import { ItemContainer } from './InfoSiteField.styles';
import { InfoSiteFieldProps } from './InfoSiteField.types';

const InfoSiteField: React.FC<InfoSiteFieldProps> = ({ title, text }) =>
  text !== null ? (
    <ItemContainer>
      <Typography variant="paragraphMediumSemi">{title}</Typography>
      <Typography variant="paragraphSmall">{text}</Typography>
    </ItemContainer>
  ) : null;

export default InfoSiteField;
