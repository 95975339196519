import { Paper, styled, Typography } from '@mui/material';
import { ResponsiveDirectionBox } from '@components/templates';

export const SectionIcon = styled('img')`
  width: 32px;
  height: 32px;
`;

export const ContactButtonContainer = styled(ResponsiveDirectionBox)`
  gap: 16px 8px;
`;

export const Description = styled(Typography)`
  margin-bottom: 30px;
`;

export const ContactPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up('tablet')]: {
    maxWidth: 459,
  },
}));
