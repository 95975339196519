import React from 'react';
import { RiskPotentialProps } from './RiskPotential.types';
import { useTheme } from '@mui/material';
import { RiskItem } from '@components/atoms/RiskItem';
import { RiskItemProps } from '@components/atoms/RiskItem/RiskItem.types';
import {
  IconContainer,
  ListContainer,
  RiskItemContainer,
  TitleContainer,
} from './RiskPotential.styles';

const RiskPotential: React.FC<RiskPotentialProps> = ({ icon, title, list }) => {
  const theme = useTheme();
  return (
    <RiskItemContainer>
      <IconContainer>{icon}</IconContainer>
      <TitleContainer color={theme.palette[title]?.dark} variant="h1">
        {title}
      </TitleContainer>
      <ListContainer>
        {list.map((item: RiskItemProps) => {
          return <RiskItem key={item.label} {...item} />;
        })}
      </ListContainer>
    </RiskItemContainer>
  );
};

export default RiskPotential;
