import { FlexBox } from '@components/templates';
import { Box, styled } from '@mui/material';

export const ItemContainer = styled(FlexBox)(() => ({
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const GradingContainer = styled(FlexBox)(() => ({
  alignItems: 'center',
  flex: 1,
}));

export const TitleItem = styled(FlexBox)(({ theme }) => ({
  width: 134,
  padding: theme.spacing(0, 0, 0, 1),
  [theme.breakpoints.only('mobile')]: {
    width: 66,
  },
  alignItems: 'center',
}));

export const BarItem = styled(Box)(() => ({
  flex: 1,
}));
