import React from 'react';
import { AllSitesTopSectionProps } from './AllSitesTopSection.types';
import { Link, Typography } from '@mui/material';
import { MapView } from '@components/molecules/portfolio/MapView';
import {
  LeftContainer,
  SourceTextContainer,
  TitleContainer,
  TopSectionContainer,
} from './AllSitesTopSection.styles';

const AllSitesTopSection: React.FC<AllSitesTopSectionProps> = ({
  titleSection,
  descriptionScetion,
  titleMapView,
  titleButtonMapView,
  descriptionMapView,
  textLink,
  openModal,
  sourceText,
}) => {
  return (
    <TopSectionContainer>
      <LeftContainer>
        <TitleContainer>
          <Typography variant="d6">{titleSection}</Typography>
        </TitleContainer>
        <Typography variant="paragraphMedium">
          {descriptionScetion}
          <Link onClick={openModal}>{textLink}</Link>
        </Typography>
        <SourceTextContainer>
          <Typography variant="paragraphSmall">{sourceText}</Typography>
        </SourceTextContainer>
      </LeftContainer>
      <MapView
        title={titleMapView}
        titleButton={titleButtonMapView}
        description={descriptionMapView}
      />
    </TopSectionContainer>
  );
};

export default AllSitesTopSection;
