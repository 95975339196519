import { FlexBox } from '@components/templates';
import { styled, Box, Typography, drawerClasses, Drawer } from '@mui/material';

export const SettingsDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${drawerClasses.paper}`]: {
    gap: 8,
    padding: theme.spacing(0, 2, 2, 2.25),
    alignItems: 'flex-start',
  },
}));

export const SettingsHeader = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const ItemContainer = styled(Typography)(({ isSelected = false }) => ({
  color: isSelected ? '#3D3DAA' : '#343C3D',
}));

export const LegendContainer = styled(FlexBox)(() => ({
  justifyContent: 'space-between',
  width: '100%',
}));
