import React from 'react';
import { ModalRisksTutorialProps } from './ModalRisksTutorial.types';
import { ModalContainer } from '@components/organisms/ModalContainer';
import {
  ListContainer,
  TutoContainer,
  TreeRiskContainer,
  ParagraphContainerMobile,
} from './ModalRisksTutorial.styles';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { dataRiskList } from '@components/molecules/portfolio/RiskComponent/datadef';
import { RiskComponent } from '@components/molecules/portfolio/RiskComponent';
import { RiskComponentProps } from '@components/molecules/portfolio/RiskComponent/RiskComponent.types';
import { dataRiskPotentialList } from '@components/molecules/portfolio/RiskPotential/datadef';
import { RiskPotentialProps } from '@components/molecules/portfolio/RiskPotential/RiskPotential.types';
import { RiskPotential } from '@components/molecules/portfolio/RiskPotential';
import { ParagraphTuto } from '@components/atoms/ParagraphTuto';
import { useTranslation } from 'react-i18next';
import { IntroTutorial } from '@components/molecules/portfolio/IntroTutorial';
import { LabelListTuto } from '../LabelListTuto';
import { RiskGradeTuto } from '../RiskGradeTuto';
import { SourceSection } from '../../../molecules/portfolio/SourceSection';
import { ExpandablesGridsTuto } from '../ExpandablesGridsTuto';
import { TreeRiskMobileIcon } from '@components/atoms/Icons/TreeRiskIconMobile';
import { TreeRiskIcon } from '@components/atoms/Icons/TreeRiskIcon';

const ModalRisksTutorial: React.FC<ModalRisksTutorialProps> = ({
  isOpen,
  closeText,
  onClose,
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  const { t } = useTranslation();
  return (
    <ModalContainer
      isOpen={isOpen}
      closeText={closeText}
      onClose={onClose}
      totalModalWidth={'75%'}
    >
      <TutoContainer>
        <IntroTutorial
          title={t('portfolio.tutorial.natural_hazard')}
          description={t('portfolio.tutorial.natural_hazard_description')}
        />
        <LabelListTuto label={t('portfolio.tutorial.insurance_terms')} />
        <ListContainer>
          {dataRiskList.map((item: RiskComponentProps, index) => (
            <RiskComponent key={`${index}-${item.title}`} {...item} />
          ))}
        </ListContainer>
        {isSmall ? (
          <ParagraphContainerMobile variant="paragraphMedium">
            {t('portfolio.tutorial.trusted_data')}
            {'\n'}
            {t('portfolio.tutorial.likelihood')}
          </ParagraphContainerMobile>
        ) : (
          <>
            <Typography variant="leadSemi">
              {t('portfolio.tutorial.trusted_data')}
            </Typography>
            <Typography variant="leadSemi">
              {t('portfolio.tutorial.likelihood')}
            </Typography>
          </>
        )}
        <ListContainer>
          {dataRiskPotentialList.map((item: RiskPotentialProps, index) => (
            <RiskPotential key={`${index}-${item.title}`} {...item} />
          ))}
        </ListContainer>
        <ParagraphTuto
          title={t('portfolio.tutorial.individual_hazard')}
          description={t('portfolio.tutorial.individual_hazard_description')}
        />
        {isSmall && (
          <ParagraphTuto
            title={t('portfolio.tutorial.compound_risk')}
            description={t('portfolio.tutorial.compound_risk_description')}
          />
        )}
        <RiskGradeTuto />
        {!isSmall && (
          <ParagraphTuto
            title={t('portfolio.tutorial.compound_risk')}
            description={t('portfolio.tutorial.compound_risk_description')}
          />
        )}
        <TreeRiskContainer>
          {isSmall ? <TreeRiskMobileIcon /> : <TreeRiskIcon />}
        </TreeRiskContainer>
        {!isSmall && (
          <Typography variant="leadSemi">
            {t('portfolio.tutorial.click_hazard')}
          </Typography>
        )}
        <ExpandablesGridsTuto />
        <SourceSection />
      </TutoContainer>
    </ModalContainer>
  );
};

export default ModalRisksTutorial;
