import React from 'react';
import { ExplanationSquareProps } from './ExplanationSquare.types';
import { Link, Typography } from '@mui/material';
import {
  DescriptionContainer,
  SquareContainer,
} from './ExplanationSquare.styles';

const ExplanationSquare: React.FC<ExplanationSquareProps> = ({
  description,
  redirectionText,
  sourceText,
  openModal,
}) => {
  return (
    <SquareContainer>
      {description && (
        <DescriptionContainer sx={{ marginBottom: '10px' }}>
          <Typography variant="paragraphSmallSemi">
            {description} <Link onClick={openModal}>{redirectionText}</Link>
          </Typography>
        </DescriptionContainer>
      )}
      <Typography variant="paragraphSmall">{sourceText}</Typography>
    </SquareContainer>
  );
};

export default ExplanationSquare;
