import { Box, styled } from '@mui/material';

/* Screen Templates */

export const Page = styled(Box)(() => ({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
}));

export const PageContent = styled(Page)(({ theme }) => ({
  gap: theme.spacing(3),
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(3, 2.5),
  },
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(3, 3),
  },
}));

export const PageList = styled(Page)(({ theme }) => ({
  gap: theme.spacing(4),
  [theme.breakpoints.down('desktop')]: {
    padding: theme.spacing(4, 0),
  },
  [theme.breakpoints.up('desktop')]: {
    padding: theme.spacing(4, '12%', 10),
  },
}));

export const PageCatalog = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  gap: theme.spacing(2),
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(4, 2.5),
  },
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(6, 13),
  },
  [theme.breakpoints.up('desktop')]: {
    padding: theme.spacing(6, 17),
  },
}));

export const PageDoubleColumn = styled(PageContent)(
  ({ theme, breakpoint }) => ({
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(breakpoint || 'tablet')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    ['& > *']: {
      flex: 1,
    },
  })
);

export const PageAlert = styled(PageContent)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  [theme.breakpoints.up('tablet')]: {
    alignItems: 'center',
    padding: theme.spacing(10, 2.5),
  },
}));

export const PageStep = styled(PageAlert)(({ theme }) => ({
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(5, 2.5),
  },
}));
