import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { ButtonCloseProps } from './ButtonClose.types';

const ButtonClose: React.FC<ButtonCloseProps> = ({ onClose, title }) => {
  return (
    <Button size="small" startIcon={<Close />} onClick={onClose}>
      {title}
    </Button>
  );
};

export default ButtonClose;
