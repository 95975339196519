export const getMarkerCategory = (
  value: { x: number; y: number },
  max: number
) => {
  if (value.x <= 5) {
    return 0;
  }
  if (value.x >= 75 && value.y > (2 / 3) * max) {
    return 5;
  }
  if (
    (value.y >= (2 / 3) * max && value.x >= 50) ||
    (value.y >= (1 / 3) * max && value.x >= 75)
  ) {
    return 4;
  }
  if (
    (value.x >= 25 && value.y >= (2 / 3) * max) ||
    (value.y >= (1 / 3) * max && value.x >= 50) ||
    value.x >= 75
  ) {
    return 3;
  }
  if (
    value.y >= (2 / 3) * max ||
    (value.y >= (1 / 3) * max && value.x >= 25) ||
    value.x >= 50
  ) {
    return 2;
  }
  if (value.y >= (1 / 3) * max || value.x >= 25) {
    return 1;
  } else {
    return 0;
  }
};
