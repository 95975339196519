import { FlexBox } from '@components/templates';
import { styled, Typography } from '@mui/material';

export const ColorBox = styled(FlexBox)`
  justify-content: center;
  height: 18px;
  background-color: ${({ color }: { color: string }) => color};
  width: 100%;
`;

export const TextLegendItemContainer = styled(Typography)(() => ({
  margin: 'auto',
  color: 'white',
}));
