import { Box, styled } from '@mui/material';

export const PinContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -54px;
  left: -27px;
`;

export const PinText = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
