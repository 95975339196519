import { FlexColumnBox } from '@components/templates';
import { Box, styled } from '@mui/material';

export const RiskGradeContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.not('mobile')]: {
    padding: theme.spacing(0, 1),
  },
}));

export const ExampleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  [theme.breakpoints.not('mobile')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    height: 'auto',
    gap: 40,
  },
}));

export const ListInfoRiskContainer = styled(FlexColumnBox)(({ theme }) => ({
  gap: 16,
  margin: theme.spacing(3, 0, 0, 0),
}));

export const ExplanationContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.not('mobile')]: {
    flex: '1 1 500px',
  },
  alignSelf: 'flex-end',
}));
