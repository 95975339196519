import React from 'react';
import { ButtonExpandableProps } from './ButtonExpandable.types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button } from '@mui/material';

const ButtonExpandable: React.FC<ButtonExpandableProps> = ({
  onClickButton,
  isExpand,
}) => {
  return (
    <Button
      id="expand-typo-button"
      variant="text"
      size="xs"
      onClick={onClickButton}
      endIcon={isExpand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    >
      {isExpand ? 'Show less' : 'Show more'}
    </Button>
  );
};

export default ButtonExpandable;
