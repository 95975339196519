import {
  Next,
  Previous,
} from '@components/molecules/portfolio/SiteGrid/SiteGrid';
import { SiteItem } from '@components/molecules/portfolio/SiteItem';
import { TitlePopup } from '@components/molecules/portfolio/Title/TitlePopup';
import { SiteMapFeature } from '@modules/types/sites.types';
import { Pagination, PaginationItem, Typography } from '@mui/material';
import { sitesItemPerPage } from '@utils/numbers';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListSiteContainer,
  PaginationContainer,
  TitleListContainer,
} from './ListSiteSummary.styles';
import { ListSiteSummaryProps } from './ListSiteSummary.types';

const ListSiteSummary: React.FC<ListSiteSummaryProps> = ({
  sites,
  onClick,
  companyCurrency,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [listOfSitesWithPaginaton, setListOfSitesWithPaginaton] = useState(
    sites.length >= 3 ? sites.slice(0, 3) : sites
  );
  return (
    <ListSiteContainer>
      <TitlePopup title={sites[0].name} />
      <TitleListContainer>
        <Typography variant="paragraphSemi">
          {t(`site.titleList`, {
            numberOfSites: sites.length,
          })}
        </Typography>
      </TitleListContainer>
      {listOfSitesWithPaginaton.map((site: SiteMapFeature, index: number) => {
        return (
          <SiteItem
            key={site.id}
            site={site}
            onClick={() => onClick(site)}
            isLastItem={listOfSitesWithPaginaton.length === index + 1}
            titleField={t('site.fields.tiv')}
            companyCurrency={companyCurrency}
          />
        );
      })}
      {sites.length > 3 && (
        <PaginationContainer>
          <Pagination
            id="map-site-summary-popup-pagination"
            count={Math.ceil(sites.length / sitesItemPerPage)}
            page={page}
            boundaryCount={1}
            siblingCount={0}
            size="small"
            renderItem={(item) => {
              return (
                <PaginationItem
                  id={`portfolio-pagination-button-${item.page}`}
                  slots={{ previous: Previous, next: Next }}
                  {...item}
                />
              );
            }}
            onChange={(event, value) => {
              setPage(value);
              setListOfSitesWithPaginaton(
                sites.slice(
                  (value - 1) * sitesItemPerPage,
                  value * sitesItemPerPage
                )
              );
            }}
          />
        </PaginationContainer>
      )}
    </ListSiteContainer>
  );
};

export default ListSiteSummary;
