import { TopWhiteSection } from '@components/molecules';
import { GradientSection, PageStep, PaperStep } from '@components/templates';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import RecommendationStepLoading from '@components/organisms/resources/RecommendationStep/RecommendationStep.loading';

const RecommendationLoader: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();

  return (
    <>
      <GradientSection
        color={'linear-gradient(102.86deg, #015A66 0.05%, #41949F 101.01%);'}
      >
        <TopWhiteSection backText={t('navigation.back')}>
          <Skeleton width={310} />
        </TopWhiteSection>
      </GradientSection>
      <PageStep>
        <PaperStep>
          <RecommendationStepLoading />
        </PaperStep>
      </PageStep>
    </>
  );
};

export default RecommendationLoader;
