import React, { Component, ReactNode } from 'react';
import { TopSection } from '@components/molecules';
import { ErrorContent, GradientSection } from '@components/templates';
import { Error404, ErrorGeneric } from '@components/organisms';

interface Props {
  action?: () => void;
  error?: '404' | Error | null;
  children?: ReactNode;
}

interface State {
  error: Error | null;
}
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public render() {
    if (this.props.error || this.state.error) {
      return (
        <>
          <GradientSection>
            <TopSection
              headline="AXA SMART SITES"
              title="Something went wrong "
            />
          </GradientSection>
          <ErrorContent>
            {this.props.error === '404' ? (
              <Error404 />
            ) : (
              <ErrorGeneric action={this.props.action} />
            )}
          </ErrorContent>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
