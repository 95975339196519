import React from 'react';
import { MapLegendMobileProps } from './MapLegendMobile.types';
import {
  ItemContainer,
  LegendContainer,
  SettingsHeader,
  SettingsDrawer,
} from './MapLegendMobile.styles';
import { ButtonClose } from '@components/atoms/ButtonClose';
import LegendItemMobile from '../ItemLegendMobile/LegendItemMobile';

const MapLegendMobile: React.FC<MapLegendMobileProps> = ({
  selected,
  selectedField,
  title,
  buttonCloseTitle,
  onClose,
  ...props
}) => {
  return (
    <SettingsDrawer anchor="bottom" onClose={onClose} {...props}>
      <SettingsHeader>
        <ButtonClose onClose={onClose} title={buttonCloseTitle} />
      </SettingsHeader>
      <ItemContainer variant="h5" isSelected={true}>
        {selected && selectedField.toUpperCase()}
      </ItemContainer>
      <ItemContainer variant="h5">{title.toUpperCase()}</ItemContainer>
      <LegendContainer>
        <ItemContainer variant="h5">
          {selected && selected.legend && selected.legend.bottom.toUpperCase()}
        </ItemContainer>
        <ItemContainer variant="h5">
          {selected && selected.legend && selected.legend.top.toUpperCase()}
        </ItemContainer>
      </LegendContainer>
      <LegendContainer>
        {selected &&
          selected.scale &&
          selected.scale
            .map(
              (item) => item && <LegendItemMobile key={item.label} {...item} />
            )
            .reverse()}
      </LegendContainer>
    </SettingsDrawer>
  );
};

export default MapLegendMobile;
