import {
  EventDetail,
  EventCategories,
  EventLayerProperties,
} from '@modules/types/events.types';

type FeatureCollectionEvents = GeoJSON.FeatureCollection<
  GeoJSON.MultiPolygon | GeoJSON.Polygon,
  { category: EventCategories; kind: string }
>;

type FeatureEvent = GeoJSON.Feature<
  GeoJSON.MultiPolygon | GeoJSON.Polygon,
  EventLayerProperties
>;

export const mergeFootprints = (
  events?: EventDetail[]
): FeatureCollectionEvents => {
  const features =
    events?.reduce((accum, event) => {
      return accum
        .concat({
          ...event.areaOfInterest,
          properties: {
            category: event.category,
            status: event.status,
            id: event.id,
            kind: 'areaOfInterest',
          },
        })
        .concat({
          ...event.delineation,
          properties: {
            category: event.category,
            status: event.status,
            id: event.id,
            kind: 'delineation',
          },
        });
    }, [] as FeatureEvent[]) || [];

  return {
    type: 'FeatureCollection',
    features,
  };
};
