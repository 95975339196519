import {
  AddressSite,
  AllPerilsRisk,
  GeophysicalInsuredRisk,
  HydrologicalInsuredRisk,
  MeteorologicalInsuredRisk,
  SiteSummary,
  WildfireRisk,
} from '@modules/types';
import { GridSortItem, GridSortModel } from '@mui/x-data-grid';
import countryMap from '@utils/countryMap.json';

export const sortParams = (sort?: string | null): GridSortModel | undefined =>
  sort?.split(',').map((element) => {
    const [field, sort] = element.split(' ');
    return { field, sort } as GridSortItem;
  });

export const formatCountry = (iso: string) =>
  (countryMap as { [key: string]: { name: string } })[iso]?.name ||
  'No country';

export const formatCountries = (countries: string[]) =>
  countries.map(formatCountry).join(', ');

export const extractCountry = (countryIso2?: string) =>
  countryIso2 ? formatCountry(countryIso2) : 'No country';

export const formatAddress = (address: AddressSite) =>
  address.street || address.city
    ? address.street && address.city
      ? [address?.street, address?.city].filter(String).join(',\n')
      : address.street || address.city
    : 'No address';

export const geophysicalRisks = [
  'earthquake',
  'volcano',
  'tsunami',
] as (keyof GeophysicalInsuredRisk)[];

export const hydrologicalRisks = [
  'riverineFlood',
  'surfaceWaterFlood',
  'coastalFlood',
] as (keyof HydrologicalInsuredRisk)[];

export const meteorologicalRisks = [
  'tropicalCyclone',
  'extraTropicalCyclone',
  'hailstorm',
  'tornado',
  'lightning',
] as (keyof MeteorologicalInsuredRisk)[];
export const wildfireCategoryRisks = ['wildfire'] as (keyof WildfireRisk)[];

export const allCategoryRiskGrades: {
  key: string;
  field: keyof SiteSummary['categoryRiskGradeSummary'];
}[] = [
  { key: 'geophysical', field: 'geophysical' },
  { key: 'hydrological', field: 'hydrological' },
  { key: 'meteorological', field: 'meteorological' },
  { key: 'wildfireCategory', field: 'wildfire' },
];

export const allRisks = (geophysicalRisks as (keyof AllPerilsRisk)[])
  .concat(hydrologicalRisks)
  .concat(meteorologicalRisks)
  .concat(wildfireCategoryRisks);

export const allGeocodingResolutions = [
  'VERIFIED',
  'ROOFTOP',
  'PARCEL_CENTROID',
  'STREET',
  'CLIENT_COORDINATES',
  'STREET_CENTROID',
  'CITY',
  'ZIPCODE',
  'STATE',
  'COUNTRY',
  'NO_GEOCODE',
];
