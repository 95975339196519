import { ArrowBack } from '@mui/icons-material';
import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import { BackButton } from './MapBackControl.styles';
import { MapBackControlProps } from './MapBackControl.types';

const MapBackControl: React.FC<MapBackControlProps> = ({
  title,
  redirection,
}) => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );

  return (
    <BackButton
      id="map-back"
      href={redirection}
      size="small"
      variant="contained-unchecked"
      startIcon={!isSmall ? <ArrowBack /> : undefined}
    >
      {!isSmall ? title : <ArrowBack />}
    </BackButton>
  );
};

export default MapBackControl;
