import React from 'react';
import { Skeleton, Typography } from '@mui/material';
import {
  BackButton,
  RedLine,
  Title,
  TopSectionStack,
} from './TopSection.styles';
import { TopSectionProps } from './TopSection.types';
import ScopedDark from '@components/themes/ScopedDark';
import { ArrowBack } from '@mui/icons-material';
import { FlexBox } from '@components/templates';

const TopSectionLoader: React.FC<TopSectionProps> = ({
  children,
  headline,
  back,
}) => {
  return (
    <TopSectionStack $marginTop={!back}>
      <FlexBox>
        <ScopedDark>
          {!!back && (
            <BackButton
              id="back"
              aria-label="back"
              size="small"
              startIcon={<ArrowBack fontSize="large" />}
            >
              <Skeleton width={200} />
            </BackButton>
          )}
          <RedLine />
          {headline && (
            <Typography id="page-header-headline" variant="h3">
              <Skeleton />
            </Typography>
          )}
          <Title id="page-header-title" headline={headline} variant="d5">
            <Skeleton width={250} />
          </Title>
        </ScopedDark>
      </FlexBox>
      {children}
    </TopSectionStack>
  );
};

export default TopSectionLoader;
