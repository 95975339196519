import React from 'react';
import { ExpandablesGridsTutoProps } from './ExpandablesGridsTuto.types';
import {
  ExpandablesContainer,
  ListContainerGrid,
  SeparatorExpandableItem,
} from './ExpandablesGridsTuto.styles';
import { ExpandableItem } from '@components/molecules/ExpandableItem';
import { RiskPotentialTitle } from '@components/molecules/portfolio/RiskPotentialTitle';
import {
  GeophysicalIcon,
  HydrologicalIcon,
  MeteorologicalIcon,
  WildfireIcon,
} from '@components/atoms/Icons/icons';
import { InfoScoreGrid } from '@components/molecules/portfolio/InfoScoreGrid';
import {
  itemsGradeWildfire,
  listGridGeophysical,
  listGridHydrological,
  listGridMeteorologicalFirstSection,
  listGridMeteorologicalSecondSection,
} from '@components/molecules/portfolio/InfoScoreGrid/datadef';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExplanationPga } from '@components/atoms/ExplanationPga';

const ExpandablesGridsTuto: React.FC<ExpandablesGridsTutoProps> = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <ExpandablesContainer>
      <ExpandableItem
        title={t('portfolio.tutorial.geophysical.title')}
        isOpen={false}
      >
        <RiskPotentialTitle
          icon={<GeophysicalIcon />}
          title={t('portfolio.tutorial.geophysical.title')}
        />
        <ListContainerGrid>
          {listGridGeophysical.map((grid) => {
            return (
              <InfoScoreGrid
                key={`Info_score_grid_${grid.title}`}
                title={t(`portfolio.tutorial.geophysical.${grid.title}_source`)}
                items={grid.items}
                color={theme.palette['geophysical']?.main}
              />
            );
          })}
        </ListContainerGrid>
      </ExpandableItem>
      <SeparatorExpandableItem />
      <ExpandableItem
        title={t('portfolio.tutorial.hydrological.title')}
        isOpen={false}
      >
        <RiskPotentialTitle
          icon={<HydrologicalIcon />}
          title={t('portfolio.tutorial.hydrological.title')}
        />
        <ListContainerGrid>
          {listGridHydrological.map((grid) => {
            return (
              <InfoScoreGrid
                key={`Info_score_grid_${grid.title}`}
                title={t(
                  `portfolio.tutorial.hydrological.${grid.title}_source`
                )}
                items={grid.items}
                color={theme.palette['hydrological']?.main}
              />
            );
          })}
        </ListContainerGrid>
        <ExplanationPga
          title={t('portfolio.tutorial.hydrological.return_period')}
          description={t(
            'portfolio.tutorial.hydrological.return_period_description'
          )}
        />
      </ExpandableItem>
      <SeparatorExpandableItem />
      <ExpandableItem
        title={t('portfolio.tutorial.meteorological.title')}
        isOpen={false}
      >
        <RiskPotentialTitle
          icon={<MeteorologicalIcon />}
          title={t('portfolio.tutorial.meteorological.title')}
        />
        <ListContainerGrid>
          {listGridMeteorologicalFirstSection.map((grid) => {
            return (
              <InfoScoreGrid
                key={`Info_score_grid_${grid.title}`}
                title={t(
                  `portfolio.tutorial.meteorological.${grid.title}_source`
                )}
                items={grid.items}
                color={theme.palette['meteorological']?.main}
              />
            );
          })}
        </ListContainerGrid>
        <ListContainerGrid>
          {listGridMeteorologicalSecondSection.map((grid) => {
            return (
              <InfoScoreGrid
                key={`Info_score_grid_${grid.title}`}
                title={t(
                  `portfolio.tutorial.meteorological.${grid.title}_source`
                )}
                items={grid.items}
                color={theme.palette['meteorological']?.main}
              />
            );
          })}
        </ListContainerGrid>
      </ExpandableItem>
      <SeparatorExpandableItem />
      <ExpandableItem
        title={t('portfolio.tutorial.wildfire.title')}
        isOpen={false}
      >
        <RiskPotentialTitle
          icon={<WildfireIcon />}
          title={t('portfolio.tutorial.wildfire.title')}
        />
        <ListContainerGrid>
          <InfoScoreGrid
            title={t('portfolio.tutorial.wildfire.wildfire_source')}
            items={itemsGradeWildfire}
            color={theme.palette['wildfire']?.main}
          />
        </ListContainerGrid>
      </ExpandableItem>
    </ExpandablesContainer>
  );
};

export default ExpandablesGridsTuto;
