import { Box, styled } from '@mui/material';

export const SectionIcon = styled('img')`
  width: 40px;
  height: 40px;
`;

export const MarginTooltip = styled(Box)`
  padding-left: 1px;
`;
