import React, { useMemo } from 'react';
import { ScreenLoader } from './Dashboard/Dashboard';
import {
  usePortfolioValue,
  useSiteList,
} from '@modules/services/services-sites';
import { GlobalMap } from '@components/organisms/map/GlobalMap';
import { mapFilters } from '@components/organisms/map/GlobalMap/filters';
import { useTranslation } from 'react-i18next';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';
import { useCompany } from '@modules/services/services-company';

const Screen: React.FC<Record<string, never>> = () => {
  const { data: sites } = useSiteList();
  const { data: portfolioValue } = usePortfolioValue();
  const { data: company } = useCompany();
  const { t } = useTranslation();
  const mapStyles = useMemo(
    () => [
      {
        id: 'map-light',
        style: 'mapbox://styles/mapbox/light-v11',
        icon: '/map-light.jpg',
        label: t(`portfolio.map.styles.map-light`),
      },
      {
        id: 'map-streets',
        style: '/map-style-streets-v11.json',
        icon: '/map-streets.jpg',
        label: t(`portfolio.map.styles.map-streets`),
      },
      {
        id: 'map-satellite',
        style: 'mapbox://styles/mapbox/satellite-v9',
        icon: '/map-satellite.jpg',
        label: t(`portfolio.map.styles.map-satellite`),
      },
    ],
    [t]
  );
  if (!(sites && portfolioValue)) {
    return <ScreenLoader />;
  }

  return (
    <GlobalMap
      data={sites.items}
      totalValue={portfolioValue.total}
      mapStyles={mapStyles}
      filters={mapFilters}
      companyCurrency={company?.currencyIso3}
    />
  );
};

export default withErrorBoundary(Screen);
