import { AppBar } from '@mui/material';
import React from 'react';
import {
  BrandBar,
  BurgerButton,
  LogoButton,
  LogoutButton,
  NavImage,
  ProminentToolBar,
  ServiceButton,
  ServiceTitle,
  ShortcutsBar,
} from './NavBar.styles';
import { NavBarProps } from './NavBar.types';
import MenuIcon from '@mui/icons-material/Menu';
import { PersonOutlineOutlined } from '@mui/icons-material';
import { SMART_SERVICES_PRIVATE } from '@utils/urls';

const NavBar: React.FC<NavBarProps> = ({
  onLogout,
  onMenuClick,
  appName,
  portalNavigation,
  logout,
  ...props
}) => {
  return (
    <AppBar
      position="static"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      {...props}
    >
      <ProminentToolBar id="header-section">
        <BrandBar>
          <LogoButton
            id="navigation-button-home"
            onClick={onMenuClick(false)}
            href="/"
          >
            <NavImage src="/logo.png" alt="axa logo" />
          </LogoButton>
          <ServiceTitle id="title-header" variant="h2" color="primary">
            {appName}
          </ServiceTitle>
          <BurgerButton
            id="navigation-button-menu"
            color="primary"
            size="large"
            edge="end"
            aria-label="menu"
            onClick={onMenuClick()}
          >
            <MenuIcon fontSize="medium" sx={{ height: 32, width: 32 }} />
          </BurgerButton>
        </BrandBar>
        <ShortcutsBar>
          <ServiceButton
            id="navigation-button-portal"
            size="small"
            href={SMART_SERVICES_PRIVATE}
          >
            {portalNavigation}
          </ServiceButton>
          <LogoutButton
            id="navigation-button-logout"
            variant="outlined"
            size="small"
            startIcon={<PersonOutlineOutlined />}
            onClick={onLogout}
          >
            {logout}
          </LogoutButton>
        </ShortcutsBar>
      </ProminentToolBar>
    </AppBar>
  );
};

export default NavBar;
