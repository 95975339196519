import React from 'react';
import { Typography } from '@mui/material';
import {} from './InfoRiskSiteField.styles';
import { InfoRiskSiteFieldProps } from './InfoRiskSiteField.types';
import { ChipGrade } from '@components/atoms/ChipGrade';

const InfoRiskSiteField: React.FC<InfoRiskSiteFieldProps> = ({
  title,
  risk,
  variant = 'outlined',
}) => (
  <>
    <Typography variant="paragraphMediumSemi">{title}</Typography>
    <ChipGrade variant={variant} label={risk?.label} color={risk?.color} />
  </>
);

export default InfoRiskSiteField;
