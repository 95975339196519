import { FlexBox, FlexColumnBox } from '@components/templates';
import { Button, styled } from '@mui/material';

export const ContainerDetail = styled(FlexBox)(({ theme }) => ({
  [theme.breakpoints.up('tablet')]: {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.not('tablet')]: {
    flexDirection: 'column',
  },
  gap: theme.spacing(1.25, 5),
  margin: theme.spacing(3, 0, 1.25),
}));

export const ActionContainer = styled(FlexColumnBox)(({ theme }) => ({
  alignSelf: 'center',
  margin: theme.spacing(3, 0, 0),
  gap: theme.spacing(2),
  [theme.breakpoints.only('tablet')]: {
    flexDirection: 'row',
  },
}));

export const ButtonDetail = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-start',
  margin: theme.spacing(0, 0, 3),
  boxShadow: theme.shadows[2],
}));

export const EventDetailTopContainer = styled(FlexBox)(({ theme }) => ({
  margin: theme.spacing(0, 0, 1.5, 0),
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ScrollBox = styled(FlexColumnBox)`
  overflow-y: auto;
`;

export const NumberOfSitesContainer = styled(FlexBox)(({ theme }) => ({
  [theme.breakpoints.only('desktop')]: {
    margin: theme.spacing(3, 0),
  },
  margin: theme.spacing(2, 0),
}));
