import { FormControlLabel, RadioGroup, styled, Theme } from '@mui/material';

export const RadioGridGroup = styled(RadioGroup)(
  ({ theme, mode = 'grid' }: { theme: Theme; mode: 'column' | 'grid' }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(1),
    [theme.breakpoints.up('desktop')]: {
      gridTemplateColumns:
        mode === 'grid' ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
    },
  })
);

export const RadioLabel = styled(FormControlLabel)``;
