import React from 'react';
import { RiskPotentialTitleProps } from './RiskPotentialTitle.types';
import { useTheme } from '@mui/material';
import {
  LabelContainer,
  RiskTitleContainer,
} from './RiskPotentialTitle.styles';

const RiskPotentialTitle: React.FC<RiskPotentialTitleProps> = ({
  title,
  icon,
}) => {
  const theme = useTheme();
  return (
    <RiskTitleContainer>
      {icon}
      <LabelContainer color={theme.palette[title]?.dark} variant="h1">
        {title}
      </LabelContainer>
    </RiskTitleContainer>
  );
};

export default RiskPotentialTitle;
