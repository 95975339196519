import { NAV_BAR_MARGIN } from '@components/organisms/navigation/NavBar/NavBar.styles';
import { Box, Button, Select, styled } from '@mui/material';

export const FiltersContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '25%',
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  gap: theme.spacing(1),
  right: '0%',
  margin: theme.spacing(2),
  flexDirection: 'column',
  [theme.breakpoints.only('desktop')]: {
    margin: theme.spacing(2.25, 0),
    gap: theme.spacing(2),
    right: NAV_BAR_MARGIN,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
}));

export const ButtonMap = styled<typeof Button>(Button)(({ theme }) => ({
  maxWidth: 262,
  flex: 1,
  justifyContent: 'flex-start',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(0, 3),
  marginTop: 1, // align with select
  [theme.breakpoints.not('desktop')]: {
    width: 220,
    justifyContent: 'center',
  },
}));

export const SelectMap = styled(Select)(({ theme }) => ({
  flex: 1,
  padding: 0,
  maxWidth: 262,
  [theme.breakpoints.not('desktop')]: {
    width: 220,
    justifyContent: 'center',
  },
  ['& > div']: {
    margin: theme.spacing(0.6, 1),
  },
}));
