import React from 'react';
import { ArrowButtonBigProps } from './ArrowButtonBig.types';

import { ArrowRight } from '@components/atoms/Icons/icons';
import { Typography } from '@mui/material';
import { Head, StyledButton, LeftIcon } from './ArrowButtonBig.styles';

const ArrowButtonBig: React.FC<ArrowButtonBigProps> = ({
  icon,
  alt,
  title,
  description,
  ...props
}) => {
  return (
    <StyledButton {...props}>
      <Head>
        <LeftIcon src={icon} alt={alt} />
        <ArrowRight />
      </Head>
      <Typography variant="h5">{title.toUpperCase()}</Typography>
      <Typography variant="paragraphSmall">{description}</Typography>
    </StyledButton>
  );
};

export default ArrowButtonBig;
