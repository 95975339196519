import React from 'react';
import { DetailItemEventProps } from './DetailItemEvent.types';
import { DetailItem } from './DetailItemEvent.styles';
import { Typography } from '@mui/material';

const DetailItemEvent: React.FC<DetailItemEventProps> = ({
  type,
  children,
  minWidth,
}) => {
  return (
    (children && (
      <DetailItem minWidth={minWidth}>
        <Typography variant="paragraphMediumSemi">{type}</Typography>
        {children}
      </DetailItem>
    )) ||
    null
  );
};

export default DetailItemEvent;
