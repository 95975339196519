import { ExpandLess } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { SelectMapStyle } from '../SelectMapStyle';
import {
  AccordionDetailsContainer,
  AccordionMapStyle,
  AccordionTitle,
} from './MapStyleControl.styles';
import { MapStyleControlProps } from './MapStyleControl.types';

const MapStyleControl: React.FC<MapStyleControlProps> = (props) => {
  return (
    <AccordionMapStyle id="map-legend-accordion">
      <AccordionTitle expandIcon={<ExpandLess />}>
        <Typography variant="h5">{props.title}</Typography>
      </AccordionTitle>
      <AccordionDetailsContainer>
        <SelectMapStyle {...props} />
      </AccordionDetailsContainer>
    </AccordionMapStyle>
  );
};

export default MapStyleControl;
