import React from 'react';
import { Avatar, Skeleton, Stack, Typography } from '@mui/material';
import { ContactStack } from './Contact.styles';

export default function ContactSkeleton({
  size = 'medium',
}: {
  size?: string;
}) {
  return (
    <ContactStack direction="row">
      <Skeleton variant="circular">
        <Avatar className={size} />
      </Skeleton>
      <Stack spacing={0}>
        <Typography variant={size === 'large' ? 'h1' : 'h2'}>
          <Skeleton width={170} />
        </Typography>
        <Typography variant={size === 'large' ? 'lead' : 'paragraphSmall'}>
          <Skeleton width={100} />
        </Typography>
      </Stack>
    </ContactStack>
  );
}
