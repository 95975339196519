import { InsuredValue } from '@modules/types';

export interface BarDef {
  field: keyof InsuredValue;
  name: string;
  color: string;
  value?: number;
}

export const chartDef = [
  { field: 'building', name: 'Building', color: '#5C5CB7' },
  {
    field: 'businessInterruption',
    name: 'Business Interruption',
    color: '#AC7074',
  },
  { field: 'content', name: 'Contents', color: '#738D87' },
] as BarDef[];

export const extractBars = (data: Record<string, number>) =>
  chartDef
    ?.map((type) => ({
      ...type,
      value: data[type.field],
    }))
    .filter((type) => type.value > 0)
    .sort((a, b) => b.value - a.value);
