import { FlexBox } from '@components/templates';
import { Box, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const ExpandablesContainer = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[1],
}));

export const SeparatorExpandableItem = styled(FlexBox)(() => ({
  borderBottom: `1px solid ${grey[200]}`,
}));

export const ListContainerGrid = styled(Box)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  [theme.breakpoints.not('mobile')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    columnGap: 32,
    rowGap: 16,
  },
}));
