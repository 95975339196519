import React, { useLayoutEffect, useRef, useState } from 'react';
import { FlexColumnBox } from '@components/templates';
import { StyledTypography } from './ExpandableTypography.styles';
import { ExpandableTypographyProps } from './ExpandableTypography.types';
import { useWindowWidth } from '@utils/window';
import DOMPurify from 'isomorphic-dompurify';
import { ButtonExpandable } from '../ButtonExpandable';
import { Typography } from '@mui/material';

const EllipsedText: React.FC<ExpandableTypographyProps> = ({
  maxHeight = 120,
  children,
  type,
}) => {
  const width = useWindowWidth();
  const [expandState, setExpandState] = useState({
    expandable: false,
    isExpanded: true,
  });

  const ref = useRef<HTMLDivElement | null>(null);

  const handleToggleExpand = () => {
    setExpandState((prevState) => ({
      ...prevState,
      isExpanded: !prevState.isExpanded,
    }));
  };

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight > maxHeight * 1.2) {
      setExpandState({ expandable: true, isExpanded: false });
    } else if (ref.current && ref.current.clientHeight < maxHeight) {
      setExpandState({
        expandable: false,
        isExpanded: true,
      });
    }
  }, [ref.current, width, children]);

  const childrenCleanFromHTMLTags = DOMPurify.sanitize(children, {
    USE_PROFILES: { html: false },
  });
  return (
    <FlexColumnBox>
      <Typography variant="paragraphMediumSemi">{type}</Typography>
      <StyledTypography
        ref={ref}
        variant={'paragraphSmall'}
        $isExpanded={expandState.isExpanded}
        $maxHeight={maxHeight}
      >
        {childrenCleanFromHTMLTags}
      </StyledTypography>
      {expandState.expandable && (
        <ButtonExpandable
          onClickButton={handleToggleExpand}
          isExpand={expandState.isExpanded}
        />
      )}
    </FlexColumnBox>
  );
};

export default EllipsedText;
