import { riskGradeColors } from '@utils/numbers';

export const RiskInformationColumns: { field: string; headerName: string }[] = [
  {
    field: 'zone',
    headerName: 'Zone',
  },
  {
    field: 'score',
    headerName: 'Score',
  },
  {
    field: 'grading',
    headerName: 'Grading',
  },
];

export const itemsGradeEarthquake = [
  {
    id: 1,
    zone: 'PGA = <0.0g',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: 'PGA = 0.09-0.15g',
    score: 1,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: 'PGA = 0.16-0.30g',
    score: 50,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 4,
    zone: 'PGA = 0.31-0.50g',
    score: 75,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 5,
    zone: 'PGA = >0.50g',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const itemsGradeTsunami = [
  {
    id: 1,
    zone: 'Negligible hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: 'Minimal flood risk',
    score: 25,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: '1:1000 years return period',
    score: 50,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 4,
    zone: '1:500 years',
    score: 75,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 5,
    zone: '1:100 years',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const itemsGradeVolcano = [
  {
    id: 1,
    zone: 'Negligible hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: 'Unclassified',
    score: 10,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: 'Zone 1',
    score: 25,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 4,
    zone: 'Zone 2',
    score: 50,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 5,
    zone: 'Zone 3',
    score: 99,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const itemsGradeRiverine = [
  {
    id: 1,
    zone: 'Negligible hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: '1:1500 years return period',
    score: 10,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: '1:1000 years',
    score: 20,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 4,
    zone: '1:500 years',
    score: 40,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 5,
    zone: '1:200 years',
    score: 50,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 6,
    zone: '1:100 years',
    score: 70,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 7,
    zone: '1:75 years',
    score: 75,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 8,
    zone: '1:50 years',
    score: 90,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
  {
    id: 9,
    zone: '1:20 years',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const itemsGradeSurfaceWater = [
  {
    id: 1,
    zone: 'Negligible hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: '1:1500 years return period',
    score: 10,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: '1:1000 years',
    score: 20,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 4,
    zone: '1:500 years',
    score: 40,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 5,
    zone: '1:200 years',
    score: 50,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 6,
    zone: '1:100 years',
    score: 70,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 7,
    zone: '1:75 years',
    score: 75,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 8,
    zone: '1:50 years',
    score: 90,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
  {
    id: 9,
    zone: '1:20 years',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const itemsGradeCostalFlood = [
  {
    id: 1,
    zone: 'Negligible hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: '1:1000 years return period',
    score: 25,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: '1:500 years',
    score: 40,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 4,
    zone: '1:100 years',
    score: 70,
    grading: { label: 'High', color: riskGradeColors.D },
  },
];

export const itemsGradeTropical = [
  {
    id: 1,
    zone: 'No hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: 'Zone 0: 76 -141 km/h',
    score: 15,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: 'Zone 1: 142 -184 km/h',
    score: 35,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 4,
    zone: 'Zone 2: 185 -212 km/h',
    score: 65,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 5,
    zone: 'Zone 3: 213 -251 km/h',
    score: 85,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 6,
    zone: 'Zone 4: 252 -299 km/h',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
  {
    id: 7,
    zone: 'Zone 5: >300 km/h',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const itemsGradeExtraTropical = [
  {
    id: 1,
    zone: 'No hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: 'Zone 0: 0-80 km/h',
    score: 10,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: 'Zone 1: 81-120 km/h',
    score: 20,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 4,
    zone: 'Zone 2: 121-160 km/h',
    score: 35,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 5,
    zone: 'Zone 3: 161-200 km/h',
    score: 50,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 6,
    zone: 'Zone 4: >200 km/h',
    score: 75,
    grading: { label: 'High', color: riskGradeColors.D },
  },
];

export const itemsGradeHailstorm = [
  {
    id: 1,
    zone: 'No hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: 'Zone 1',
    score: 10,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: 'Zone 2',
    score: 20,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 4,
    zone: 'Zone 3',
    score: 30,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 5,
    zone: 'Zone 4',
    score: 50,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 6,
    zone: 'Zone 5',
    score: 75,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 6,
    zone: 'Zone 6',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const itemsGradeTornado = [
  {
    id: 1,
    zone: 'No hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: 'Zone 1',
    score: 15,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: 'Zone 2',
    score: 40,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 4,
    zone: 'Zone 3',
    score: 60,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 5,
    zone: 'Zone 4',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const itemsGradeLightning = [
  {
    id: 1,
    zone: 'No hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: 'Zone 1',
    score: 10,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: 'Zone 2',
    score: 20,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 4,
    zone: 'Zone 3',
    score: 30,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 5,
    zone: 'Zone 4',
    score: 50,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 6,
    zone: 'Zone 5',
    score: 75,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 6,
    zone: 'Zone 6',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const itemsGradeWildfire = [
  {
    id: 1,
    zone: 'No hazard',
    score: 1,
    grading: { label: 'Negligible', color: riskGradeColors.A },
  },
  {
    id: 2,
    zone: 'Zone 1',
    score: 15,
    grading: { label: 'Low', color: riskGradeColors.B },
  },
  {
    id: 3,
    zone: 'Zone 2',
    score: 40,
    grading: { label: 'Moderate', color: riskGradeColors.C },
  },
  {
    id: 4,
    zone: 'Zone 3',
    score: 60,
    grading: { label: 'High', color: riskGradeColors.D },
  },
  {
    id: 5,
    zone: 'Zone 4',
    score: 100,
    grading: { label: 'Extreme', color: riskGradeColors.E },
  },
];

export const listGridGeophysical = [
  { items: itemsGradeEarthquake, title: 'earthquake' },
  { items: itemsGradeTsunami, title: 'tsunami' },
  { items: itemsGradeVolcano, title: 'volcano' },
];

export const listGridHydrological = [
  { items: itemsGradeRiverine, title: 'riverine' },
  { items: itemsGradeSurfaceWater, title: 'surface' },
  { items: itemsGradeCostalFlood, title: 'costal' },
];

export const listGridMeteorologicalFirstSection = [
  { items: itemsGradeTropical, title: 'tropical' },
  { items: itemsGradeExtraTropical, title: 'extratropical' },
];

export const listGridMeteorologicalSecondSection = [
  { items: itemsGradeHailstorm, title: 'hailstorm' },
  { items: itemsGradeTornado, title: 'tornado' },
  { items: itemsGradeLightning, title: 'lightning' },
];
