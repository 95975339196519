import React from 'react';
import { DonutChartProps } from './DonutChart.types';
import { Doughnut } from 'react-chartjs-2';
import { DoughnutContainer, TotalContainer } from './DonutChart.styles';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { shortNumberFormatter } from '@utils/numbers';

const DonutChart: React.FC<DonutChartProps> = ({
  titleValue,
  data,
  total,
  currency,
  ...props
}) => {
  const isMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('desktop')
  );

  const [size, borderRadius, cutout] = isMedium ? [178, 40, 70] : [232, 60, 95];

  return (
    <DoughnutContainer {...props}>
      <Doughnut
        key={`doughnut-${isMedium}`}
        width={size}
        height={size}
        data={{
          datasets: [
            {
              data: data?.map((bar) => bar.value),
              borderRadius,
              backgroundColor: data?.map((bar) => bar.color),
            },
          ],
        }}
        options={{
          cutout,
          maintainAspectRatio: false,
          responsive: false,
        }}
      />
      <TotalContainer>
        <Typography variant="paragraphSemi">{titleValue}</Typography>
        <Typography sx={{ marginTop: -1 }} variant="h1">
          {shortNumberFormatter(total, currency).toUpperCase()}
        </Typography>
      </TotalContainer>
    </DoughnutContainer>
  );
};

export default DonutChart;
