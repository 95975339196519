import { Box, styled } from '@mui/material';

export const LegendContainer = styled(Box)`
  text-transform: uppercase;
  display: flex;
  flex: 1;
  padding-bottom: 8px;
  min-width: 150px;
`;

export const LegendaryColumn = styled(Box)(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.only('mobile')]: {
    borderLeft: `2px solid ${theme.palette.border.main}`,
    paddingLeft: '12px',
  },
  ' &:not(:last-child)': {
    marginRight: '8px',
  },
}));

export const LegendaryItem = styled(Box)`
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const LegendaryColor = styled(Box)`
  width: 24px;
  margin-bottom: 4px;
  height: 4px;
  background-color: ${({ color }: { color: string }) => color};
`;
