import { ResponsiveDirectionBox } from '@components/templates';
import { Box, styled, Typography } from '@mui/material';

export const Container = styled(ResponsiveDirectionBox)(({ theme }) => ({
  gap: theme.spacing(2, 6),
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.up('desktop')]: {
    gap: theme.spacing(3),
  },
}));

export const CoachContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'stretch',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('desktop')]: {
    alignItems: 'flex-start',
    flex: 0.7,
    borderLeft: `3px solid ${theme.palette.border.main}`,
    paddingLeft: theme.spacing(6),
  },
}));

export const CoachIntro = styled(Typography)(({ theme }) => ({
  ...theme.typography.lead,
  [theme.breakpoints.up('desktop')]: {
    ...theme.typography.h1,
    margin: theme.spacing(0, 0, 0, 10),
  },
}));

export const CoachDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.paragraph,
  [theme.breakpoints.up('desktop')]: {
    margin: theme.spacing(0, 0, 0, 10),
    ...theme.typography.lead,
  },
}));
