import React from 'react';
import { MapAttributesModalProps } from './MapAttributesModal.types';
import { ModalContainer } from '@components/organisms/ModalContainer';
import { Link, Typography, useTheme } from '@mui/material';
import {
  OSMData,
  improveMapData,
  mapBoxData,
} from '../MapFooterAttributes/datadef';
import { LinksContainer } from './MapAttributesModal.styles';

const MapAttributesModal: React.FC<MapAttributesModalProps> = ({
  isOpen,
  onClose,
  title,
}) => {
  const theme = useTheme();
  return (
    <ModalContainer isOpen={isOpen} title={title} onClose={onClose}>
      <LinksContainer>
        <Typography
          variant="paragraphSmall"
          color={theme.palette.primary.main}
          sx={{ whiteSpace: 'nowrap' }}
        >
          <Link target="_blank" href={mapBoxData.link}>
            ©{mapBoxData.title}
          </Link>
          {' - '}
          <Link target="_blank" href={OSMData.link}>
            ©{OSMData.title}
          </Link>
          <br />
          <Link target="_blank" href={improveMapData.link}>
            {improveMapData.title}
          </Link>
        </Typography>
      </LinksContainer>
    </ModalContainer>
  );
};

export default MapAttributesModal;
