import React from 'react';
import { Skeleton, Stack } from '@mui/material';
import { ExpandButton } from '../../../templates/ExpandableList/ExpandableList.styles';
import RiskLevelSkeleton from '@components/molecules/portfolio/RiskLevel/RiskLevel.loading';

const RiskLevelListSkeleton = () => {
  return (
    <Stack>
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <RiskLevelSkeleton key={item} />
      ))}
      <Skeleton>
        <ExpandButton />
      </Skeleton>
    </Stack>
  );
};

export default RiskLevelListSkeleton;
