import React from 'react';
import { PaperAlert, PageAlert, GradientSection } from '@components/templates';
import { Cached } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { TopSection } from '@components/molecules';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';

export interface ErrorProps {}

const Error: React.FC<ErrorProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pageTitle, title, description, action, messageToSave } =
    location.state || {
      pageTitle: t('support.title'),
      title: t('errors.generic.title'),
      description: t('errors.generic.description'),
      action: t('action.resend'),
    };

  const goBackRiskCoach = () => {
    navigate('/riskcoach', {
      state: {
        message: messageToSave.message,
        href: '/riskcoach',
      },
    });
  };

  return (
    <>
      <GradientSection>
        <TopSection
          back
          backText={t('navigation.dashboard')}
          title={pageTitle}
        />
      </GradientSection>
      <PageAlert>
        <PaperAlert>
          <img src="/danger.svg" />
          <Typography variant="h2">{title}</Typography>
          <Typography variant="paragraphMedium" whiteSpace="pre-line">
            {description}
          </Typography>
          <Button
            id="error-functional-button-action"
            sx={{
              alignSelf: 'center',
              marginTop: 3,
            }}
            size="large"
            variant="contained"
            endIcon={<Cached />}
            onClick={() => {
              return goBackRiskCoach();
            }}
          >
            {action}
          </Button>
        </PaperAlert>
      </PageAlert>
    </>
  );
};

export default withErrorBoundary(Error);
