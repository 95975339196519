import React from 'react';
import { LabelListTutoProps } from './LabelListTuto.types';
import { Typography } from '@mui/material';
import { LabelListContainer } from './LabelListTuto.styles';

const LabelListTuto: React.FC<LabelListTutoProps> = ({ label }) => {
  return (
    <LabelListContainer>
      <Typography variant="leadSemi">{label}</Typography>
    </LabelListContainer>
  );
};

export default LabelListTuto;
