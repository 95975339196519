import React from 'react';
import { AlertType, TooltipMapMessageProps } from './TooltipMapMessage.types';
import {
  SectionIcon,
  TextContainer,
  TooltipContainer,
} from './TooltipMapMessage.styles';
import { useTheme } from '@mui/material';

const TooltipMapMessage: React.FC<TooltipMapMessageProps> = ({
  text,
  type = AlertType.INFO,
}) => {
  const theme = useTheme();
  const getColor = (type: AlertType) => {
    switch (type) {
      case AlertType.INFO:
        return theme.palette.ocean.main;
      case AlertType.SUCCESS:
        return theme.palette.success.main;
      case AlertType.ERROR:
        return theme.palette.error.main;
      default:
        return '/portfolio.svg';
    }
  };
  return (
    <TooltipContainer color={getColor(type)}>
      <SectionIcon
        id={'tooltip-logo-map-message'}
        src={
          type === AlertType.ERROR
            ? '/info-error.svg'
            : '/info-map-live-event.svg'
        }
        alt={'info-map'}
      />
      <TextContainer variant="paragraph">{text}</TextContainer>
    </TooltipContainer>
  );
};

export default TooltipMapMessage;
