import React from 'react';
import { ButtonRedirectionProps } from './ButtonRedirection.types';
import { StyledButton } from './ButtonRedirection.styles';

const ButtonRedirection: React.FC<ButtonRedirectionProps> = ({
  title,
  href,
}) => {
  return (
    <StyledButton
      id="map-site-button-detail"
      variant="text"
      size="small"
      href={href}
    >
      {title}
    </StyledButton>
  );
};

export default ButtonRedirection;
