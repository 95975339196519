import React from 'react';
import { LiveEventLabelProps } from './LiveEventLabel.types';
import {
  LabelContainer,
  LabelText,
  SectionIcon,
} from './LiveEventLabel.styles';

const LiveEventLabel: React.FC<LiveEventLabelProps> = ({ label, ...props }) => {
  return (
    <LabelContainer {...props}>
      <LabelText variant="h5">{label.toUpperCase()}</LabelText>
      <SectionIcon
        id={'liveEvent'}
        src={'/live-event-logo.svg'}
        alt={'liveEvent'}
      />
    </LabelContainer>
  );
};

export default LiveEventLabel;
