import { PaletteOptions } from '@mui/material';
import { grey } from '@mui/material/colors';
import './palette.types';

export const palette: PaletteOptions = {
  primary: {
    main: '#00008f',
    gradient: 'linear-gradient(140.95deg, #5c5cb7, #00006d 60%, #00006d)', // hero primary
  },
  action: {
    disabled: grey[600],
  },
  secondary: {
    main: '#d24723',
    gradient: 'linear-gradient(102.86deg, #743438 0.05%, #ac7074 101.01%)',
  },
  text: {
    primary: '#343C3D',
    secondary: '#111B1D',
  },
  danger: {
    main: '#c91432',
    gradient:
      'linear-gradient(90deg, #458833 0%, #f2ce0d 25%, #f2a83b 50%, #ea3223 75%, #b7001e 100%)',
  },
  teal: {
    main: '#015A66',
    gradient: 'linear-gradient(90deg, #015A66 0.37%, #41949F 68.63%)',
  },
  ocean: {
    main: '#4976ba',
    gradient: 'linear-gradient(111.62deg, #3a5e94 23.31%, #7698cb 100%)',
  },
  viridian: {
    main: '#738D87',
    gradient: 'linear-gradient(111.62deg, #32433F 23.31%, #738D87 100%)',
  },
  neutral: {
    main: '#343c3d',
    contrastText: '#ffffff',
  },
  background: {
    default: '#fafafa',
    paper: '#ffffff',
  },
  border: {
    main: '#e5e5e5',
  },
  hydrological: {
    main: '#4976BA',
    dark: '#3A5E94',
  },
  geophysical: {
    main: '#A79666',
    dark: '#8A7333',
  },
  meteorological: {
    main: '#41949F',
    dark: '#027180',
  },
  wildfire: {
    main: '#C91432',
    dark: '#B0122C',
  },
  other: {
    main: '#AC7074',
    dark: '#914146',
  },
  selected: {
    main: '#FFBC11',
  },
  hover: {
    main: '#FFED1F',
  },
  noEvent: {
    main: '#5F5F5F',
  },
  notification: {
    main: '#A11028',
  },
  affectedSites: {
    main: '#B7001E',
  },
  chartAxis: {
    main: '#757575',
  },
  chartLine: {
    main: '#999999',
  },
  success: {
    main: '#138636',
  },
  error: {
    main: '#C91432',
  },
};

export const darkPalette = {
  mode: 'dark',
  primary: {
    main: '#f0f0f0',
    dark: '#e5e5e5',
    light: '#ffffff',
    contrastText: '#343C3D',
  },
  secondary: {
    main: '#d24723',
  },
  background: {
    default: 'transparent',
  },
  text: {
    primary: '#ffffff',
    secondary: '#ffffff',
  },
};
