import React, { useState } from 'react';
import { Send } from '@mui/icons-material';
import { Avatar, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RadioGrid } from '../../forms/RadioGrid';
import {
  ActionContainer,
  RiskSendButton,
  TextArea,
} from './RiskSupport.styles';
import { RiskSupportProps } from './RiskSupport.types';
import { RadioOption } from '../../forms/RadioGrid/RadioGrid.types';

const topics = [
  'data_quality',
  'risk_consulting',
  'natural_hazard',
  'site',
  'other',
];

const RiskSupport: React.FC<RiskSupportProps> = ({
  coach,
  placeholder,
  isLoading,
  onSendMessage,
  questionContentSave,
}) => {
  const { t } = useTranslation();
  const [question, setQuestion] = useState(
    questionContentSave ?? (placeholder || null)
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };
  const [selectedTopic, setTopic] = useState<RadioOption<string>>();

  const postMessage = () =>
    onSendMessage({
      contactId: coach?.id,
      message: question ?? '',
      subject: selectedTopic?.label || 'none',
    });

  return (
    <Stack spacing={2}>
      <Typography color="text.secondary" variant="d6">
        {t('support.form.title')}
      </Typography>
      <Stack sx={{ maxWidth: 420 }} spacing={2} direction="row">
        <Avatar alt="coach" src={'/avatar.png'} />
        <Typography variant="paragraphMedium">
          {t('support.form.description')}
        </Typography>
      </Stack>
      <RadioGrid
        id="risk-question-topics"
        selected={selectedTopic}
        onChange={setTopic}
        hint={t('support.form.topic.title')}
        data={topics?.map((topic) => ({
          id: topic,
          value: topic,
          label: t(`support.form.topic.categories.${topic}`),
        }))}
      />
      <TextArea
        id="risk-question-field"
        placeholder={t('support.form.placeholder') || undefined}
        value={question}
        onChange={handleChange}
      />
      <ActionContainer>
        <RiskSendButton
          id="risk-question-button-send"
          variant="contained"
          disabled={question?.trim().length === 0}
          endIcon={<Send />}
          loading={isLoading}
          onClick={postMessage}
        >
          {t('action.send')}
        </RiskSendButton>
      </ActionContainer>
    </Stack>
  );
};

export default RiskSupport;
