import { FlexBox, FlexColumnBox } from '@components/templates';
import { Box, Typography, styled } from '@mui/material';

export const ExpandableBorder = styled(Box)(() => ({}));

export const ExpandableContainer = styled(FlexColumnBox)(({ theme }) => ({
  padding: theme.spacing(3, 0),
  [theme.breakpoints.not('mobile')]: {
    padding: theme.spacing(3, 4),
  },
  gap: 16,
}));

export const ExpandableBanner = styled(FlexBox)(({ theme, isOpen }) => ({
  //Todo: register the color in palette (check with JD)
  backgroundColor: isOpen ? 'rgba(240, 240, 240, 1)' : '',
  padding: theme.spacing(3, 1, 3, 4),
  justifyContent: 'space-between',
  cursor: 'pointer',
  ['&:hover']: {
    backgroundColor: 'rgba(240, 240, 240, 1)', // Specify the color you want on hover
  },
}));

export const TitleBanner = styled(Typography)(({ theme, isOpen }) => ({
  color: isOpen ? theme.palette.primary.main : theme.palette.text.primary,
  textTransform: 'uppercase',
}));
