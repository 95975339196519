import EventCardSkeleton from '@components/molecules/events/EventCard/EventCard.loading';
import {
  HorizontalPaginatonList,
  LiveScreenContainer,
} from '@components/templates';
import { Skeleton } from '@mui/material';
import React from 'react';

const LiveEventSkeleton: React.FC = () => {
  return (
    <LiveScreenContainer>
      <HorizontalPaginatonList
        keyExtractor={(item) => item}
        items={[1, 2, 3, 4, 5, 6, 7]}
        renderItem={(item) => <EventCardSkeleton key={item} />}
      />
      <Skeleton sx={{ height: '100%' }} variant="rectangular" />
    </LiveScreenContainer>
  );
};

export default LiveEventSkeleton;
