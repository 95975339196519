import React from 'react';
import { PortfolioSiteListProps } from './PortfolioSiteList.types';
import { Typography, Skeleton, Box } from '@mui/material';
import {
  CenteredContent,
  QuickFilterCenteredLoading,
  SitesContainer,
} from './PortfolioSiteList.styles';
import { UnmarginBox } from '@components/templates';
import SiteGridSkeleton from '@components/molecules/portfolio/SiteGrid/SiteGrid.loading';

const PortfolioSiteListSkeleton: React.FC<PortfolioSiteListProps> = () => {
  return (
    <SitesContainer>
      <CenteredContent>
        <Typography variant="d6">
          <Skeleton width={100} />
        </Typography>
      </CenteredContent>
      <Box style={{ alignSelf: 'center', maxWidth: '100%' }}>
        <UnmarginBox>
          <QuickFilterCenteredLoading items={[1, 2, 3, 4, 5, 6]} />
        </UnmarginBox>
      </Box>
      <CenteredContent sx={{ marginTop: -2 }}>
        <Typography variant="paragraphMedium">
          <Skeleton width={150} />
        </Typography>
      </CenteredContent>
      <SiteGridSkeleton />
    </SitesContainer>
  );
};

export default PortfolioSiteListSkeleton;
