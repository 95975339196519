import React from 'react';
import { SourceSectionProps } from './SourceSection.types';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { SourceDefinition } from '@components/atoms/SourceDefinition';
import { useTranslation } from 'react-i18next';
import {
  SMART_SERVICES_SOURCE_JBA,
  SMART_SERVICES_SOURCE_MUNICH,
} from '@utils/urls';

const SourceSection: React.FC<SourceSectionProps> = () => {
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h2">
        {t('portfolio.tutorial.source.title')}
      </Typography>
      <Typography variant={isSmall ? 'paragraph' : 'paragraphSemi'}>
        {t('portfolio.tutorial.source.description')}
      </Typography>
      <SourceDefinition
        name={t('portfolio.tutorial.source.1.title')}
        description={t('portfolio.tutorial.source.1.description')}
        href={SMART_SERVICES_SOURCE_MUNICH}
      />
      <SourceDefinition
        name={t('portfolio.tutorial.source.2.title')}
        description={t('portfolio.tutorial.source.2.description')}
        href={SMART_SERVICES_SOURCE_JBA}
      />
    </Box>
  );
};

export default SourceSection;
