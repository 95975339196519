import { sanitizeUrl } from '@braintree/sanitize-url';
import { ButtonBase, styled } from '@mui/material';
import React from 'react';
import '../themes/buttons.types';
//import TagManager from 'react-gtm-module';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

// eslint-disable-next-line react/display-name
export const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)

  if (typeof href === 'string' && href.startsWith('https://')) {
    return (
      <a
        ref={ref}
        href={sanitizeUrl(href)}
        rel="noopener noreferrer"
        {...other}
      />
    );
  }
  return (
    <RouterLink ref={ref} to={href} rel="noopener noreferrer" {...other} />
  );
});

export const ButtonControl = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 1),
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(2),
  },
  border: `1px solid ${theme.palette.border.main}`,
  textAlign: 'start',
  justifyContent: 'flex-start',
}));

export const ButtonGreyControl = styled(ButtonControl)(({ theme }) => ({
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.default,
  alignItems: 'stretch',
}));

export const ButtonBottom = styled(ButtonGreyControl)(({ theme }) => ({
  border: 0,
  borderTop: `1px solid ${theme.palette.border.main}`,
}));
