import {
  AllPerilsRisk,
  PerilRiskGrade,
  PerilRiskGradeOrdinate,
  SiteSummary,
} from '@modules/types';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridSortModel,
  gridNumberComparator,
  gridStringOrNumberComparator,
  GridSortCellParams,
} from '@mui/x-data-grid';
import {
  extractCountry,
  shortNumberFormatter,
  formatAddress,
  allCategoryRiskGrades,
  allRisks,
} from '../../../../utils';
import { TFunction } from 'i18next';
import {
  renderCellSkeleton,
  renderGeocodingQualityGrade,
  renderHeaderRiskGrade,
  renderRisk,
  renderRiskGrade,
  renderSiteName,
} from './SiteCells';

export interface ColumnField<T> {
  headName: T;
  field: string;
}

const commonRiskDef = {
  flex: 1.2,
  minWidth: 120,
  renderCell: renderRisk,
  filterable: false,
  disableColumnMenu: true,
  sortComparator: (
    v1: PerilRiskGrade,
    v2: PerilRiskGrade,
    param1: GridSortCellParams<PerilRiskGrade>,
    param2: GridSortCellParams<PerilRiskGrade>
  ) =>
    gridNumberComparator(
      PerilRiskGradeOrdinate[v1],
      PerilRiskGradeOrdinate[v2],
      param1,
      param2
    ),
};

export const generateRiskGradesDef = <
  T extends { perilGradeSummary: AllPerilsRisk }
>(
  risks: (keyof AllPerilsRisk)[],
  t: TFunction<'translation', undefined, 'translation'>,
  type: string
) =>
  risks.map((risk) => ({
    field: (risk + type) as unknown as string,
    headerName: t(`portfolio.risk.types.${risk}`),
    valueGetter: (params: { row: T }) => params.row.perilGradeSummary[risk],
    ...commonRiskDef,
  }));

export const generateRiskGradesCategoryDef = (
  risks: {
    key: string;
    field: keyof SiteSummary['categoryRiskGradeSummary'];
  }[],
  t: TFunction<'translation', undefined, 'translation'>
) =>
  risks.map((item) => ({
    field: item.key as unknown as string,
    headerName: t(`portfolio.risk.types.${item.key}`),
    valueGetter: (params: { row: SiteSummary }) =>
      params.row.categoryRiskGradeSummary[item.field],
    ...commonRiskDef,
  }));

export const getColumns = (
  t: TFunction<'translation', undefined, 'translation'>,
  currency?: string,
  openModal?: () => void
): GridColDef<SiteSummary>[] => [
  {
    field: 'name',
    headerName: 'Site name',
    flex: 2,
    minWidth: 200,
    renderCell: renderSiteName,
  },
  {
    field: 'address',
    headerName: 'Address',
    sortable: true,
    flex: 1.5,
    minWidth: 164,
    cellClassName: 'address',
    valueGetter: (params) => formatAddress(params.row.address),
  },
  {
    field: 'country',
    headerName: 'Country',
    flex: 1,
    minWidth: 100,
    valueGetter: (params) => extractCountry(params.row.address.countryIso2),
  },
  {
    field: 'geocodingQuality',
    headerName: 'Geocoding Quality',
    flex: 1.5,
    filterable: false,
    disableColumnMenu: true,
    minWidth: 164,
    cellClassName: 'geocoding-quality',
    valueGetter: (params) => params.row.geocodeQuality,
    sortComparator: (v1, v2) => v1 - v2,
    renderCell: (params) =>
      renderGeocodingQualityGrade(params.row.geocodeQuality),
  },
  {
    field: 'siteValue',
    headerName: 'Site value',
    type: 'number',
    headerAlign: 'left',
    flex: 1,
    minWidth: 100,
    filterable: false,
    disableColumnMenu: true,
    valueGetter: (params) => ({
      value: params.row.insuredValues.total,
      currency: currency,
      grade: params.row.riskGrade,
    }),
    sortComparator: (v1, v2, param1, param2) =>
      gridNumberComparator(v1.value, v2.value, param1, param2) ||
      gridStringOrNumberComparator(v1.grade, v2.grade, param1, param2),
    valueFormatter: (params) =>
      shortNumberFormatter(params?.value.value, params?.value.currency),
  },
  {
    field: 'riskGrade',
    headerName: 'Grade',
    renderHeader: (params) =>
      renderHeaderRiskGrade(
        params.colDef.headerName,
        t('site.risk.tooltip.title'),
        t('site.risk.tooltip.description'),
        t('site.risk.tooltip.link'),
        openModal
      ),
    flex: 1.2,
    minWidth: 120,
    valueGetter: (params) => ({
      value: params.row.insuredValues.total,
      grade: params.row.riskGrade,
    }),
    sortComparator: (v1, v2, param1, param2) =>
      gridStringOrNumberComparator(v1.grade, v2.grade, param1, param2) ||
      gridNumberComparator(v1.value, v2.value, param1, param2),
    renderCell: renderRiskGrade,
    filterable: false,
    disableColumnMenu: true,
  },
  ...generateRiskGradesDef(allRisks, t, ''),
  ...generateRiskGradesCategoryDef(allCategoryRiskGrades, t),
];

export const getColumnsLoading = (): GridColDef<object>[] => [
  {
    field: 'name',
    renderHeader: renderCellSkeleton,
    headerName: 'Site name',
    flex: 2,
    minWidth: 200,
    renderCell: renderCellSkeleton,
    sortable: false,
  },
  {
    field: 'address',
    renderHeader: renderCellSkeleton,
    headerName: 'Address',
    flex: 1.5,
    minWidth: 164,
    cellClassName: 'address',
    renderCell: renderCellSkeleton,
    sortable: false,
  },
  {
    field: 'country',
    renderHeader: renderCellSkeleton,
    headerName: 'Country',
    flex: 1,
    minWidth: 100,
    renderCell: renderCellSkeleton,
    sortable: false,
  },
  {
    field: 'riskGrade',
    renderHeader: renderCellSkeleton,
    headerName: 'Natural hazard',
    flex: 1.2,
    minWidth: 120,
    renderCell: renderCellSkeleton,
    sortable: false,
  },
  {
    field: 'siteValue',
    renderHeader: renderCellSkeleton,
    headerName: 'Site value',
    headerAlign: 'left',
    flex: 1,
    minWidth: 100,
    renderCell: renderCellSkeleton,
    sortable: false,
  },
  {
    field: 'geocodingQuality',
    renderHeader: renderCellSkeleton,
    headerName: 'Geocoding Quality',
    flex: 1.5,
    minWidth: 164,
    renderCell: renderCellSkeleton,
    sortable: false,
  },
];

export type PortfolioFilter = {
  name: string;
  columns?: GridColumnVisibilityModel;
  sort?: GridSortModel;
  width?: number;
};

export const perilsGradesDisabled = {
  earthquake: false,
  volcano: false,
  tsunami: false,
  riverineFlood: false,
  surfaceWaterFlood: false,
  coastalFlood: false,
  flood: false,
  tropicalCyclone: false,
  extraTropicalCyclone: false,
  hailstorm: false,
  tornado: false,
  lightning: false,
  wildfire: false,
};

const categoriesPerilsHidden = {
  geophysical: false,
  hydrological: false,
  meteorological: false,
  wildfireCategory: false,
};

export const portfolioFilters = [
  {
    name: 'all',
    columns: {
      ...perilsGradesDisabled,
    },
    sort: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
    style: {
      alignSelf: 'stretch',
    },
  },
  {
    name: 'highRisk',
    columns: {
      address: false,
      geocodingQuality: false,
      ...perilsGradesDisabled,
      ...categoriesPerilsHidden,
    },
    sort: [
      {
        field: 'riskGrade',
        sort: 'desc',
      },
    ],
    width: 520,
  },
  {
    name: 'allPerils',
    columns: {
      ...categoriesPerilsHidden,
      geocodingQuality: false,
    },
    sort: [
      {
        field: 'riskGrade',
        sort: 'desc',
      },
    ],
    style: {
      alignSelf: 'stretch',
    },
  },
  {
    name: 'highestValue',
    columns: {
      address: false,
      ...perilsGradesDisabled,
      ...categoriesPerilsHidden,
      geocodingQuality: false,
    },
    sort: [
      {
        field: 'siteValue',
        sort: 'desc',
      },
    ],
    width: 520,
  },
  {
    name: 'dataQuality',
    columns: {
      riskGrade: false,
      ...perilsGradesDisabled,
      ...categoriesPerilsHidden,
    },
    sort: [
      {
        field: 'geocodingQuality',
        sort: 'desc',
      },
    ],
    width: 848,
  },
  {
    name: 'riskCountry',
  },
] as PortfolioFilter[];
