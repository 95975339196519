import { Skeleton, Stack, styled } from '@mui/material';

export const PortfolioRiskListContent = styled(Stack)(() => ({
  display: 'flex',
  flex: 1,
  direction: 'column',
  gap: 8,
}));

export const ExplanationSquareSkeleton = styled(Skeleton)(() => ({
  width: '100%',
  height: 80,
}));
