import React from 'react';
import { IntroTutorialProps } from './IntroTutorial.types';
import { Box, Typography } from '@mui/material';
import { TitleSectionContainer } from './IntroTutorial.styles';

const IntroTutorial: React.FC<IntroTutorialProps> = ({
  title,
  description,
}) => {
  return (
    <Box>
      <TitleSectionContainer>
        <Typography variant="d6">{title}</Typography>
      </TitleSectionContainer>
      <Typography variant="h2">{description}</Typography>
    </Box>
  );
};

export default IntroTutorial;
