import { FlexColumnBox } from '@components/templates';
import { Avatar, avatarClasses, styled } from '@mui/material';

export const MapHintFilterContainer = styled(FlexColumnBox)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(6),
  left: 0,
  margin: theme.spacing(2),
  gap: theme.spacing(1),
  alignItems: 'flex-start',
}));

export const AvatarWhite = styled(Avatar)(() => ({
  [`& .${avatarClasses.img}`]: {
    filter: 'invert(1) brightness(1.8)',
  },
}));
