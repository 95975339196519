import { FlexBox } from '@components/templates';
import { Box, Typography, styled } from '@mui/material';

export const TooltipPosition = styled(Box)(() => ({
  position: 'relative',
  top: 40,
}));

export const TooltipPositionSiteDetail = styled(Box)(() => ({
  position: 'fixed',
  top: 40,
  width: '100%',
  zIndex: 1202,
}));

export const TooltipContainer = styled(FlexBox)(({ theme, color }) => ({
  justifyContent: 'center',
  backgroundColor: color,
  alignItems: 'center',
  margin: theme.spacing(0, 5),
  padding: theme.spacing(2, 2.5),
  [theme.breakpoints.only('mobile')]: {
    top: 24,
    margin: theme.spacing(0, 2.5),
  },
}));

export const SectionIcon = styled('img')(({ theme }) => ({
  marginRight: 10,
  [theme.breakpoints.only('mobile')]: {
    display: 'none',
  },
}));

export const TextContainer = styled(Typography)(() => ({
  textAlign: 'center',
  color: 'white',
}));
