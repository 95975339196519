import React from 'react';
import { HorizontalScrollView } from '@components/templates';
import { FilterButton } from './QuickFilters.styles';
import { QuickFiltersProps } from './QuickFilters.types';
import { BoxProps } from '@mui/material';

const QuickFilters = <T extends { name: string }>({
  selected,
  items,
  onItemChange,
  nameExtractor,
  ...props
}: QuickFiltersProps<T> & BoxProps) => (
  <HorizontalScrollView
    items={items}
    keyExtractor={(filter) => filter.name}
    renderItem={(filter) => (
      <FilterButton
        id={`portfolio-${filter.name}`}
        key={filter.name}
        onClick={() => {
          onItemChange(filter);
        }}
        variant={
          nameExtractor(filter) === nameExtractor(selected)
            ? 'contained'
            : 'outlined'
        }
      >
        {nameExtractor(filter)}
      </FilterButton>
    )}
    {...props}
  />
);

export default QuickFilters;
