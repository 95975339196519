import React from 'react';
import {
  ArrowButtonBig,
  ArrowButtonSmall,
  MiniMap,
} from '@components/molecules';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowButtonsStack, MapContainer } from './SiteLinks.styles';
import { SiteLinksProps } from './SiteLinks.types';

const SiteLinks: React.FC<SiteLinksProps> = ({ company, name, footprint }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const height = useMediaQuery((theme: Theme) => theme.breakpoints.up('tablet'))
    ? 186
    : 218;
  function handleMapClick() {
    navigate('map');
  }

  return (
    <>
      {footprint !== undefined && (
        <MapContainer>
          <Box sx={{ height }}>
            <MiniMap
              style={{ height }}
              company={company}
              name={name}
              footprint={footprint}
              onClick={handleMapClick}
            />
          </Box>
          <ArrowButtonSmall
            id="site-button-map"
            icon="/map.svg"
            title={t('site.location.map')}
            alt={'map'}
            href="map"
          />
        </MapContainer>
      )}
      <ArrowButtonsStack>
        <ArrowButtonBig
          id="site-button-assignment"
          icon="/assignment.svg"
          title={t('site.location.assignment.title')}
          description={t('site.location.assignment.description')}
          href="/riskcoach"
          alt={'assignment'}
        />
        <ArrowButtonBig
          id="site-button-axa-services"
          icon="/shoppingcart.svg"
          title={t('site.location.shopping_cart.title')}
          description={t('site.location.shopping_cart.description')}
          alt={'shopping_cart'}
          href="https://axaxl.com"
        />
      </ArrowButtonsStack>
    </>
  );
};

export default SiteLinks;
