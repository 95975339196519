import React from 'react';
import { Stack, Typography } from '@mui/material';
import { TitleIconProps } from './TitleIcon.types';
import { MarginTooltip, SectionIcon } from './TitleIcon.styles';
import { InfoTooltip } from '@components/atoms/InfoTooltip';

export default function TitleIcon({
  src,
  alt,
  children,
  variant = 'd6',
  titleTooltip,
  descriptionTooltip,
  ...props
}: TitleIconProps) {
  return (
    <Stack alignItems="center" direction="row" spacing={0.5} {...props}>
      <SectionIcon src={src} alt={alt} />
      <Typography variant={variant}>{children}</Typography>
      {titleTooltip && descriptionTooltip && (
        <MarginTooltip>
          <InfoTooltip title={titleTooltip} description={descriptionTooltip} />
        </MarginTooltip>
      )}
    </Stack>
  );
}
