import { Box, Paper, styled } from '@mui/material';

/* Paper layouts */

export const PaperPage = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(2),
  },
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(5),
  },
}));

export const PaperInfo = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(5),
  },
}));

export const PaperDetail = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  pointerEvents: 'auto',
  cursor: 'default',
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(3, 4),
  },
  [theme.breakpoints.not('desktop')]: {
    margin: theme.spacing(2, 0),
  },
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up('desktop')]: {
    padding: theme.spacing(2.5),
    width: 296,
  },
  zIndex: 6,
}));

export const PaperColor = styled(Paper)(({ theme, bottomcolor, maxwidth }) => ({
  padding: theme.spacing(1.75, 2),
  [theme.breakpoints.up('tablet')]: {
    maxWidth: maxwidth,
  },
  borderBottom: bottomcolor !== null ? `4px solid ${bottomcolor}` : undefined,
}));
PaperColor.defaultProps = { elevation: 1 };

export const PaperAlert = styled(Paper)(({ theme, width = 608 }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing(4, 5),
    width,
  },
}));

export const PaperStep = styled(PaperAlert)(({ theme }) => ({
  alignItems: 'stretch',
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(2),
  },
}));

export const ContainerSquare = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
