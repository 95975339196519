import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import App from './Application';
import { QueryProvider } from './modules/services';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { IS_PRODUCTION } from '@utils/urls';
import GlobalError from '@pages/GlobalError';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithRedirectCallback from './routes/Auth0ProviderwithRedirectCallback';

const tagManagerArgs: TagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  dataLayer: {
    env_work: !IS_PRODUCTION ? process.env.REACT_APP_ENV : 'production',
    env_language: 'en',
  },
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_AUTH0_DOMAIN_ID || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        authorizationParams={{
          audience: process.env.REACT_APP_API_AUDIENCE_ID,
          connection: 'AXA-XL-AUTH0',
          redirect_uri: window.location.origin,
        }}
      >
        <QueryProvider>
          <GlobalError>
            <App />
          </GlobalError>
        </QueryProvider>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
