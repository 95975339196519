import { Box, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

export const PortfolioResponsiveSeparator = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.only('mobile')]: {
    display: 'block',
    height: 40,
    marginTop: 40,
    borderTop: `2px solid ${grey[300]}`,
  },
  [theme.breakpoints.only('desktop')]: {
    display: 'block',
    width: 40,
    marginTop: 116,
  },
}));
