import React from 'react';
import { GradientSection } from '@components/templates/layouts/containers';
import { PaperPage } from '@components/templates/layouts/papers';
import { PageDoubleColumn } from '@components/templates/layouts/pages';
import { useTranslation } from 'react-i18next';
import { TopSection } from '@components/molecules';
import { RiskSupport, RiskSuggestions } from '@components/organisms';
import {
  useCoach,
  useSendMessageContact,
} from '@modules/services/services-company';
import {} from '@components/organisms';
import { useLocation, useNavigate } from 'react-router-dom';
import { RiskMessage } from '@modules/types';
import { withErrorBoundary } from 'src/routes/withErrorBoundary';

const Screen: React.FC<Record<string, never>> = () => {
  const { t } = useTranslation();

  const { data: coach } = useCoach();
  const location = useLocation();
  const { message } = location.state || { message: '' };
  const navigate = useNavigate();
  const { mutate, isLoading } = useSendMessageContact();

  const postMessage = (message: RiskMessage) =>
    mutate(message, {
      onSuccess: () =>
        navigate('success', {
          state: {
            title: t('support.form.success.title'),
            description: t('support.form.success.description'),
          },
        }),

      onError: () =>
        navigate('error', {
          state: {
            pageTitle: t('support.title'),
            title: t('support.form.error.title'),
            description: t('support.form.error.description'),
            action: t('action.resend'),
            href: '/riskcoach',
            messageToSave: message,
          },
        }),
    });

  return (
    <>
      <GradientSection>
        <TopSection
          back
          backText={t('navigation.dashboard')}
          title={t('support.title')}
        />
      </GradientSection>
      <PageDoubleColumn>
        <PaperPage>
          <RiskSupport
            coach={coach}
            placeholder={location?.state?.placeholder}
            isLoading={isLoading}
            onSendMessage={postMessage}
            questionContentSave={message}
          />
        </PaperPage>
        <PaperPage>
          <RiskSuggestions />
        </PaperPage>
      </PageDoubleColumn>
    </>
  );
};

export default withErrorBoundary(Screen);
