import { Box, styled } from '@mui/material';

export const TierContainer = styled(Box)(() => ({
  display: 'flex',
  padding: '24px 2vw',
  gap: '24px 1vw',
  flexGrow: 1,
}));

const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px 1vw;
`;

export const ColumnLarge = styled(Column)(({ theme }) => ({
  width: '70vw',
  [theme.breakpoints.down('desktop')]: {
    width: '96vw',
  },
}));

export const ColumnSmall = styled(Column)(({ theme }) => ({
  width: '30vw',
  margin: theme.spacing(0, 0, 0, 3),
  [theme.breakpoints.down('desktop')]: {
    display: 'none',
  },
}));
