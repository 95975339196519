import { FlexBox } from '@components/templates';
import { Box, styled } from '@mui/material';

export const FooterContainer = styled(Box)(({ theme, isSmallFormat }) => ({
  width: '100%',
  height: isSmallFormat ? 42 : 22,
  backgroundColor: theme.palette.background.paper,
  position: 'absolute',
  bottom: 0,
  left: 0,
  opacity: 0.65,
  textAlign: 'end',
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(isSmallFormat ? 0.75 : 0),
  zIndex: 4,
}));

export const MapBoxLogo = styled('img')(({ theme }) => ({
  verticalAlign: 'middle',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
}));

export const InfoLogo = styled('img')(({ theme }) => ({
  verticalAlign: 'middle',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(0.5),
}));

export const MobileFooterContainer = styled(FlexBox)(() => ({
  justifyContent: 'space-between',
}));

export const LogosContainer = styled(FlexBox)(() => ({
  verticalAlign: 'middle',
}));
