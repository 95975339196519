import React from 'react';
import { PortfolioRiskGradingProps } from './PortfolioRiskGrading.types';
import { riskColors } from '@utils/numbers';
import { ItemPortfolioRiskGrading } from '@components/molecules/portfolio/ItemPortfolioRiskGrading';
import { RiskGradingContainer } from './PortfolioRiskGrading.styles';
import { Typography } from '@mui/material';

const PortfolioRiskGrading: React.FC<PortfolioRiskGradingProps> = ({
  titleGraph,
  riskData,
}) => {
  return (
    <RiskGradingContainer>
      <Typography textTransform="uppercase" variant="h3">
        {titleGraph}
      </Typography>
      <ItemPortfolioRiskGrading
        risk={riskColors.negligible}
        siteNumber={riskData.riskGradeSummary.a}
        siteTotalNumber={riskData.totalSites}
      />
      <ItemPortfolioRiskGrading
        risk={riskColors.low}
        siteNumber={riskData.riskGradeSummary.b}
        siteTotalNumber={riskData.totalSites}
      />
      <ItemPortfolioRiskGrading
        risk={riskColors.medium}
        siteNumber={riskData.riskGradeSummary.c}
        siteTotalNumber={riskData.totalSites}
      />
      <ItemPortfolioRiskGrading
        risk={riskColors.high}
        siteNumber={riskData.riskGradeSummary.d}
        siteTotalNumber={riskData.totalSites}
      />
      <ItemPortfolioRiskGrading
        risk={riskColors.extreme}
        siteNumber={riskData.riskGradeSummary.e}
        siteTotalNumber={riskData.totalSites}
        isExtreme={true}
      />
    </RiskGradingContainer>
  );
};

export default PortfolioRiskGrading;
