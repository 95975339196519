import React from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import RiskLevelListSkeleton from '@components/molecules/portfolio/RiskLevel/RiskLevelList.loading';
import TitleIconResponsiveSkeleton from '@components/molecules/navigation/TitleIconResponsive/TitleIconResponsive.loading';
import {
  ExplanationSquareSkeleton,
  PortfolioRiskListContent,
} from './PortfolioRiskList.styles';

const PortfolioRiskListSkeleton: React.FC = () => {
  return (
    <PortfolioRiskListContent>
      <Box>
        <TitleIconResponsiveSkeleton marginIcon src="/risk.svg" alt="risk">
          <Skeleton width={70} />
        </TitleIconResponsiveSkeleton>
        <Typography
          variant="paragraphMedium"
          sx={{
            whiteSpace: 'pre-line',
          }}
        >
          <Skeleton width={150} />
        </Typography>
        <RiskLevelListSkeleton />
      </Box>
      <ExplanationSquareSkeleton />
    </PortfolioRiskListContent>
  );
};

export default PortfolioRiskListSkeleton;
