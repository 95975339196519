import { NAV_BAR_MARGIN } from '@components/organisms/navigation/NavBar/NavBar.styles';
import { Box, Button, buttonClasses, styled } from '@mui/material';

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: `${theme.spacing(2.75)} !important`,
  [theme.breakpoints.only('mobile')]: {
    //Todo: change with tooltip
    bottom: `${theme.spacing(5.25)} !important`,
  },
  left: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.only('desktop')]: {
    bottom: theme.spacing(7),
    left: NAV_BAR_MARGIN,
  },
}));

export const IconButtonControl = styled(Button)((/*{ theme }*/) => ({
  padding: 0,
  minWidth: 48,
  minHeight: 32,
  [`& .${buttonClasses.startIcon}`]: {
    margin: 0,
  },
}));
