import { Box, styled } from '@mui/material';

export const MapContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  filter: `drop-shadow(${theme.shadows[1]})`,
}));

export const ArrowButtonsStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  margin: theme.spacing(1, 0),
}));
