import React from 'react';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import ScopedDark from '@components/themes/ScopedDark';
import { SitesIcon } from '@components/atoms/Icons/icons';
import { OverviewContainer, OverviewDescription } from './SitesOverview.styles';
import SitesRiskIndicatorSkeleton from '@components/molecules/portfolio/SitesRiskIndicator/SitesRiskIndicator.loading';

const SitesOverviewSkeleton: React.FC<Record<string, never>> = () => {
  return (
    <ScopedDark>
      <OverviewContainer>
        <Stack spacing={2} alignItems={'center'}>
          <Typography variant="d1">
            <Skeleton width={120} />
          </Typography>
          <OverviewDescription variant="lead">
            <Skeleton width={250} />
          </OverviewDescription>
          <SitesRiskIndicatorSkeleton />
          <Skeleton>
            <Button variant="contained" size="large" startIcon={<SitesIcon />}>
              view sites
            </Button>
          </Skeleton>
        </Stack>
      </OverviewContainer>
    </ScopedDark>
  );
};

export default SitesOverviewSkeleton;
