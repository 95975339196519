import React, { useCallback } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { FooterButton, FooterContainer, FooterDivider } from './Footer.styles';
import { FooterProps } from './Footer.types';
import {
  SMART_SERVICES_COOKIES,
  SMART_SERVICES_PRIVACY,
  SMART_SERVICES_SUPPORT,
} from '@utils/urls';
import { openNewTab } from '@utils/routes';

declare global {
  interface Window {
    tC: {
      privacyCenter: {
        showPrivacyCenter: () => void;
      };
    };
  }
}

const Footer: React.FC<FooterProps> = ({
  footerCookies,
  footerSupport,
  footerLegal,
  footerPolicy,
}) => {
  const isVertical = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('mobile')
  );

  const handleShowCookies = useCallback(() => {
    if (window?.tC?.privacyCenter?.showPrivacyCenter) {
      window?.tC?.privacyCenter?.showPrivacyCenter();
    } else {
      openNewTab(SMART_SERVICES_COOKIES);
    }
  }, [window.tC]);

  return (
    <FooterContainer
      id="footer"
      direction={isVertical ? 'column' : 'row'}
      divider={
        <Box>
          <FooterDivider
            orientation={isVertical ? 'horizontal' : 'vertical'}
            variant={isVertical ? 'middle' : 'fullWidth'}
          />
        </Box>
      }
    >
      <FooterButton
        id="footer-button-privacy"
        target="_blank"
        href={SMART_SERVICES_PRIVACY}
      >
        {footerPolicy}
      </FooterButton>
      <FooterButton id="footer-button-cookies" onClick={handleShowCookies}>
        {footerCookies}
      </FooterButton>
      <FooterButton
        id="footer-button-support"
        target="_blank"
        href={SMART_SERVICES_SUPPORT}
      >
        {footerSupport}
      </FooterButton>
      <FooterButton
        id="footer-legal"
        target="_blank"
        sx={{ pointerEvents: 'none' }}
      >
        {footerLegal}
      </FooterButton>
    </FooterContainer>
  );
};

export default Footer;
