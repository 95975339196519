import { RiskProgress } from '@components/atoms/RiskProgress';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { RiskLevelProps } from './RiskLevel.types';
import { InfoTooltip } from '@components/atoms/InfoTooltip';
import { TitleItemContainer } from './RiskLevel.styles';

const RiskLevel: React.FC<RiskLevelProps> = ({
  name,
  value,
  color,
  toolbox,
}) => {
  return (
    <Box>
      <TitleItemContainer>
        <Typography variant="paragraphSmall">{name}</Typography>
        {toolbox && <InfoTooltip {...toolbox} />}
      </TitleItemContainer>
      <Stack direction="row">
        <RiskProgress color={color} value={value} />
        <Typography variant="paragraphSmall">{value}</Typography>
      </Stack>
    </Box>
  );
};

export default RiskLevel;
