import { FlexBox } from '@components/templates';
import { Box, styled } from '@mui/material';

export const TopSectionContainer = styled(FlexBox)(({ theme }) => ({
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [theme.breakpoints.only('desktop')]: {
    paddingBottom: theme.spacing(3),
    gap: theme.spacing(5),
  },
  [theme.breakpoints.only('tablet')]: {
    gap: theme.spacing(3),
  },
  [theme.breakpoints.only('mobile')]: {
    gap: theme.spacing(2),
  },
}));

export const LeftContainer = styled(Box)(() => ({
  flex: '1 1 350px',
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1.25),
}));

export const SourceTextContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  [theme.breakpoints.only('mobile')]: {
    marginTop: theme.spacing(2),
  },
}));
