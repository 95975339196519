import { FlexBox, FlexColumnBox } from '@components/templates';
import { Box, Typography, styled } from '@mui/material';

export const RiskItemContainer = styled(Box)(() => ({
  gap: 12,
  display: 'inline-block',
}));

export const IconContainer = styled(FlexBox)(() => ({
  marginBottom: 16,
  justifyContent: 'center',
}));

export const TitleContainer = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  marginBottom: 12,
  [theme.breakpoints.only('mobile')]: {
    textAlign: 'center',
  },
}));

export const ListContainer = styled(FlexColumnBox)(({ theme }) => ({
  gap: 12,
  [theme.breakpoints.only('mobile')]: {
    alignItems: 'center',
  },
}));
