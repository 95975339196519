import { FlexColumnBox } from '@components/templates';
import { Box, Typography, styled } from '@mui/material';

export const TutoContainer = styled(FlexColumnBox)(({ theme }) => ({
  [theme.breakpoints.only('mobile')]: {
    padding: theme.spacing(1, 0),
    gap: 24,
  },
  padding: theme.spacing(2, 2),
  gap: 40,
}));

export const ListContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.not('mobile')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    columnGap: 120,
    rowGap: 40,
  },
  [theme.breakpoints.only('mobile')]: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
  },
}));

export const TreeRiskContainer = styled(FlexColumnBox)(() => ({
  alignSelf: 'stretch',
  alignItems: 'center',
}));

export const InfoSiteGridResponsive = styled(Box)(() => ({
  flex: '1 1 auo',
  alignSelf: 'flex-end',
}));

export const ParagraphContainerMobile = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
}));
